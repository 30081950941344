import React from 'react'
import { Layout, Menu, Typography } from 'antd'
import PrivateSetting from './PrivateSetting'
import UserSetting from './UserSetting'
import BackGroundSetting from './BackgroundSetting'
import { injectIntl } from 'react-intl'

const { Content, Sider } = Layout
const { Title } = Typography

class UserInfo extends React.Component {

    state = {
        pageTitle: this.props.intl.formatMessage({ id: 'SportUserInfo.setting' }),
        page: <UserSetting />
    }

    onChangeItem = (item) => {
        switch (item.key) {
            case 'setting':
                this.setState({
                    pageTitle: this.props.intl.formatMessage({ id: 'SportUserInfo.setting' }),
                    page: <UserSetting />
                })
                break
            case 'background':
                this.setState({
                    pageTitle: this.props.intl.formatMessage({ id: 'SportUserInfo.background' }),
                    page: <BackGroundSetting />
                })
                break
            case 'private':
                this.setState({
                    pageTitle: this.props.intl.formatMessage({ id: 'SportUserInfo.private' }),
                    page: <PrivateSetting />
                })
                break
        }
    }

    render() {
        return (
            <Layout >
                <Content style={{ padding: '0 50px' }}>
                    <Layout style={{ padding: '24px 0', backgroundColor: 'white' }}>
                        <Sider width={200}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={['setting']}
                                style={{ height: '100%' }}
                                onClick={this.onChangeItem}
                            >
                                <Menu.Item key="setting">{this.props.intl.formatMessage({ id: 'SportUserInfo.setting' })}</Menu.Item>
                                <Menu.Item key="background">{this.props.intl.formatMessage({ id: 'SportUserInfo.background' })}</Menu.Item>
                                <Menu.Item key="private">{this.props.intl.formatMessage({ id: 'SportUserInfo.private' })}</Menu.Item>
                            </Menu>
                        </Sider>
                        <Content style={{ padding: '0 24px', minHeight: 280, backgroundColor: 'white' }}>
                            <Title style={{ paddingBottom: '16px' }} level={3}>{this.state.pageTitle}</Title>
                            {this.state.page}
                        </Content>
                    </Layout>
                </Content>
            </Layout>
        )
    }
}

export default injectIntl(UserInfo)