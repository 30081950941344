import React, { Component } from 'react'

class PrefixLabel extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <span className={'ant-input-group-wrapper'}>
        <span className={'ant-input-wrapper ant-input-group'}>
          <span className={'ant-input-group-addon'}>
            <span>{this.props.text}</span>
          </span>
          { this.props.children }
        </span>
      </span>
    )
  }
}

export default PrefixLabel
