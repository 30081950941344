import React from "react";
import StatePanel from './StatePanel'
import GamePanel from './GamePanel'
import Live from './Live'
import MuteList from "./MuteList";
import Chat from './Chat'
import useModel from './useModel'
import './index.scss'

function LiveControl(props) {
  const { msg, heart, sendMessage, socket } = props
  const { model, actions } = useModel({ socket })

  return (
    <div className="live-content">
      <div className="left-panel">
        <StatePanel
          online={model.online}
          channel={model.liveInfo.channel}
          closeLive={actions.closeLive} />
        <GamePanel
          game={model.gameList}
          gamelist={actions.fetchGames}
          nowBindGame={model.liveInfo.game}
          bindGame={actions.bindGame} />
        <MuteList banCustomer={model.banCustomerList} />
      </div>

      <div className="right-panel">
        <Live liveInfo={model.liveInfo} actions={actions} />
        <Chat
          msg={msg}
          heart={heart}
          sendMessage={sendMessage}
          reloadBanList={actions.getBenCustomerList}
          reloadManagerList={actions.getManagerList}
          managerList={model.managerList}
          games={model.gameList} />
      </div>
    </div>
  )
}

export default LiveControl