import React from 'react'

import Loading from "./Loading"
import AnchorCard from "./AnchorCard"
import { Empty } from 'antd'

import { injectIntl } from 'react-intl'

const AnchorList = (props) => {
  const { anchorListInfo, intl } = props

  if (anchorListInfo.loading) return <Loading/>

  return anchorListInfo.data.length ? <div className="monitor-beauty-anchorList-warp">
      {
        anchorListInfo.data.map(anchorInfo => (
          <AnchorCard key={anchorInfo.id} anchorInfo={anchorInfo}/>
        ))
      }
    </div>
    : <Empty description={intl.formatMessage({ id: 'familyMonitor.no_anchor' })} style={{ marginTop: 100 }}/>


}

export default injectIntl(AnchorList)
