import React from 'react'
import { Menu, Layout, Typography } from 'antd'
import { UserOutlined, ProjectOutlined } from '@ant-design/icons'
import { Switch, Route, Link } from "react-router-dom"
import { injectIntl } from 'react-intl'
import myEvents from './MyEvents'
import SportsEvents from './SportsEvents'
import SportApi from '../../../api/Sport'

const { SubMenu } = Menu;
const { Title } = Typography;

class Sports extends React.Component {

  state = {
    menuEvent: [],
    selectEventText: ''
  };

  handleClick = e => {
    this.setState({ selectEventText: e.key })
  }

  async componentDidMount() {
    const result = await SportApi.sportMenu()
    if (result.status === 200) {
      const { sport } = result.data
      const data = sport.map(item => {
        const i18nKey = `SportCommonTypes.${item}`
        return { sportId: item, name: `${this.props.intl.formatMessage({ id: i18nKey })}`}
      })
      this.setState({
        menuEvent: data,
        selectEventText: this.filterEventName(data)
      })
    }
  }

  componentWillReceiveProps() {
    this.setState({ selectEventText: this.defaultSelected()[0] })
  }

  defaultSelected = () => {
    const path = window.location.hash.split('#/')[1]

    if (path.match('events')) {
      return [`${this.filterEventName(this.state.menuEvent)}`]
    }

    return [`${this.props.intl.formatMessage({ id: 'Sports.myEvents' })}`]

  }

  filterEventName = (menuEvent) => {
    const path = window.location.hash.split('#/')[1]

    const eventName = menuEvent.filter(e => e.sportId == path.split('sports/events/')[1])

    if (eventName.length != 0) {
      return eventName[0].name
    }
    return this.props.intl.formatMessage({ id: 'Sports.myEvents' })
  }

  render() {
    const { menuEvent } = this.state
    const { intl } = this.props

    return (
      <div>
        <Menu onClick={this.handleClick} selectedKeys={this.defaultSelected()} mode="horizontal" >
          <Menu.Item key={intl.formatMessage({ id: 'Sports.myEvents' })} icon={<UserOutlined />} >
            <Link to="/sports">{intl.formatMessage({ id: 'Sports.myEvents' })}</Link>
          </Menu.Item>

          <SubMenu key="SportsEvents" icon={<ProjectOutlined />} title={this.props.intl.formatMessage({ id: 'Sports.sportsEvents' })}>
            <Menu.ItemGroup style={{ maxHeight: '400px', overflow: 'auto' }}>
              {
                menuEvent.map(event => {
                  return (
                    <Menu.Item key={event.name}>
                      <Link to={`/sports/events/${event.sportId}`}>{event.name}</Link>
                    </Menu.Item>
                  )
                })
              }
            </Menu.ItemGroup>
          </SubMenu>

        </Menu>
        <Layout.Content style={{ marginTop: '20px', backgroundColor: 'white', padding: '20px' }}>
          <div>
            <Title level={2}>{this.state.selectEventText}</Title>
          </div>
          <Switch>
            <Route path="/sports/events/:id" component={SportsEvents} />
            <Route path="/sports" component={myEvents} />
          </Switch>
        </Layout.Content>
      </div>
    )
  }
}


export default injectIntl(Sports)