import API from './index'

export default {
  async imUrl (Merchant) {
    const headers = { Merchant }
    try {
      const response = await API.get('/chat/imUrl', { headers })
      return response.data
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}
