import React from 'react'
import { LoadingOutlined, TeamOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { Button, Card, Skeleton } from 'antd'
import Ribbon from "../../../../../components/Badge/Ribbon"

import { injectIntl } from 'react-intl'

const AnchorCardLoading = (props) => {
  const { intl } = props

  return (
    <Ribbon
      color={"volcano"}
      style={{ left: 0, top: -5 }}
      text={<div><TeamOutlined/> ...</div>}>
      <Card
        hoverable
        style={{ justifyContent: 'center' }}
        actions={[
          <div>
            <LoadingOutlined/>
            <div>{intl.formatMessage({ id: 'familyMonitor.remove' })}</div>
          </div>,
        ]}
      >
        <Skeleton.Avatar active size={100} shape={"circle"}/>
        <Skeleton.Input active className="name" size={25} style={{ width: 100 }}/>
        <Skeleton.Input active className="stream-name" size={20} style={{ width: 100 }}/>
        <Button className="view-btn" loading={true} type="primary" shape="round" icon={<VideoCameraOutlined/>}
                size={"middle"}>
          {intl.formatMessage({ id: 'familyMonitor.monitor_live' })}
        </Button>
      </Card>
    </Ribbon>
  )

}

export default injectIntl(AnchorCardLoading)
