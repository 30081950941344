import React from 'react'
import DecodePlayer from "./DecodePlayer"
import NormalPlayer from "./NormalPlayer"

const HlsPlayer = props => {
  const { roomType, streamUrl, changeChannelTypeToFlv } = props
  if (roomType !== "normal") return (
    <DecodePlayer
      changeChannelTypeToFlv={changeChannelTypeToFlv}
      streamUrl={streamUrl}
    />
  )

  return (<NormalPlayer changeChannelTypeToFlv={changeChannelTypeToFlv} roomType={roomType} streamUrl={streamUrl}/>)
}
export default HlsPlayer
