import React from 'react'
import { Modal, Table, Statistic, Tag, Button, message } from 'antd'
import moment from 'moment'
import MuteAPI from '../../../../api/Sport/mute'
import { injectIntl } from 'react-intl';

const { Countdown } = Statistic

class MuteList extends React.Component {

  columns = [
    {
      title: this.props.intl.formatMessage({ id: 'MuteList.name' }),
      dataIndex: 'customer',
      align: 'center',
      width: '100px',
      render: (customer) => customer.nickname
    },
    {
      title: this.props.intl.formatMessage({ id: 'MuteList.muteTime' }),
      dataIndex: 'banTime',
      align: 'center',
      render: (date) => moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: this.props.intl.formatMessage({ id: 'MuteList.timeLeft' }),
      dataIndex: 'freeTime',
      align: 'center',
      render: (date) => {
        if (new Date(date) <= new Date()) {
          return (
            <Tag color="red">{this.props.intl.formatMessage({ id: 'MuteList.end' })}</Tag>
          )
        } else {
          return (
            <Countdown valueStyle={{ fontSize: 18 }} value={new Date(date)} onFinish={() => this.props.reloadData()} />
          )
        }
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'MuteList.manager' }),
      dataIndex: 'byManager',
      align: 'center',
      render: (byManager) => byManager ? byManager.nickname : ''
    },
    {
      title: this.props.intl.formatMessage({ id: 'MuteList.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (_, item) => {
        if (new Date(item.freeTime) <= new Date()) {
          return (
            <Button type='primary' disabled>{this.props.intl.formatMessage({ id: 'MuteList.disMute' })}</Button>
          )
        } else {
          return (
            <Button type='primary' onClick={() => this.onMute(item)}>{this.props.intl.formatMessage({ id: 'MuteList.disMute' })}</Button>
          )
        }
      }
    }
  ]

  onMute = async (item) => {
    const result = await MuteAPI.dismissMute(item.customer.customerId)
    if (result.status === 200) {
      this.props.reloadData()
      message.success(`${this.props.intl.formatMessage({ id: 'MuteList.has' })} ${item.customer.nickname} ${this.props.intl.formatMessage({ id: 'MuteList.disMute' })}`)
    }
  }

  render() {
    const { isShow, dataSource, onCancel } = this.props

    return (
      <Modal
        visible={isShow}
        footer={null}
        closable={false}
        onCancel={() => onCancel()}
        width={1000}
        centered
      >
        <Table
          columns={this.columns}
          dataSource={dataSource}
        />
      </Modal>
    )
  }
}

export default injectIntl(MuteList)