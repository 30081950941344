import API from '../index'

const path = 'live'

export default {
  async liveSum (query) {
    const { from, to, name, page = 1 } = query
    const params = Object.assign({ limit: 10 }, { from, to, name, page })

    try {
      const response = await API.get(`/${path}/family`, { params })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async stop({ id, merchant }) {
    try {
      const headers = { merchant }
      const response = await API.delete(`${path}/stop/${id}`, { headers })
      return response
    } catch (e) {
      throw error(e)
    }
  },
}
