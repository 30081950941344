import React from 'react'
import FlvPlayerContent from "./FlvPlayer"
import flvJs from "flv.js"

const FlvPlayer = props => {
  const { isNormal, videoUrl, isNeedEncrypt } = props

  return (
    <FlvPlayerContent
      flvJs={(isNormal || !isNeedEncrypt) ? flvJs : window.flvjs}
      videoUrl={videoUrl}
    />
  )

}

export default FlvPlayer
