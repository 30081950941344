import React, { Component } from 'react'
import { Row, Col, Tabs, Input, Button } from 'antd'
import { injectIntl } from 'react-intl'

import Lottery from './Lottery'
import ESport from './ESport'
import Sport from './Sport'
import GameAPI from '../../../../api/game'
import UserAPI from '../../../../api/user'

import './index.scss'

class GamePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      key: 'lotto',
      gameCategory: {
        lottery: false,
        esport: false,
        sport: false,
        littleGame: false,
      },
      games: [],

      gameSelected: {},
      nowGame: {}
    }
  }

  queryGames = async key => {
    let result
    switch (key) {
      case 'lotto':
        result = await GameAPI.getLGWGame()
        if (result.status === 200) {
          this.props.gamelist(result.data.games)
          this.setState({ games: result.data.games })
        }
        break
      case 'esport':
        result = await GameAPI.getGCSVendor()
        if (result.status === 200) this.setState({ games: result.data.games.filter(v => v.type === 'esport') })
        break
      case 'sport':
        result = await GameAPI.getGCSVendor()
        if (result.status === 200) this.setState({ games: result.data.games.filter(v => v.type !== 'sport') })
        break
    }
  }

  onTabChange = (key) => {
    this.setState({ key, games: [], gameSelected: {} })

    this.queryGames(key)
  }

  onGameSelected = (value) => {
    this.setState({ gameSelected: value })
  }

  onGameConfirm = async () => {
    if (Object.keys(this.state.gameSelected).length === 0) return

    const result = await UserAPI.setGame({ game: this.state.gameSelected })
    if (result.status === 200) {
      this.props.bindGame(this.state.gameSelected)
      this.setState({ gameSelected: {}, nowGame: this.state.gameSelected })
    }
  }

  onGameCancel = async () => {
    const result = await UserAPI.setGame({ game: null })
    if (result.status === 200) {
      this.props.bindGame({})
      this.setState({ nowGame: {}, gameSelected: {} })
    }
  }

  initGame = async () => {
    if (this.props.game && this.props.game.type) {
      const game = JSON.parse(this.props.game)

      await this.queryGames(game.type)
      this.setState({
        nowGame: game,
        gameSelected: game,
        key: game.type
      })
    } else {
      await this.queryGames('lotto')
    }
  }

  async componentDidMount() {
    const result = await GameAPI.getGameCategory()
    if (result.status === 200) {
      this.setState({ gameCategory: result.data })
    }

    this.initGame()
  }

  render() {
    const { intl, nowBindGame } = this.props
    //const { TabPane } = Tabs

    return (
      <Col>
        <Lottery
          games={this.state.games}
          bindGameId={nowBindGame.gameId}
          onGameSelected={this.onGameSelected}
          selected={this.state.nowGame.gameId}
          onGameConfirm={this.onGameConfirm}
          onGameCancel={this.onGameCancel}
        />
        {/* <Tabs activeKey={this.state.key} onChange={this.onTabChange}>
          {
            this.state.gameCategory && this.state.gameCategory.lottery &&
            <TabPane tab={intl.formatMessage({ id: 'games.lottery' })} key="lotto">
              <Lottery
                games={this.state.games}
                onGameSelected={this.onGameSelected}
                selected={this.state.nowGame.gameCode}
              />
            </TabPane>
          }
          {
            this.state.gameCategory && this.state.gameCategory.esport &&
            <TabPane tab={intl.formatMessage({ id: 'games.esport' })} key="esport">
              <ESport
                games={this.state.games}
                onGameSelected={this.onGameSelected}
                selected={this.state.nowGame.id}
              />
            </TabPane>
          }
          {
            this.state.gameCategory && this.state.gameCategory.sport &&
            <TabPane tab={intl.formatMessage({ id: 'games.sport' })} key="sport">
              <Sport
                games={this.state.games}
                onGameSelected={this.onGameSelected}
                selected={this.state.nowGame.id}
              />
            </TabPane>
          }
          {
            this.state.gameCategory && this.state.gameCategory.littleGame &&
            <TabPane tab={intl.formatMessage({ id: 'games.littleGame' })} key="littleGame">
              {intl.formatMessage({ id: 'games.comingSoon' })}
            </TabPane>
          }
        </Tabs> */}

        {/* <Row style={{ margin: '20px 0' }}>
          <Col span={16}>
            <Input
              addonBefore={intl.formatMessage({ id: 'games.gameSelected' })}
              value={this.state.gameSelected.name}
              disabled
            />
          </Col>
        </Row>

        <Row type="flex" style={{ margin: '20px 0' }}>
          <Col style={{ alignItems: "left" }}>
            <Button type="primary" onClick={this.onGameConfirm}>{intl.formatMessage({ id: 'games.gameRecommend' })}</Button>
          </Col>
          <Col offset={1}>
            <Button type="danger" onClick={this.onGameCancel}>{intl.formatMessage({ id: 'games.gameCancel' })}</Button>
          </Col>
        </Row> */}
      </Col>
    )
  }
}

export default injectIntl(GamePanel)