import API from '../index'

const path = 'ban'

export default {
  async muteList() {
    try {
      const response = await API.get(`/${path}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async mute(cid, minute) {
    try {
      const response = await API.post(`/${path}/mute/${cid}`, { minute: minute })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async dismissMute(cid) {
    try {
      const response = await API.delete(`/${path}/mute/${cid}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
}