import API from '../index'
import { getUserInfo } from '../../helper/helper'

const path = 'game'

export default {
  async sportMenu() {
    try {
      const response = await API.get(`/${path}/queryParams`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async sportEvents(params) {
    try {
      const config = {
        headers: {
          'merchant': getUserInfo().user.merchant
        },
        params: params
      }
      const response = await API.get(`/${path}/anchorReserve`, config)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}