import API from '../index'

const path = 'giftOrder'

export default {
  async list (query) {
    const { from, to, name, page = 1 } = query
    const params = Object.assign({ limit: 10 }, { from, to, name, page })

    try {
      const response = await API.get(`/${path}/family`, { params })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
}
