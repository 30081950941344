import API from '../index'

const path = 'live'

export default {
  async postLive(body) {
    try {
      const response = await API.post(`/${path}`, body)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async deleteLive() {
    try {
      const response = await API.delete(`/${path}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}