import React, { useRef, useEffect } from 'react'

const FlvPlayer = props => {
  const { streamUrl, changeChannelTypeToHls, flvJs } = props
  const videoRef = useRef()
  const player = useRef()
  const destroyPlayer = () => {
    if (player.current) {
      player.current.destroy()
      player.current = null
    }
  }

  const launchVideo = () => {
    if (flvJs && flvJs.isSupported()) {

      player.current = flvJs.createPlayer({
        type: 'flv',
        isLive: true,
        url: streamUrl,
      })
      player.current.attachMediaElement(videoRef.current)
      player.current.load()
      player.current.play()
      player.current.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {
        // onError(errorType, errorDetail, errorInfo, player.current)
      });
    } else {
      changeChannelTypeToHls()
    }
  }


  useEffect(() => {
    launchVideo()
    return () => {
      destroyPlayer()
    }
  }, [])

  return (
    <div style={{ width: '100%', height: '100%', background: '#001529', position: "relative" }}>
      <div style={{
        position: "absolute",
        color: "white",
        top: 0,
        right: 0,
        width: 15,
        height: 15,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        fontSize: 12,
        zIndex: 1,
        borderRadius: 15,
        border: "1px solid"
      }} onClick={changeChannelTypeToHls}>F
      </div>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        style={{ width: '100%', height: '100%', background: '#001529' }}
        controls={true}
        muted
        autoPlay={true}
        playsInline={true}
        webkit-playsinline="true"
        x5-playsinline="true"
      />
    </div>
  )
}

export default FlvPlayer
