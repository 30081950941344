import React from 'react'
import AnchorCardLoading from "./AnchorCardLoading"

const AnchorLoadingList = () => {
  return (
    <div className="monitor-beauty-anchorList-warp">
      {
        Array.from({ length: 3 }, (_, index) => (
          <AnchorCardLoading key={index}/>
        ))
      }
    </div>

  )

}

export default AnchorLoadingList
