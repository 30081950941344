import React, { Component } from 'react'
import 'animate.css'

class Animate extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { animateIn, animateOut, toggle } = this.props

    return (
      <div
        className={`animated ${toggle ? animateIn : animateOut}`}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    )
  }
}

export default Animate
