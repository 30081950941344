import API from './index'
import { lasHost } from '../config/env'

const path = 'live'

export default {
  async webStart(body) {
    try {
      const response = await API.post(`/${path}`, body)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async webEnd() {
    try {
      const response = await API.delete(`/${path}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async list(q) {
    try {
      let query = ''
      if (q && q.type) {
        query += `&type=${q.type}`
      }
      if (q && q.from) {
        query += `&from=${q.from}`
      }
      if (q && q.to) {
        query += `&to=${q.to}`
      }

      const response = await API.get(`/${path}/model?limit=10000${query}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async analysisList(q) {
    try {
      let query = ''
      if (q && q.from) {
        query += `&from=${q.from}`
      }
      if (q && q.to) {
        query += `&to=${q.to}`
      }

      const response = await API.get(`/${path}/me?limit=10000${query}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}
