import React from 'react'
import { Table, Button, Popconfirm, notification } from 'antd'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import SportApi from '../../../api/Sport'
import AnchorApi from '../../../api/Sport/anchor'
import ActionBar from './ActionBar'

const dateFormat = 'YYYY-MM-DD'

class SportsEvents extends React.Component {

  state = {
    dataSource: [],
    sportId: 0,
    setLeague: [],
    setRegion: [],
    tableLoading: false
  }

  columns = [
    {
      title: this.props.intl.formatMessage({ id: 'Sports.leagueName' }),
      dataIndex: 'leagueName',
      align: 'center'
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.date' }),
      dataIndex: 'startAt',
      sortOrder: 'ascend',
      align: 'center',
      width: '150px',
      sorter: (a, b) => moment(a.startAt).unix() - moment(b.startAt).unix(),
      render: date => {
        const at = moment(new Date(date)).format('YYYY-MM-DD /-/-/ HH:mm').split('/-/-/')
        return (
          <div>
            {at[0]}
            <br></br>
            {at[1]}
          </div>
        )
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.region' }),
      dataIndex: 'region',
      align: 'center',
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.eventName' }),
      dataIndex: 'eventName',
      align: 'center',
      render: text => {
        const homeGuest = text.split('/-/-/')
        return (
          <div>
            {homeGuest[0]}
            <br></br>
            <br></br>
            {homeGuest[1]}
          </div>
        )
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.fllowedAnchor' }),
      dataIndex: 'anchorReserveCount',
      align: 'center',
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => {
        if (record.anchorReserveCount >= record.maxReserveCount) {
          return (
            <Button disabled>{this.props.intl.formatMessage({ id: 'Sports.fllowFull' })}</Button>
          )
        } else if (!record.isMyReserve) {
          return (
            <Popconfirm
              placement="topRight"
              title={this.props.intl.formatMessage({ id: 'Sports.fllowEvent' })}
              onConfirm={() => this.confirm(record)}
              okText={this.props.intl.formatMessage({ id: 'Sports.ok' })}
              cancelText={this.props.intl.formatMessage({ id: 'Sports.cancel' })}
            >
              <Button type="primary">{this.props.intl.formatMessage({ id: 'Sports.fllow' })}</Button>
            </Popconfirm>
          )
        } else {
          return (
            <Button disabled>{this.props.intl.formatMessage({ id: 'Sports.fllowed' })}</Button>
          )
        }
      }
    }
  ]

  sportEventQuery = async (event, type) => {
    this.setState({ tableLoading: true })
    const result = await SportApi.sportEvents(event)

    if (result.status === 200) {
      const vaule = [...result.data]
      vaule.forEach((even) => {
        even["eventName"] =
          `${even.homeTeamName} ${this.props.intl.formatMessage({ id: 'Sports.home' })}
         /-/-/
         ${even.guestTeamName} ${this.props.intl.formatMessage({ id: 'Sports.guest' })}`
      })

      const league = vaule.map((item) => {
        return {
          leagueId: item.leagueId,
          leagueName: item.leagueName
        }
      })
      switch (type) {
        case 'Date':
          break
        case 'Event':
          this.setState({
            setRegion: [...new Set(vaule.map(e => e.region))],
            setLeague: [...new Set(league.map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
          })
          break
        case 'Region':
          this.setState({
            setLeague: [...new Set(league.map(item => JSON.stringify(item)))].map(item => JSON.parse(item))
          })
          break
        case 'League':
          break
        default:
          break
      }

      this.setState({ dataSource: vaule, tableLoading: false })
    }
  }

  componentDidMount() {
    this.setState({ sportId: this.props.match.params.id })
    this.onChangeActionBar(
      this.props.match.params.id,
      null,
      null,
      moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss"),
      moment(new Date().addDays(7), dateFormat).set({ hour: 23, minute: 59, second: 59 }).toISOString()
    )
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ sportId: nextProps.match.params.id })
    this.onChangeActionBar(
      nextProps.match.params.id,
      null,
      null,
      moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss"),
      moment(new Date().addDays(7), dateFormat).set({ hour: 23, minute: 59, second: 59 }).toISOString()
    )
  }


  confirm = async (item) => {
    const result = await AnchorApi.reserve(item.id)
    if (result.status === 200) {
      const data = [...this.state.dataSource].map(e => {
        if (e.id === item.id) {
          var obj = e
          obj.isMyReserve = 1
          obj.anchorReserveCount += 1
          return obj
        }
        return e
      })
      this.setState({ dataSource: data })
      this.openNotificationWithIcon('success', this.props.intl.formatMessage({ id: 'Sports.fllowEventSuccess' }))
    } else {
      this.openNotificationWithIcon('error', this.props.intl.formatMessage({ id: 'Sports.fllowEventError' }))
    }
  }

  openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: msg
    })
  }

  onChangeActionBar = (sportId, region, leagueId, from, to, type = 'Event') => {
    this.sportEventQuery(
      {
        sport: sportId,
        league: leagueId,
        region: region,
        from: from,
        to: to,
      },
      type
    )
  }

  render() {
    return (
      <>
        <ActionBar
          sportId={this.state.sportId}
          league={this.state.setLeague}
          region={this.state.setRegion}
          onChange={this.onChangeActionBar}
        />
        <Table
          style={{ marginTop: '20px' }}
          dataSource={this.state.dataSource}
          columns={this.columns}
          pagination={{ pageSize: 15, showSizeChanger: false }}
          bordered={true}
          loading={this.state.tableLoading}
        />
      </>
    )
  }
}

export default injectIntl(SportsEvents)