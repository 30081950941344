import React, { Component } from 'react'
import moment from 'moment'
import { Icon, Col, Input, Button, Popover } from 'antd'
import { DateRange } from 'react-date-range'
import { injectIntl } from 'react-intl'
import { CalendarOutlined } from '@ant-design/icons';
import '../../css/rangePicker.css'
import PrefixLabel from './PrefixLabel'

class DateTimeRangePicker extends Component {
  constructor(props) {
    super(props)

    this.state = {
      from: (props.startDate || moment()).set({ hour: 0, minute: 0, second: 0 }),
      to: (props.endDate || props.startDate || moment()).set({ hour: 23, minute: 59, second: 59 }),
      selection: 0,
      visible: false,
      show: false
    }

    if (this.state.to.diff(this.state.from, 'days') !== 0) {
      this.state.selection = 6
      this.state.show = true
    }

    if (typeof this.props.onInit === 'function') {
      this.props.onInit({ startDate: this.state.from, endDate: this.state.to })
    }
    this.onChange = this.props.onChange
  }

  displayValue() {
    if (this.state.to.diff(this.state.from, 'days') === 0) {
      return this.state.from.format('YYYY-MM-DD')
    } else {
      return `${this.state.from.format('YYYY-MM-DD')} ~ ${this.state.to.format('YYYY-MM-DD')}`
    }
  }

  showRangePicker(show) {
    this.setState({ show })
  }

  buttonSelection(sel) {
    this.setState({ selection: sel })

    let from, to

    switch (sel) {
      case 0:
        from = moment().set({ hour: 0, minute: 0, second: 0 })
        to = moment().set({ hour: 23, minute: 59, second: 59 })
        break
      case 1:
        from = moment().add(-1, 'd').set({ hour: 0, minute: 0, second: 0 })
        to = moment().add(-1, 'd').set({ hour: 23, minute: 59, second: 59 })
        break
      case 2:
        from = moment().add(-6, 'd').set({ hour: 0, minute: 0, second: 0 })
        to = moment().set({ hour: 23, minute: 59, second: 59 })
        break
      case 3:
        from = moment().add(-29, 'd').set({ hour: 0, minute: 0, second: 0 })
        to = moment().set({ hour: 23, minute: 59, second: 59 })
        break
      case 4:
        from = moment().set({ date: 1, hour: 0, minute: 0, second: 0 })
        to = moment().add(1, 'M').set({ date: 1, hour: 23, minute: 59, second: 59 }).add(-1, 'd')
        break
      case 5:
        from = moment().add(-1, 'M').set({ date: 1, hour: 0, minute: 0, second: 0 })
        to = moment().set({ date: 1, hour: 23, minute: 59, second: 59 }).add(-1, 'd')
        break
      case 6:
        this.showRangePicker(true)
        break
      default:
        break
    }

    if (sel < 6) {
      this.showRangePicker(false)
      this.setState({ visible: false })
      this.setState({ from, to })
      this.onChange({ startDate: from, endDate: to })
    }
  }

  onPopupChanged = visible => {
    this.setState({ visible })
  }

  onInputFromChange = event => {
    if (moment(event.target.value).isValid()) {
      this.setState({ from: moment(event.target.value) })
      this.onChange({ startDate: moment(event.target.value), endDate: this.state.to })
    }
  }

  onInputToChange = event => {
    if (moment(event.target.value).isValid()) {
      this.setState({ to: moment(event.target.value) })
      this.onChange({ startDate: this.state.from, endDate: moment(event.target.value) })
    }
  }

  onRangePickerChange = moments => {
    this.setState({ from: moments.startDate, to: moments.endDate })
    this.onChange({ startDate: moments.startDate, endDate: moments.endDate })
  }

  render() {
    const { intl } = this.props
    const InputGroup = Input.Group

    const rightPart = (
      <div>
        <div>
          <InputGroup>
            <Col span={12}>
              <Input
                prefix={<CalendarOutlined />}
                type={'date'}
                value={this.state.from.format('YYYY-MM-DD')}
                onChange={this.onInputFromChange}
              />
            </Col>
            <Col span={12}>
              <Input
                prefix={<CalendarOutlined />}
                type={'date'}
                value={this.state.to.format('YYYY-MM-DD')}
                onChange={this.onInputToChange}
              />
            </Col>
          </InputGroup>
        </div>
        <div>
          <DateRange
            startDate={this.state.from}
            endDate={this.state.to}
            theme={{ Calendar: { width: 200, paddingTop: 0 } }}
            onChange={this.onRangePickerChange}
          />
        </div>
      </div>
    )

    const content = (
      <div>
        <div style={{ display: 'inline-block', marginRight: '10px' }}>
          <Button
            type={this.state.selection === 0 ? 'primary' : null}
            style={Style.button}
            onClick={() => this.buttonSelection(0)}
          >{intl.formatMessage({ id: 'dateTimePicker.today' })}
          </Button>
          <Button
            type={this.state.selection === 1 ? 'primary' : null}
            style={Style.button}
            onClick={() => this.buttonSelection(1)}
          >{intl.formatMessage({ id: 'dateTimePicker.yesterday' })}
          </Button>
          <Button
            type={this.state.selection === 2 ? 'primary' : null}
            style={Style.button}
            onClick={() => this.buttonSelection(2)}
          >{intl.formatMessage({ id: 'dateTimePicker.l7days' })}
          </Button>
          <Button
            type={this.state.selection === 3 ? 'primary' : null}
            style={Style.button}
            onClick={() => this.buttonSelection(3)}
          >{intl.formatMessage({ id: 'dateTimePicker.l30days' })}
          </Button>
          <Button
            type={this.state.selection === 4 ? 'primary' : null}
            style={Style.button}
            onClick={() => this.buttonSelection(4)}
          >{intl.formatMessage({ id: 'dateTimePicker.tMonth' })}
          </Button>
          <Button
            type={this.state.selection === 5 ? 'primary' : null}
            style={Style.button}
            onClick={() => this.buttonSelection(5)}
          >{intl.formatMessage({ id: 'dateTimePicker.lMonth' })}
          </Button>
          <Button
            type={this.state.selection === 6 ? 'primary' : null}
            style={Style.button}
            onClick={() => this.buttonSelection(6)}
          >{intl.formatMessage({ id: 'dateTimePicker.custom' })}
          </Button>
        </div>
        <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
          {
            this.state.show ?
              rightPart : null
          }
        </div>
      </div>
    )

    return (
      <Popover
        placement="bottom"
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.onPopupChanged}
      >
        <Button style={this.props.style} icon={<CalendarOutlined />}>{this.displayValue()}</Button>
      </Popover>
    )
  }
}

const Style = {
  button: {
    display: 'block',
    width: '100%',
    marginBottom: '5px'
  }
}

export default injectIntl(DateTimeRangePicker)
