export const collapsedTypes = {
  toggle: `Toggle Collapsed`,
  close: `Close Collapsed`,
}

const CollapsedInit = false

export default (state = CollapsedInit, action) => {
  switch (action.type) {
    case collapsedTypes.toggle:
      return !state
    case collapsedTypes.close:
      return true
    default:
      return state
  }
}
