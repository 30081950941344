import API from './index'
import { getUserInfo } from '../helper/helper'

export default {
  async login(body) {
    try {
      const response = await API.post('/user/login', body)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async generateQRCode() {
    try {
      const response = await API.post(`/user/qrcode/${getUserInfo().user.id}`, null)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async findOneModel() {
    try {
      const headers = {
        'merchant': getUserInfo().user.merchant
      }
      const response = await API.get(`/user/model/${getUserInfo().user.id}`, { headers })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async pushUrl(roomType = 'normal') {
    try {
      const headers = {
        'merchant': getUserInfo().user.merchant
      }
      const params = { roomType }
      const response = await API.get(`/user/pushUrl`, { headers, params })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async setGame(body) {
    try {
      const headers = {
        'merchant': getUserInfo().user.merchant
      }
      const response = await API.put(`/user/model/${getUserInfo().user.id}/game`, body, { headers })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async userInfo() {
    try {
      const headers = {
        'merchant': getUserInfo().user.merchant
      }
      const response = await API.get(`/user/modelBO`, { headers })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async setUserInfo(body) {
    try {
      const headers = {
        'merchant': getUserInfo().user.merchant
      }
      const response = await API.put(`/user/modelBO`, body, { headers })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async upload(file, type) {
    const headers = {
      'merchant': getUserInfo().user.merchant
    }
    try {
      let data = new FormData()
      data.append('file', file)
      data.append('type', type)

      const response = await API.put(`/user/modelBO/upload`, data, { headers })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
}
