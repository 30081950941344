import React from 'react'
import { DatePicker, Table, Badge, Button, notification, Popconfirm, Typography } from 'antd'
import moment from 'moment'
import { injectIntl } from 'react-intl'
import AnchorApi from '../../../api/Sport/anchor'
import { UserOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'

Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + days);
  return this;
}


const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
const { Text } = Typography;

class MyEvents extends React.Component {

  state = {
    form: moment(new Date(), dateFormat).set({ hour: 0, minute: 0, second: 0 }),
    to: moment(new Date().addDays(7), dateFormat).set({ hour: 23, minute: 59, second: 59 }),
    dataSource: [],
    tableLoading: false
  }

  columns = [
    {
      title: this.props.intl.formatMessage({ id: 'Sports.date' }),
      dataIndex: 'startAt',
      sortOrder: 'ascend',
      align: 'center',
      width: '150px',
      sorter: (a, b) => moment(a.startAt).unix() - moment(b.startAt).unix(),
      render: date => {
        const at = moment(new Date(date)).format('YYYY-MM-DD /-/-/ HH:mm').split('/-/-/')
        return (
          <div>
            {at[0]}
            <br></br>
            {at[1]}
          </div>
        )
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.leagueName' }),
      dataIndex: 'leagueName',
      align: 'center',
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.eventName' }),
      dataIndex: 'eventName',
      align: 'center',
      render: text => {
        const homeGuest = text.split('/-/-/')
        return (
          <div>
            {homeGuest[0]}
            <br></br>
            <br></br>
            {homeGuest[1]}
          </div>
        )
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.liveStatus' }),
      dataIndex: 'myLiveStatus',
      align: 'center',
      render: (status) => (this.onAirStatus(status))
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.customerCount' }),
      dataIndex: 'customerReserveCount',
      align: 'center',
      render: (count) => (
        <Text><UserOutlined /> {count}</Text>
      )
    },
    {
      title: this.props.intl.formatMessage({ id: 'Sports.action' }),
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => (
        <Popconfirm
          placement="topRight"
          disabled={record.myLiveStatus !== 'never'}
          title={this.props.intl.formatMessage({ id: 'Sports.cancelEvent' })}
          onConfirm={() => this.confirm(record)}
          okText={this.props.intl.formatMessage({ id: 'Sports.ok' })}
          cancelText={this.props.intl.formatMessage({ id: 'Sports.cancel' })}
        >
          <Button
            disabled={record.myLiveStatus !== 'never'}
            danger>
            {this.props.intl.formatMessage({ id: 'Sports.cancel' })}
          </Button>
        </Popconfirm>
      )
    }
  ]

  confirm = async (item) => {
    const result = await AnchorApi.delReserve(item.id)
    if (result.status === 200) {
      const data = [...this.state.dataSource].filter(e => e.id != item.id)

      this.setState({ dataSource: data })
      this.openNotificationWithIcon('success')
    }
  }

  openNotificationWithIcon = (type) => {
    notification[type]({
      message: this.props.intl.formatMessage({ id: 'Sports.cancelEventSuccess' })
    });
  };

  onAirStatus = (ststus) => {
    switch (ststus) {
      case "onair":
        return (<Badge
          status="processing"
          text={this.props.intl.formatMessage({ id: 'Sports.onair' })}
        />)
      case "over":
        return (<Badge
          status="default"
          color='green'
          text={this.props.intl.formatMessage({ id: 'Sports.over' })}
        />)
      case "never":
        return (<Badge
          status="default"
          text={this.props.intl.formatMessage({ id: 'Sports.never' })}
        />)

    }
  }

  componentDidMount() {
    this.query(
      this.state.form.utc().format('YYYY-MM-DD HH:mm:ss'),
      this.state.to.utc().format('YYYY-MM-DD HH:mm:ss')
    )
  }

  query = async (from, to) => {
    this.setState({ tableLoading: true })
    const result = await AnchorApi.reserveEvent(this.props.user.merchant, from, to)
    if (result.status === 200) {
      const vaule = [...result.data]
      vaule.forEach((even) => {
        even["eventName"] =
          `${even.homeTeamName} ${this.props.intl.formatMessage({ id: 'Sports.home' })}
         /-/-/
         ${even.guestTeamName} ${this.props.intl.formatMessage({ id: 'Sports.guest' })}`
      })
      this.setState({ dataSource: vaule, tableLoading: false })
    }
  }

  onChange = (dates, dateStrings) => {
    this.query(
      moment(dates[0].toISOString()).set({ hour: 0, minute: 0, second: 0 }).utc().format('YYYY-MM-DD HH:mm:ss'),
      moment(dates[1].toISOString()).set({ hour: 23, minute: 59, second: 59 }).utc().format('YYYY-MM-DD HH:mm:ss')
    )
  }

  render() {
    return (
      <>
        <RangePicker
          defaultValue={[moment(new Date(), dateFormat), moment(new Date().addDays(7), dateFormat)]}
          allowClear={false}
          onChange={this.onChange}
        />
        <Table
          style={{ marginTop: '20px' }}
          dataSource={this.state.dataSource}
          columns={this.columns}
          pagination={{ pageSize: 15 }}
          bordered={true}
          loading={this.state.tableLoading}
        />
      </>
    )
  }
}

const mapStateToProps = store => (
  { user: store.user }
)

export default connect(mapStateToProps)(injectIntl(MyEvents))