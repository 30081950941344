import React from 'react'
import { Card, Statistic } from 'antd'
import { injectIntl } from 'react-intl'


const BeautyPanel = (props) => {
  const { anchorListInfo, intl } = props
  const valueStyle = { textAlign: "right" }

  return (
    <div className="monitor-beauty-header">
      <div className="monitor-beauty-header-content">
        <Card>
          <Statistic title={intl.formatMessage({ id: 'familyMonitor.anchor_count' })}
                     loading={anchorListInfo.loading}
                     value={anchorListInfo.data.length}
                     valueStyle={valueStyle}/>
        </Card>
        <Card>
          <Statistic title={intl.formatMessage({ id: 'familyMonitor.anchor_live_count' })}
                     loading={anchorListInfo.loading}
                     value={anchorListInfo.data.filter(data => data.roomInfo.type === "normal").length}
                     valueStyle={valueStyle}/>
        </Card>
        <Card>
          <Statistic title={intl.formatMessage({ id: 'familyMonitor.anchor_porn_live_count' })}
                     loading={anchorListInfo.loading}
                     value={anchorListInfo.data.filter(data => data.roomInfo.type !== "normal").length}
                     valueStyle={valueStyle}/>
        </Card>
        <Card>
          <Statistic title={intl.formatMessage({ id: 'familyMonitor.clientCount' })}
                     loading={anchorListInfo.loading}
                     value={anchorListInfo.data.reduce((sum, anchor) =>
                      anchor.onlineCount && anchor.onlineCount > 0
                        ? sum + (anchor.onlineCount - 1)
                        : sum, 0)
                     }
                     valueStyle={valueStyle}/>
        </Card>
      </div>
    </div>

  )

}

export default injectIntl(BeautyPanel)
