import React, { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'

import MonitorAPI from '../../../../api/Family/monitor'
import LiveAPI from "../../../../api/Family/live"
import { getUserInfo } from '../../../../helper/helper'


const useData = (props) => {
  const {socket, intl} = props
  const [anchorListInfo, setAnchorListInfo] = useState({
    loading: true,
    data: []
  })
  const [monitorAnchorId, setMonitorAnchorId] = useState(null)

  const openMonitorModal = useCallback((anchorId) => setMonitorAnchorId(anchorId), [])
  const closeMonitorModal = useCallback(() => setMonitorAnchorId(null), [])
  const closeRoom = useCallback((anchorId) => {
    LiveAPI.stop({ id: anchorId, merchant: getUserInfo().user.merchant })
      .then(() => {
        message.success(intl.formatMessage({ id: 'familyMonitor.kick_success' }))
        fetchAnchorList()
      })
  }, [])

  const fetchAnchorList = useCallback(() => {
    MonitorAPI.getAnchorList()
      .then((anchorResults) => {
        const data = anchorResults.data.map(data => ({
          ...data,
          openMonitorModal: () => openMonitorModal(data.id),
          closeMonitorModal,
          closeRoom: () => closeRoom(data.id)
        }))
        setAnchorListInfo({ loading: false, data })
      })
      .finally(() => {
        setAnchorListInfo(prevState => ({ ...prevState, loading: false }))
      })
      .catch(() => {
        message.error('get anchors error')
      })
  }, [])

  const changeAnchorOnlineCount = useCallback((onlineCountData) => {
    setAnchorListInfo(prevState => {
      const anchorIndex = prevState.data.findIndex(data => data.id === onlineCountData.anchorId)
      prevState.data[anchorIndex] = { ...prevState.data[anchorIndex], onlineCount: onlineCountData.count }
      if (anchorIndex >= 0) {
        return { ...prevState, data: [...prevState.data] }
      } else {
        return prevState
      }
    })
  }, [])

  useEffect(() => {
    fetchAnchorList()
  }, [])

  useEffect(() => {
    if (socket.current) {
      socket.current.on("model/onair/changed", fetchAnchorList)
      socket.current.on("chat/room/count", changeAnchorOnlineCount)
    }
    return () => {
      if (socket.current) {
        socket.current.off("model/onair/changed", fetchAnchorList)
        socket.current.off("chat/room/count", changeAnchorOnlineCount)
      }
    }
  }, [socket])


  useEffect(() => {
    //主播下線時, monitor set null
    if (!anchorListInfo.data.find(data => data.id === monitorAnchorId)) {
      setMonitorAnchorId(null)
    }
  }, [anchorListInfo])

  return {
    anchorListInfo,
    monitorInfo: anchorListInfo.data.find(data => data.id === monitorAnchorId)
  }

}

export default useData
