import React, { useRef, useEffect, useCallback } from 'react'


const DecodePlayer = props => {
  const { streamUrl, isEncode = true, changeChannelTypeToFlv } = props
  const videoRef = useRef('')
  const unMounted = useRef(false)
  const player = useRef()

  const setSources = src => {
    if (!player.current || !src || unMounted.current) return
    player.current.loadSource(src)
  }

  const destroyPlayer = () => {
    if (player.current) {
      player.current.destroy()
      player.current = null
    }
  }

  const launchVideo = () => {
    if (player.current) {
      player.current.destroy()
      player.current = null
    }
    if (window.Hls && window.Hls.isSupported()) {
      player.current = new window.Hls({ isEncode })
      player.current.attachMedia(videoRef.current)

      videoRef.current.addEventListener('loadedmetadata', function () {
        videoRef.current.play()
      })

      setSources(streamUrl)
    }
  }

  useEffect(() => {
    unMounted.current = false
    launchVideo()
    return () => {
      unMounted.current = true
      destroyPlayer()
    }
  }, [])

  return (
    <div style={{ width: '100%', height: '100%', background: '#001529', position: "relative" }}>
      <div
        onClick={changeChannelTypeToFlv}
        style={{
          position: "absolute",
          color: "white",
          top: 0,
          right: 0,
          width: 15,
          height: 15,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontSize: 12,
          zIndex: 1,
          borderRadius: 15,
          border: "1px solid"
        }}>H
      </div>
      <div style={{
        position: 'absolute',
        color: '#fff',
        top: 5,
        padding: '5px 10px',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#fff',
        borderRadius: 25,
        zIndex: 1
      }} />
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        style={{ width: '100%', height: '100%', background: '#001529' }}
        controls={true}
        muted
        autoPlay={true}
        playsInline={true}
        webkit-playsinline="true"
        x5-playsinline="true"
      />
    </div>

  )
}

export default DecodePlayer
