import React, { useEffect, useRef, useState } from 'react'
import { Button, Input } from "antd"
import Message, { messageType } from "./Message"

import { injectIntl } from 'react-intl'

const InputArea = (props) => {
  const { sendMessage, intl } = props

  const [value, setValue] = useState("")
  const sendMessageHandle = () => {
    sendMessage(value)
    setValue("")
  }
  return (
    <Input
      className="input"
      value={value}
      onChange={(event) => setValue(event.target.value)}
      placeholder={intl.formatMessage({ id: 'familyMonitor.inputPlaceholder' })}
      suffix={
        <Button onClick={sendMessageHandle}>
          {intl.formatMessage({ id: 'familyMonitor.send' })}
        </Button>
      }
      onPressEnter={sendMessageHandle}
    />
  )
}

const Chat = (props) => {
  const { socket, anchorInfo, intl } = props
  const [messages, setMessages] = useState([])
  const chatBodyRef = useRef()

  const sendMessage = (value) => {
    socket.current && socket.current.emit("chat/message", { message: value, anchorId: anchorInfo.id })
  }

  useEffect(() => {
    if (socket.current) {

      const addMessageHandle = (emitObject) => {
        if (emitObject.anchorId === anchorInfo.id) {
          setMessages(prevState => [...prevState, emitObject])
        }
      }

      const roomEnterHandle = () => {
        socket.current.emit("room/enter", anchorInfo.id)
        setMessages(prevState => [...prevState, { type: messageType.system, data: { msg: "Connected" } }])
      }

      const roomDiscountHandle = () => {
        setMessages(prevState => [...prevState, { type: messageType.system, data: { msg: "Disconnect" } }])
      }

      roomEnterHandle()
      socket.current.on("chat/message", addMessageHandle)
      socket.current.on("connectComplete", roomEnterHandle)
      socket.current.on("disconnect", roomDiscountHandle)
      return () => {
        socket.current.emit("room/leave", anchorInfo.id)
        socket.current.off("chat/message", addMessageHandle)
        socket.current.off("connectComplete", roomEnterHandle)
        socket.current.off("disconnect", roomDiscountHandle)
      }
    }

  }, [socket])

  useEffect(() => {
    chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight
  }, [messages])

  return (
    <div className="monitor-chat-warp">
      <div className="message-body" ref={chatBodyRef}>
        {
          messages.map(message => (
            <Message message={message}/>
          ))
        }
      </div>
      <InputArea sendMessage={sendMessage} intl={intl}/>
    </div>
  )

}

export default injectIntl(Chat)
