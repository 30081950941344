import store from '../index'
import { collapsedTypes } from '.'

export const toggleCollapsed = () => {
  store.dispatch({ type: collapsedTypes.toggle })
}

export const closeCollapsed = () => {
  store.dispatch({ type: collapsedTypes.close })
}
