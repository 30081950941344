import API from './index'

export default {
    async applist() {
        try {
            const response = await API.get(`/app/both/new`)
            return response
        } catch (e) {
            if (e.response) return e.response.data.message
            if (e.message) return e.message
        }
    },
}