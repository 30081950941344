import { i18nObject } from '../helper/helper'

const language = {
  app: {
    mobileLogin: '手機登入',
    logout: '登出',
    qrcodeDesc: '請打開手機APP掃碼登入'
  },
  familyMonitor: {
    anchor_count: '目前主播人數',
    anchor_live_count: '「綠播」主播人數',
    anchor_porn_live_count: '「色播」主播人數',
    clientCount: '觀看直播總人數',
    no_anchor: '無主播直播中',
    sureRemove: '確定踢下線?',
    cancel: '取消',
    confirm: '確定',
    remove: '踢下線',
    monitor_live: '監控直播',
    sendGift: '送給主播',
    monitor: '監控人員',
    enterRoom: '進入房間',
    anchor_live: '錄播',
    anchor_porn_live: '色播',
    inputPlaceholder: '監控人員給主播的私密訊息...',
    send: '送出',
    kick_success: '操作成功, 更新主播列表',
  },
  userInfo: {
    icon: '大頭照',
    backgroundImage: '背景圖',
    name: '主播名稱',
    name_en: '主播英文名',
    email: '主播郵箱',
    password: '密碼',
    birthday: '生日',
    height: '身高',
    weight: '體重',
    width: '三圍',
    blood: '血型',
    constellation: '星座',
    language: '語言',
    education: '學歷',
    job: '工作經驗',
    interest: '興趣',
    movie: '最喜歡的電影',
    food: '最喜歡的食物',
    actor: '最喜歡的藝人',
    game: '最喜歡的遊戲',
    number: '幸運數字',
    introduction: '自我介紹',
    modifyPassword: '修改密碼',
    modify: '修改'
  },
  routes: {
    info: '個人资料',
    dashboard: '儀表板',
    liveControl: '直播管理',
    giftHistory: '打賞紀錄',
    liveHistory: '開播紀錄',
    modelPlayer: '全部直播瀏覽',
    RoomOrder: '房費紀錄',
    analysis: '開播數據分析',
    userIncome: '主播收益紀錄',
    familyManager: '家族管理',
    familyIncome: '家族收益紀錄',
    events: '賽事管理',
    SportStatistics: '查詢賽事开播統計',
    monitor_live: '監控直播'
  },
  global: {
    update: '更新',
    query: '查詢',
    dateRange: '日期區間',
    all: '全部',
    export: '匯出'
  },
  dateTimePicker: {
    today: '今天',
    yesterday: '昨天',
    l7days: '最近七天',
    l30days: '最近三十天',
    tMonth: '這個月',
    lMonth: '上個月',
    custom: '自訂區間',
  },
  lasSocket: {
    disconnect: '已中斷連線',
    connect: '已連線',
    loginFailed: '登入失敗'
  },
  login: {
    title: '主播登入',
    account: '帳號',
    accountPlaceholder: 'Email',
    password: '密碼',
    modelLogin: '主播登入',
    adminLogin: '管理員登入',
    familyLogin: '家族登入',
    adminPlaceholder: '管理員帳號',
  },
  giftHistory: {
    model: '主播名稱',
    fromName: '打賞帳號',
    giftInfo: '禮物',
    amount: '總額',
    createdAt: '日期',
    sum: '打賞總和'
  },
  liveHistory: {
    createdAt: '開始時間',
    updatedAt: '結束時間',
    total: '總和',
    type: '類型',
    all: '全部',
    type0: '直播室',
    type1: '手機推流'
  },
  liveAnalysis: {
    date: '日期',
    liveCount: '開播次數',
    totalInterval: '開播時長',
    watchedCounts: '觀看次數',
    clientCounts: '觀看人數',
    giftSum: '禮物金額',
    betCount: '投注人數',
    winOrLose: '玩家盈虧',
    eBet: '電子投注',
    eBetWin: '電子中獎',
    liveBet: '真人投注',
    liveValidBet: '真人有效投注',
    liveWin: '真人中獎',
    lotteryBet: '彩票投注',
    lotteryWin: '彩票中獎',
    battleLostSum: '對戰輸額',
    battleValidBet: '對戰有效投注',
    battleWinSum: '對戰赢額',
    fishBet: '捕鱼投注',
    fishWin: '捕鱼中獎',
    sportBet: '體育投注',
    sportValidBet: '體育有效投注',
    sportWin: '體育中獎',
    handicapBet: '盤口彩票投注',
    handicapWin: '盤口彩票中獎',
    statisticTime: '統計時間',
    model: '主播名稱',
    privateFees: '房費',
    totalGiftSum: '禮物金額總計',
    totalPrivateFees: '房費總計',
  },
  chat: {
    gift: '送給你',
    heart: '贈送愛心',
    monitor: '監控人員',
    model: '主播',
    placeholder: '說點什麼...',
    betting: '投注了',
    win: '中獎了',
    send: '送出',
    enter: '進入房間',
    focus: '已關注主播',
    manager: '房管'
  },
  games: {
    series: '{series} 期',
    whichSeries: '第 {series} 期',
    lottery: '彩票',
    esport: '電競',
    sport: '體育',
    littleGame: '小遊戲',
    comingSoon: '敬請期待',
    gameSelected: '目前所選遊戲',
    gameRecommend: '確定發送推薦',
    gameCancel: '取消推薦',
    menu: '遊戲選單',
    binding: '綁定中',
    bind: '綁定',
    cancelBind: '取消綁定',
  },
  liveControl: {
    scheduledAt: '預計開播時間',
    webStart: '開啟直播室',
    webEnd: '關閉直播室',
    mobile: '手機 APP',
    PC: 'OBS 助手',
    PCStream: 'PC推流工具',
    PCStreaming: 'PC推流工具 - 推流中',
    pushUrl: '串流網址',
    pushKey: '串流金鑰',
    OBSContent: '溫馨提示：用OBS助手等工具，配置推流地址後在助手上開始推流後，再點擊下方的“開始”按鈕',
    loginQRCode: '點擊獲取登入二維碼',
    onAir: '開始直播',
    selectDevice: '請選擇直播設備',
    normal: '綠播',
    vip: '大秀',
    private: '私密收費',
    onAirType: '開房類型',
    roomLevel: '入房等級',
    roomWatch: '觀看時間',
    roomAmount: '入房金額',
    min: '分',
    unrestricted: '不限',
    qrcodeMark: '該二維碼5分鐘內有效，過期請重新獲取'
  },
  RoomOrder: {
    customerName: '用戶名',
    createdAt: '交易時間',
    fee: '金額',
    minute: '時數 (分)',
  },
  UserIncome: {
    Date: '日期',
    onairDuration: '開播時長',
    Wage: '時薪',
    giftSplite: '禮物分成(%)',
    roomFeeSplite: '房費分成(%)',
    giftIncome: '禮物收入',
    roomFeeIncome: '房費收入',
    onairIncome: '開播收益',
    totalGiftIncome: '禮物收益',
    totalRoomFeeIncome: '房費收益',
    totalIncome: '收益小結'
  },
  FamilyIncome: {
    Date: '日期',
    users: '主播數',
    Wage: '時薪',
    giftSplite: '禮物分成(%)',
    roomFeeSplite: '房費分成(%)',
    onairDuration: '開播時長',
    Income: '家族收益',
    amount: '結算金額',
    amount_na: '未結算金額',
    endDate: '結算時間',
    option: '操作',
    memberProfit: '主播收益支出',
    detail: '主播明細',
    sum: '總計',
  },
  UserDetails: {
    userId: '主播 ID',
    familyName: '家族名稱',
    userName: '主播名稱',
    Wage: '時薪',
    giftSplite: '禮物分成(%)',
    roomFeeSplite: '房費分成(%)',
    onairDuration: '開播時長',
    giftIncome: '禮物收入',
    roomFeeIncome: '房費收入',
    onairIncome: '開播收益',
    totalGiftIncome: '禮物收益',
    totalRoomFeeIncome: '房費收益',
    totalIncome: '主播收益',
    total: '總計',
  },
  PopModifyPassword: {
    modifyPassword: '修改密碼',
    cancel: '取消',
    newPassword: '輸入新密碼',
    chackPassword: '再次輸入新密碼',
    success: '修改成功',

    chackFailure: '兩次輸入密碼不相符',
    inputPassword: '6~10碼且至少包含一個英文字符'
  },
  LineChart: {
    date: '日期',
    points: '點數',
  },
  FamilyInfo: {
    modelName: '主播名稱',
    modelEmail: '主播郵箱',
    hourlyRate: '時薪',
    giftAmountRate: '禮物分成 (%)',
    privateRoomFeeRate: '房費分成 (%)',
    popular: '當前主播人氣',
    onair: '直播中',
    isValid: '狀態',
    createdAt: '創建時間',
    option: '操作',

    familyInfo: '家族資訊',
    familyName: '家族名稱',
    bindEmail: '綁定郵箱',
    memberCount: '主播數',
    memberList: '主播列表',
  },
  Sports: {
    myEvents: '我的賽事',
    sportsEvents: '賽事列表',
    date: '日期',
    leagueName: '聯盟',
    eventName: '賽事名稱',
    liveStatus: '是否開播',
    customerCount: '預約觀眾數',
    action: '操作',
    cancelEvent: '確認取消預約賽事?',
    fllowEvent: '確認預約此場賽事?',
    ok: '確認',
    cancel: '取消',
    cancelEventSuccess: '取消預約成功',
    fllowEventSuccess: '預約成功',
    fllowed: '已預約',
    fllow: '預約',
    onair: '開播中',
    over: '已開播',
    never: '未開播',
    home: '(主)',
    guest: '(客)',
    region: '地區',
    allLeague: '全部聯盟',
    allRegion: '全部地區',
    fllowedAnchor: '已預約主播數',
    fllowEventError: '此場賽事主播數已額滿',
    fllowFull: '預約已滿',

  },
  SportLive: {
    main: '(主)',
    guest: '(客)',
    selectEvent: '選擇直播賽事',
    seletcEventLimitNotice: '* 僅顯示未來 12 小時內已預約的賽事',
    liveEvent: '直播賽事',
    streamUrl: '串流網址',
    streamKey: '串流金鑰',
    tooltip: '溫馨提示：用OBS助手等工具，配置推流地址後在助手上開始推流後，再點擊下方的“開始”按鈕',
    startLive: '開始直播',
    stopLive: '關閉直播',
    screenSwitch: '視頻顯示',
    alartMessage: "未偵測到OBS助手等工具推流數據，請重新嘗試推流再開啟 '視頻顯示'。",
    twoHours: '2 小時',
    oneDay: '1 天',
    threeDay: '3 天',
    aMonth: '30 天',
    infinite: '永久',
    becomeManager: '已成為房管',
    cancelledManager: '已取消房管',
    has: '已將',
    mute: '禁言',
    addManager: '設定為房管',
    delManager: '移除房管',
    manager: '房管',
    muteList: '禁言名單',
    other: '其他',
    customTitle: '請輸入自定義直播標題',
    customMessage: '請輸入標題名稱'
  },
  MuteList: {
    name: '暱稱',
    muteTime: '被禁言時間',
    timeLeft: '剩餘時間',
    end: '已結束',
    manager: '房管',
    action: '操作',
    has: '已將',
    disMute: '解除禁言'
  },
  SportStatistics: {
    liveStartAt: '直播開始時間',
    liveOverAt: '直播結束時間',
    startAt: '開賽日期',
    eventName: '賽事名稱',
    gamePrizeSum: '費用',
    gameGiftSum: '送禮金額',
    main: '(主)',
    guest: '(客)',
  },
  SportUserInfo: {
    setting: '基本設置',
    background: '背景圖設置',
    private: '安全設置'
  },
  SportPrivateSetting: {
    account: '帳戶密碼'
  },
  SportUserSetting: {
    name: '名稱',
    email: '信箱',
    introduction: '自我介紹',
    announcement: '主播公告',
    uploadData: '更新基本訊息',
    uploadAvatar: '更換頭像'
  },
  SportCommonTypes: {
    Soccer: '足球',
    Basketball: '籃球',
    Tennis: '網球',
    Volleyball: '排球',
    Baseball: '棒球',
    AmericanFootball: '美式足球',
    IceHockey: '冰上曲棍球',
    ESports: '電子競技',
    Handball: '手球',
    Snooker: '撞球',
    Badminton: '羽球',
    TableTennis: '桌球',
    Boxing: '拳擊',
    WaterPolo: '水球',
    Floorball: '地板球',
    Squash: '壁球',
    Cricket: '板球',
    RugbyUnion: '聯合式橄欖球',
    RugbyLeague: '聯盟式橄欖球',
    Darts: '飛鏢',
    Futsal: '室內五人足球',
    BeachVolleyball: '沙灘排球',

    Others: '其它'
  }
}

export default i18nObject(language)

