import API from '../index'

const path = 'family'

export default {
  async login (body) {
    try {
      const response = await API.post(`/${path}/login`, body)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
}
