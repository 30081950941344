import React from 'react'
import { List, Space, Button, Modal, Form, Input, message } from 'antd'
import { injectIntl } from 'react-intl'
import UserInfoAPI from '../../../../api/user'

class PrivateSetting extends React.Component {

    state = {
        popPasswordModal: false,
        password: '',
        chackPassword: '',
    }

    modifyPassword = () => {
        this.setState({ popPasswordModal: true })
    }

    handleOk = async () => {
        if (this.state.password === '') return
        if (this.state.password === this.state.chackPassword) {
            const ret = await UserInfoAPI.setUserInfo({ password: this.state.password })
            if (ret.status === 200) {
                message.success(this.props.intl.formatMessage({ id: 'PopModifyPassword.success' }))
                this.setState({ popPasswordModal: false })
            } else {
                message.error(this.props.intl.formatMessage({ id: 'PopModifyPassword.inputPassword' }))
            }
        } else {
            message.error(this.props.intl.formatMessage({ id: 'PopModifyPassword.chackFailure' }));
        }
    };

    handleCancel = () => {
        this.setState({ popPasswordModal: false })
    };

    onChangePassword = (text, type) => {
        if (type == 'password') {
            this.setState({ password: text.target.value })
        } else {
            this.setState({ chackPassword: text.target.value })
        }
    }

    render() {
        return (
            <>
                <List size="small">
                    <List.Item>
                        <Space align='baseline'>
                            {this.props.intl.formatMessage({ id: 'SportPrivateSetting.account' })}
                        </Space>
                        <Button type='link' onClick={this.modifyPassword}>
                            {this.props.intl.formatMessage({ id: 'PopModifyPassword.modifyPassword' })}
                        </Button>
                    </List.Item>

                    <List.Item />
                </List>

                <Modal
                    title={this.props.intl.formatMessage({ id: 'PopModifyPassword.modifyPassword' })}
                    visible={this.state.popPasswordModal}
                    closable={false}
                    okText={this.props.intl.formatMessage({ id: 'userInfo.modify' })}
                    cancelText={this.props.intl.formatMessage({ id: 'PopModifyPassword.cancel' })}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}>
                    <Form
                        style={{ paddingLeft: '10px' }}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        layout="vertical"
                        name="basic"
                        initialValues={{ remember: true }}
                    >
                        <Form.Item label={this.props.intl.formatMessage({ id: 'PopModifyPassword.newPassword' })}>
                            <Input.Password onChange={(text) => this.onChangePassword(text, 'password')} />
                        </Form.Item>
                        <Form.Item label={this.props.intl.formatMessage({ id: 'PopModifyPassword.chackPassword' })}>
                            <Input.Password onChange={(text) => this.onChangePassword(text, 'chack')} />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
    }
}

export default injectIntl(PrivateSetting)