import API from './index'
import { getUserInfo } from '../helper/helper'

const path = 'familyProfit/modelBO'

export default {
  async list(q) {
    try {
      let query = ''
      if (q && q.from) {
        query += `&from=${q.from}`
      }
      if (q && q.to) {
        query += `&to=${q.to}`
      }

      const response = await API.get(`/${path}/?limit=10&page=${q.page}${query}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async admin(q) {
    try {
      let query = ''
      if (q && q.from) {
        query += `&from=${q.from}`
      }
      if (q && q.to) {
        query += `&to=${q.to}`
      }

      const response = await API.get(`/familyProfit/familyBO/?limit=10&page=${q.page}${query}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async adminMember(q) {
    try {
      const response = await API.get(`/familyProfit/familyBO/member/?date=${q.date}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}
