import React from 'react'
import { injectIntl } from 'react-intl'
import Config from "../../../../../config/env"

export const messageType = {
  text: "text",
  gift: 'gift',
  monitorText: 'monitorText',
  customerEnterRoom: 'customerEnterRoom',
  system: 'system'
}

const Message = (props) => {
  const { message, intl } = props

  switch (message.type) {
    case messageType.text:
      return (
        <div className="message-item">
          <div className="content">
            <span className="name">{message.data.nickname}</span>
            <span className="msg">{message.data.msg}</span>
          </div>
        </div>
      )
    case messageType.gift :
      return (
        <div className="message-item">
          <div className="content">
            <span className="name">{message.data.nickname}</span>
            <span className="msg">
                {intl.formatMessage({ id: 'familyMonitor.sendGift' })} {message.data.giftName} <img src={Config.lasHost+message.data.giftIcon} alt="gift"/>
              </span>
          </div>
        </div>
      )
    case messageType.monitorText:
      return (
        <div className="message-item">
          <div className="content monitor">
            <span className="name">{intl.formatMessage({ id: 'familyMonitor.monitor' })}</span>
            <span className="msg">{message.data.msg}</span>
          </div>
        </div>
      )

    case messageType.customerEnterRoom:
      return (
        <div className="message-item center">
          <div className="content">
            <span className="name">{message.data.nickname}</span>
            <span className="msg">{intl.formatMessage({ id: 'familyMonitor.enterRoom' })}</span>
          </div>
        </div>
      )

    case messageType.system:
      return (
        <div className="message-item center">
          <div className="content error">
            <span className="msg">{message.data.msg}</span>
          </div>
        </div>
      )
    default :
      return null
  }

}

export default injectIntl(Message)
