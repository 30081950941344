import React, { useState } from 'react'
import HlsPlayer from "./HlsPlayer"
import FlvPlayer from "./FlvPlayer"
import { useScript } from "use-script"

const VideoPlayer = props => {
  const { roomType, channel } = props
  const [channelType, setChannelType] = useState("flv")

  const changeChannelTypeToHls = () => {
    setChannelType("hls")
  }

  const changeChannelTypeToFlv = () => {
    setChannelType("flv")
  }

  const { loading: FlvScriptLoading } = useScript({
    src: './js/flv.min.js',
  })

  const { loading: HlsScriptLoading } = useScript({
    src: './js/hls.min.js',
  })

  if (!channel) return null

  switch (channelType) {
    case "flv":
      return (!FlvScriptLoading ?
        <FlvPlayer roomType={roomType} streamUrl={channel.flv} changeChannelTypeToHls={changeChannelTypeToHls} /> : null)
    case "hls":
      return (!HlsScriptLoading ? <HlsPlayer roomType={roomType} changeChannelTypeToFlv={changeChannelTypeToFlv} streamUrl={channel.hls} /> : null)
  }
}

export default VideoPlayer
