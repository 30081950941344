import React, { Component } from 'react'
import RangePicker from '../../forms/DateTimeRangePicker'
import PrefixLabel from '../../forms/PrefixLabel'
import { Row, Col, Button, Table } from 'antd'
import { injectIntl } from 'react-intl'
import { SyncOutlined } from '@ant-design/icons';
import PrivateFeeOrderAPI from '../../../api/privateFeeOrder'

class GiftHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      pagination: { current: 1 },
      from: null,
      to: null,
      results: [],
    }

    this.columns = [
      {
        title: props.intl.formatMessage({ id: 'RoomOrder.customerName' }),
        dataIndex: 'customerName'
      },
      {
        title: props.intl.formatMessage({ id: 'RoomOrder.createdAt' }),
        dataIndex: 'createdAt',
        render: datetime => (new Date(datetime)).toLocaleString([], { hour12: false })
      },
      {
        title: props.intl.formatMessage({ id: 'RoomOrder.fee' }),
        dataIndex: ['privateFee', 'fee'],
      },
      {
        title: props.intl.formatMessage({ id: 'RoomOrder.minute' }),
        dataIndex: ['privateFee', 'minute'],
      },
    ]
  }

  onRangePickerInit = (moment) => {
    this.setState({ from: moment.startDate, to: moment.endDate })
  }

  onRangePickerChanged = (moment) => {
    const { pagination } = this.state
    this.setState({ from: moment.startDate, to: moment.endDate })
    this.query(moment.startDate.toISOString(), moment.endDate.toISOString(), pagination.current)
  }

  onSearchClick = () => {
    const { from, to, pagination } = this.state
    this.query(from.toISOString(), to.toISOString(), pagination.current)
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { from, to } = this.state
    this.setState({ pagination, })
    this.query(from.toISOString(), to.toISOString(), pagination.current)
  }

  query = async (from, to, page) => {
    this.setState({ loading: true })
    const ret = await PrivateFeeOrderAPI.list({ from, to, page })
    if (ret.status === 200) {
      const pagination = { ...this.state.pagination }
      pagination.total = ret.data.total
      this.setState({ results: ret.data.value, pagination, loading: false })
    }
  }

  render() {
    const { intl } = this.props

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={4}>
            <PrefixLabel
              text={intl.formatMessage({ id: 'global.dateRange' })}
            >
              <RangePicker
                style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onInit={this.onRangePickerInit}
                onChange={this.onRangePickerChanged}
              />
            </PrefixLabel>
          </Col>
          <Col span={4} offset={16}>
            <Button type="primary" icon={<SyncOutlined />} loading={this.state.loading}
              onClick={this.onSearchClick}
              style={{ float: 'right' }}
            >
              {intl.formatMessage({ id: 'global.update' })}
            </Button>
          </Col>
        </Row>
        <Table
          rowKey={record => record.id}
          columns={this.columns}
          dataSource={this.state.results}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          bordered
        />
      </div>
    )
  }
}

export default injectIntl(GiftHistory)
