import React, { useState } from 'react'
import { ApiOutlined, LoginOutlined, TeamOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Popconfirm } from 'antd'

import Config from "../../../../../config/env"
import Ribbon from "../../../../../components/Badge/Ribbon"

import { injectIntl } from 'react-intl'

const AnchorCard = React.memo((props) => {
  const { anchorInfo, intl } = props
  const [disabled, setDisabled] = useState(false)

  return (
    <Ribbon
      color={"volcano"}
      style={{ left: 0, top: -5 }}
      text={<div>
        <TeamOutlined /> {anchorInfo.onlineCount !== undefined ? new Intl.NumberFormat().format(anchorInfo.onlineCount - 1 >= 0 ? anchorInfo.onlineCount - 1 : 0) : "..."}
      </div>}>
      <Card
        hoverable
        style={{ justifyContent: 'center' }}
        className={anchorInfo.roomInfo.type === "normal" ? "normal" : "private"}
        actions={[
          <Popconfirm
            title={intl.formatMessage({ id: 'familyMonitor.sureRemove' })}
            disabled={disabled}
            cancelText={intl.formatMessage({ id: 'familyMonitor.cancel' })}
            okText={intl.formatMessage({ id: 'familyMonitor.confirm' })}
            onConfirm={() => {
              setDisabled(true)
              anchorInfo.closeRoom()
            }}
          >
            <div>
              <LoginOutlined />
              <div>{intl.formatMessage({ id: 'familyMonitor.remove' })}</div>
            </div>
          </Popconfirm>,
        ]}
      >
        <Avatar
          shape="circle"
          draggable={false}
          size={100}
          src={anchorInfo.cover ? Config.lasHost + anchorInfo.cover : ""} />
        <div className="name">
          <small>{anchorInfo.id} - </small>
          {anchorInfo.name}
        </div>
        <div
          className="stream-name">
          <ApiOutlined style={{ marginRight: 5 }} />
          {anchorInfo.streamInfo ? `${anchorInfo.streamInfo.streamName} - ${anchorInfo.streamInfo.streamId}` : ""}
        </div>
        <div
          className="stream-name">
          <b style={{ marginRight: 5 }}>App :</b>
          {anchorInfo.extraInfo ? `${anchorInfo.extraInfo.appVersion}` : ""}
        </div>
        <div
          className="stream-name">
          <b style={{ marginRight: 5 }}>Device :</b>
          {anchorInfo.extraInfo ? `${anchorInfo.extraInfo.deviceModel}` : ""}
        </div>
        <Button
          onClick={anchorInfo.openMonitorModal}
          className="view-btn" type="primary" shape="round" icon={<VideoCameraOutlined />} size={"middle"}>
          {intl.formatMessage({ id: 'familyMonitor.monitor_live' })}
        </Button>
      </Card>
    </Ribbon>
  )

})

export default injectIntl(AnchorCard)
