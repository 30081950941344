import React from "react";
import { Row, Col, Select, Button, Typography } from 'antd'
import VideoPlayer from '../../../VideoPlayer'
import RQRcode from 'qrcode-react'
import OnAirPanel from './OnAirPanel'
import { injectIntl } from 'react-intl'

function Live(props) {
  const { intl, liveInfo, actions } = props

  return (
    <div style={{ width: '45%', height: '100%' }}>
      <VideoPlayer channel={liveInfo.channel} roomType={liveInfo.roomType} />
      {
        !(liveInfo.channel) ?
          (<div style={{ width: '100%', height: '100%', borderStyle: 'solid', borderWidth: '1px' }}>
            <Row
              justify="center"
              style={{ background: '#D7D7D7', padding: '6px 0', alignItems: 'center' }}
              type="flex"
            >
              <Col style={{ alignItems: "right" }}>{intl.formatMessage({ id: 'liveControl.selectDevice' })}:</Col>
              <Col offset={1}>
                <Select defaultValue="app" style={{ width: 120 }} onChange={actions.streamDevice}>
                  <Select value="app">{intl.formatMessage({ id: 'liveControl.mobile' })}</Select>
                  <Select value="obs">{intl.formatMessage({ id: 'liveControl.PC' })}</Select>
                </Select>
              </Col>
            </Row>
            {(() => {
              if (liveInfo.deviceType === 'app') {
                return (
                  <div align='center' style={{ padding: '20px' }}>
                    <Button
                      onClick={actions.getQrcode}>{intl.formatMessage({ id: 'liveControl.loginQRCode' })}</Button>
                    {
                      liveInfo.qrCode
                      &&
                      <div style={{ padding: '20px' }}>
                        <RQRcode
                          value={liveInfo.qrCode}
                          size={256}
                        />
                        <Typography>{intl.formatMessage({ id: 'liveControl.qrcodeMark' })}</Typography>
                      </div>
                    }
                  </div>
                )
              } else if (liveInfo.deviceType === 'obs') {
                return (
                  <div align='center' style={{ padding: '20px' }}>
                    {
                      liveInfo.push
                      &&
                      <div align='left' style={{ padding: '20px' }}>
                        <OnAirPanel
                          roomType={type => actions.setRoomType(type)}
                          roomLevel={level => actions.setRoomLevel(level)}
                          watchTime={time => actions.setWatchTime(time)}
                          roomFee={fee => actions.setRoomFee(fee)}
                          openOBS={actions.getStreamData}
                        />
                        <br />
                        <Typography level={2}
                          style={{ color: '#F49F34' }}>{intl.formatMessage({ id: 'liveControl.pushUrl' })}：</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{liveInfo.push.url}</Typography>
                        <br />
                        <Typography
                          style={{ color: '#F49F34' }}>{intl.formatMessage({ id: 'liveControl.pushKey' })}：</Typography>
                        <Typography style={{ wordBreak: 'break-all' }}>{liveInfo.push.key}</Typography>
                        <br />
                        <Typography>{intl.formatMessage({ id: 'liveControl.OBSContent' })}</Typography>
                      </div>
                    }
                    <Button loading={liveInfo.connectLoading} type="primary"
                      onClick={actions.startPullStream}>{intl.formatMessage({ id: 'liveControl.onAir' })}</Button>
                  </div>
                )
              }
            })()}
          </div>) : null
      }
    </div>
  )
}

export default injectIntl(Live)