import { i18nObject } from '../helper/helper'

const language = {
  app: {
    mobileLogin: 'Đăng nhập di động',
    logout: 'Đăng xuất',
    qrcodeDesc: 'Quét mã QR bằng ứng dụng xem trực tuyến'
  },
  familyMonitor: {
    anchor_count: 'Số lượng Anchor',
    anchor_live_count: 'Số lượng live',
    anchor_porn_live_count: 'Số lượng live khiêu dâm',
    clientCount: 'Số lượng khách hàng',
    no_anchor: 'Không có Anchor',
    sureRemove: 'Bạn có chắc muốn ngắt kết nối?',
    cancel: 'Hủy',
    confirm: 'Xác nhận',
    remove: 'Ngắt kết nối',
    monitor_live: 'Giám sát trực tiếp',
    sendGift: 'Gửi quà tặng',
    monitor: 'Giám sát',
    enterRoom: 'Vào phòng',
    anchor_live: 'Live',
    anchor_porn_live: 'Live khiêu dâm',
    inputPlaceholder: 'Tin nhắn cá nhân từ người giám sát tới streamer...',
    send: 'Gửi',
    kick_success: 'Thao tác thành công, cập nhật danh sách host',
  },
  userInfo: {
    icon: 'Ảnh đại diện',
    backgroundImage: 'Ảnh nền',
    name: 'Tên',
    name_en: 'Tên tiếng Anh',
    email: 'Email',
    password: 'Mật khẩu',
    birthday: 'Ngày sinh',
    height: 'Chiều cao',
    weight: 'Cân nặng',
    width: 'Sanwei',
    blood: 'Nhóm máu',
    constellation: 'Chòm sao',
    language: 'Ngôn ngữ',
    education: 'Học vấn',
    job: 'Nghề nghiệp',
    interest: 'Sở thích',
    movie: 'Phim yêu thích',
    food: 'Ẩm thực yêu thích',
    actor: 'Nghệ sĩ yêu thích',
    game: 'Trò chơi yêu thích',
    number: 'Số may mắn',
    introduction: 'Giới thiệu',
    modifyPassword: 'Thay đổi mật khẩu',
    modify: 'Sửa đổi'
  },
  routes: {
    info: 'Thông tin',
    dashboard: 'Bảng điều khiển',
    liveControl: 'Điều khiển trực tiếp',
    giftHistory: 'Lịch sử quà tặng',
    liveHistory: 'Lịch sử trực tuyến',
    modelPlayer: 'Người dẫn chương trình',
    RoomOrder: 'Đặt phòng',
    analysis: 'Phân tích trực tiếp',
    userIncome: 'Lịch sử thu nhập',
    familyManager: 'Quản lý gia đình',
    familyIncome: 'Thu nhập gia đình',
    events: 'Sự kiện thể thao',
    SportStatistics: 'Thống kê thể thao',
    monitor_live: 'Giám sát trực tiếp'
  },
  global: {
    update: 'Cập nhật',
    query: 'Tìm kiếm',
    dateRange: 'Khoảng thời gian',
    all: 'Tất cả',
    export: 'Xuất khẩu'
  },
  dateTimePicker: {
    today: 'Hôm nay',
    yesterday: 'Hôm qua',
    l7days: '7 ngày trước',
    l30days: '30 ngày trước',
    tMonth: 'Tháng này',
    lMonth: 'Tháng trước',
    custom: 'Khoảng thời gian tùy chỉnh',
  },
  lasSocket: {
    disconnect: 'Ngắt kết nối',
    connect: 'Kết nối',
    loginFailed: 'Đăng nhập thất bại'
  },
  login: {
    title: 'Đăng nhập Anchor',
    account: 'Tài khoản',
    accountPlaceholder: 'Email',
    password: 'Mật khẩu',
    modelLogin: 'Đăng nhập Anchor',
    adminLogin: 'Đăng nhập Admin',
    familyLogin: 'Đăng nhập Gia đình',
    adminPlaceholder: 'Tài khoản Admin',
  },
  giftHistory: {
    model: 'Tên Anchor',
    fromName: 'Tài khoản người dùng',
    giftInfo: 'Thông tin quà tặng',
    amount: 'Số lượng',
    createdAt: 'Ngày tạo',
    sum: 'Tổng quà tặng'
  },
  liveHistory: {
    createdAt: 'Bắt đầu',
    updatedAt: 'Kết thúc',
    total: 'Tổng',
    type: 'Loại',
    all: 'Tất cả',
    type0: 'Phòng',
    type1: 'Di động'
  },
  liveAnalysis: {
    date: 'Ngày',
    liveCount: 'Số lượt live',
    totalInterval: 'Khoảng',
    watchedCounts: 'Số lượt xem',
    clientCounts: 'Số lượt khách hàng',
    giftSum: 'Tổng quà tặng',
    betCount: 'Số lần cược',
    winOrLose: 'Thắng/Thua',
    eBet: 'Cược E',
    eBetWin: 'Thắng cược E',
    liveBet: 'Cược trực tiếp',
    liveValidBet: 'Cược hợp lệ trực tiếp',
    liveWin: 'Thắng trực tiếp',
    lotteryBet: 'Cược xổ số',
    lotteryWin: 'Chiến thắng xổ số',
    battleLostSum: 'Tổng số tiền mất Battle',
    battleValidBet: 'Cược hợp lệ Battle',
    battleWinSum: 'Thắng Battle',
    fishBet: 'Cược cá',
    fishWin: 'Thắng cá',
    sportBet: 'Cược thể thao',
    sportValidBet: 'Cược hợp lệ thể thao',
    sportWin: 'Thắng thể thao',
    handicapBet: 'Cược Handicap',
    handicapWin: 'Thắng Handicap',
    statisticTime: 'Khoảng thời gian',
    model: 'Tên người mẫu',
    privateFees: 'Phí riêng tư',
    totalGiftSum: 'Tổng số quà tặng',
    totalPrivateFees: 'Tổng phí riêng tư',
  },
  chat: {
    gift: 'đã tặng bạn',
    heart: 'đã tặng trái tim',
    monitor: 'Quản lý tin nhắn',
    model: 'Anchor',
    betting: 'cược',
    win: 'chiến thắng',
    placeholder: 'Nói gì đó...',
    send: 'gửi',
    enter: 'nhập',
    focus: 'Theo dõi anchor',
    manager: 'Quản lý phòng'
  },
  games: {
    series: '{series} lần',
    whichSeries: 'Lần: {series}',
    lottery: 'Xổ số',
    esport: 'E-Sport',
    sport: 'Thể thao',
    littleGame: 'Trò chơi nhỏ',
    comingSoon: 'Sắp ra mắt...',
    gameSelected: 'Đã chọn',
    gameRecommend: 'Gửi',
    gameCancel: 'Hủy',
    menu: 'Danh sách game',
    binding: 'bó buộc',
    bind: 'buộc',
    cancelBind: 'hủy liên kết',
  },
  liveControl: {
    scheduledAt: 'sẽ bắt đầu lúc',
    webStart: 'Trực tuyến',
    webEnd: 'Tắt kết nối',
    mobile: 'Ứng dụng di động',
    PC: 'OBS',
    PCStream: 'Công cụ OBS',
    PCStreaming: 'Công cụ OBS - Trực tuyến',
    pushUrl: 'URL Stream',
    pushKey: 'Khóa Stream',
    OBSContent: 'Lưu ý: Sử dụng công cụ như OBS assistant để cấu hình địa chỉ stream và bắt đầu stream trên assistant, sau đó nhấp vào nút "Bắt đầu" bên dưới',
    loginQRCode: 'Bấm để nhận mã QR đăng nhập',
    onAir: 'OnAir',
    selectDevice: 'Vui lòng chọn thiết bị trực tiếp',
    normal: 'Miễn phí',
    vip: 'VIP',
    private: 'Tính phí',
    onAirType: 'Loại',
    roomLevel: 'Cấp độ',
    roomWatch: 'Thời lượng',
    roomAmount: 'Phí vào phòng',
    min: 'phút',
    unrestricted: 'Không giới hạn',
    qrcodeMark: 'Mã QR có hiệu lực trong 5 phút, vui lòng lấy lại khi hết hạn.'
  },
  RoomOrder: {
    customerName: 'Tài khoản người dùng',
    createdAt: 'Ngày tạo',
    fee: 'Phí',
    minute: 'Phút',
  },
  UserIncome: {
    Date: 'Ngày',
    onairDuration: 'Thời lượng',
    Wage: 'Tiền công',
    giftSplite: 'Phầm trăm quà tặng',
    roomFeeSplite: 'Phần trăm phí phòng',
    giftIncome: 'Thu nhập từ quà tặng',
    roomFeeIncome: 'Thu nhập từ phí phòng',
    onairIncome: 'Thu nhập từ trên tràng',
    totalGiftIncome: 'Tổng thu từ quà tặng',
    totalRoomFeeIncome: 'Tổng thu từ phí phòng',
    totalIncome: 'Tổng thu nhập'
  },
  FamilyIncome: {
    Date: 'Ngày',
    users: 'Số lượng người dẫn chương trình',
    Wage: 'Tiền công theo giờ',
    giftSplite: 'Phần trăm quà tặng',
    roomFeeSplite: 'Phần trăm phí phòng',
    onairDuration: 'Thời lượng trên tràng',
    Income: 'Thu nhập gia đình',
    amount: 'Số tiền thanh toán',
    amount_na: 'Số tiền chưa thanh toán',
    endDate: 'Thời gian thanh toán',
    option: 'Hoạt động',
    memberProfit: 'Lợi nhuận thành viên',
    detail: 'Chi tiết',
    sum: 'Tổng',
  },
  UserDetails: {
    userId: 'ID Anchor',
    familyName: 'Tên gia đình',
    userName: 'Tên Anchor',
    Wage: 'Tiền công theo giờ',
    giftSplite: 'Phần trăm quà tặng',
    roomFeeSplite: 'Phần trăm phí phòng',
    onairDuration: 'Thời lượng trên tràng',
    giftIncome: 'Thu nhập từ quà tặng',
    roomFeeIncome: 'Thu nhập từ phí phòng',
    onairIncome: 'Thu nhập từ trên tràng',
    totalGiftIncome: 'Thu nhập từ quà tặng',
    totalRoomFeeIncome: 'Thu nhập từ phí phòng',
    totalIncome: 'Thu nhập Anchor',

    total: 'Tổng',
  },
  PopModifyPassword: {
    modifyPassword: 'Thay đổi',
    cancel: 'Hủy',
    newPassword: 'Mật khẩu mới',
    chackPassword: 'Xác nhận mật khẩu mới',
    success: 'Thay đổi mật khẩu thành công',

    chackFailure: 'Hai mật khẩu nhập không khớp',
    inputPassword: '6~10 ký tự và ít nhất một ký tự tiếng Anh.'
  },

  LineChart: {
    date: 'Ngày',
    points: 'Điểm',
  },
  FamilyInfo: {
    modelName: 'Tên',
    modelEmail: 'Email',
    hourlyRate: 'Tiền công theo giờ',
    giftAmountRate: 'Phần trăm quà tặng (%)',
    privateRoomFeeRate: 'Phần trăm phí phòng (%)',
    popular: 'Phổ biến',
    onair: 'Trực tuyến',
    isValid: 'Hợp lệ',
    createdAt: 'Ngày tạo',
    option: 'Lựa chọn',

    familyInfo: 'Thông tin',
    familyName: 'Tên',
    bindEmail: 'Email',
    memberCount: 'Số lượng thành viên',
    memberList: 'Danh sách thành viên',
  },
  Sports: {
    myEvents: 'Sự kiện của tôi',
    sportsEvents: 'Danh sách sự kiện',
    date: 'Ngày',
    leagueName: 'Giải đấu',
    eventName: 'Tên sự kiện',
    liveStatus: 'Bắt đầu phát sóng hay không',
    customerCount: 'Số lượng khán giả đặt chỗ',
    action: 'Hoạt động',
    cancelEvent: 'Bạn có chắc muốn hủy sự kiện đã lên lịch?',
    fllowEvent: 'Xác nhận đặt chỗ sự kiện này?',
    ok: 'OK',
    cancel: 'Hủy',
    cancelEventSuccess: 'Hủy lịch thành công',
    fllowEventSuccess: 'Đặt chỗ thành công',
    fllowed: 'được đặt chỗ',
    fllow: 'Đặt chỗ',
    onair: 'Bắt đầu phát sóng',
    over: 'Đã phát sóng',
    never: 'Không phát sóng',
    home: '(Chủ nhà)',
    guest: '(Khách)',
    region: 'Vùng',
    allLeague: 'Tất cả giải đấu',
    allRegion: 'Tất cả vùng',
    fllowedAnchor: 'Số lượng anchor đã đặt lịch',
    fllowEventError: 'Số lượng anchor đặt lịch cho sự kiện này đã đầy',
    fllowFull: 'Đã hết chỗ đặt',
  },
  SportLive: {
    main: '(Chủ nhà)',
    guest: '(Khách)',
    selectEvent: 'Chọn sự kiện trực tiếp',
    seletcEventLimitNotice: '* Chỉ hiển thị các sự kiện đã đặt lịch trong 12 giờ tới',
    liveEvent: 'Sự kiện trực tiếp',
    streamUrl: 'URL phát sóng',
    streamKey: 'Khóa phát sóng',
    tooltip: 'Lưu ý: Sử dụng công cụ như OBS assistant và các công cụ khác, cấu hình địa chỉ push và bắt đầu push trên công cụ, sau đó nhấp vào nút "Bắt đầu phát sóng" bên dưới',
    startLive: 'Bắt đầu phát sóng trực tiếp',
    stopLive: 'Kết thúc phát sóng trực tiếp',
    screenSwitch: 'Hiển thị video',
    alartMessage: "Không nhận được dữ liệu được đẩy bởi công cụ như OBS assistant. Vui lòng thử đẩy lại và bật 'Hiển thị video'.",
    twoHours: '2 giờ',
    oneDay: '1 ngày',
    threeDay: '3 ngày',
    aMonth: '30 ngày',
    infinite: 'vĩnh viễn',
    becomeManager: 'Đã trở thành quản lý phòng',
    cancelledManager: 'Đã hủy quản lý phòng',
    has: 'đã',
    mute: 'Tắt tiếng',
    addManager: 'Đặt làm quản lý phòng',
    delManager: 'Xóa khỏi quản lý phòng',
    manager: 'Quản lý',
    muteList: 'Danh sách tắt tiếng',
    other: 'khác',
    customTitle: 'Vui lòng nhập tiêu đề trực tiếp tùy chỉnh',
    customMessage: 'Vui lòng nhập tên tiêu đề'
  },
  MuteList: {
    name: 'Tên',
    muteTime: 'Thời gian tắt tiếng',
    timeLeft: 'Thời gian còn lại',
    end: 'Đã kết thúc',
    manager: 'Quản lý phòng',
    action: 'Hoạt động',
    has: 'đã',
    disMute: 'Cho phép nói chuyện'
  },
  SportStatistics: {
    liveStartAt: 'Thời gian bắt đầu phát trực tiếp',
    liveOverAt: 'Thời gian kết thúc phát trực tiếp',
    startAt: 'Ngày bắt đầu',
    eventName: 'Tên sự kiện',
    gamePrizeSum: 'Chi phí',
    gameGiftSum: 'Số quà tặng',
    main: '(Chủ nhà)',
    guest: '(Khách)',
  },
  SportUserInfo: {
    setting: 'Cài đặt cơ bản',
    background: 'Cài đặt nền',
    private: 'Cài đặt bảo mật'
  },
  SportPrivateSetting: {
    account: 'Mật khẩu tài khoản'
  },
  SportUserSetting: {
    name: 'Tên',
    email: 'Email',
    introduction: 'Giới thiệu bản thân',
    announcement: 'Thông báo Anchor',
    uploadData: 'Cập nhật thông tin cơ bản',
    uploadAvatar: 'Đổi ảnh đại diện'
  },
  SportCommonTypes: {
    Soccer: 'Bóng đá',
    Basketball: 'Bóng rổ',
    Tennis: 'Quần vợt',
    Volleyball: 'Bóng chuyền',
    Baseball: 'Bóng chày',
    AmericanFootball: 'Bóng bầu dục Mỹ',
    IceHockey: 'Khúc côn cầu',
    ESports: 'Điện tử thể thao',
    Handball: 'Bóng ném',
    Snooker: 'Bi-a giao hữu',
    Badminton: 'Cầu lông',
    TableTennis: 'Bóng bàn',
    Boxing: 'Quyền Anh',
    WaterPolo: 'Bóng nước',
    Floorball: 'Bóng đá sân cỏ',
    Squash: 'Quần vợt Wall',
    Cricket: 'Cricket',
    RugbyUnion: 'Bóng rổ Liên minh Anh',
    RugbyLeague: 'Bóng rổ Liên đoàn Rugby',
    Darts: 'Phi tiêu',
    Futsal: 'Bóng đá trong nhà',
    BeachVolleyball: 'Bóng chuyền bãi biển',

    Others: 'Khác'
  }
}

export default i18nObject(language)