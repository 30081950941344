import io from 'socket.io-client'
import { sportHost } from '../config/env'
import i18n from '../locales'
const MAX_MSG_LENGTH = 200

class Socket {
  constructor(app, user, imUrl) {
    this.user = user
    this.app = app
    this.isConnected = false
    this.connectRetryCount = 0
  
    this.init(user.merchant, imUrl)
  }

  init(merchant, imUrl) {
    // this.io = io.connect(`http://www.sit-huanqiu.com/Anchor`, {
    //   path: '/ics-sport/socket.io',
    //   transports: ['websocket']
    // })
    if (imUrl) {
      this.io = io.connect(`${imUrl}/Anchor`, {
        path: '/ics/socket.io',
        transports: ['websocket']
      })
    } else if (sportHost.indexOf('http') !== -1) {
      this.io = io.connect(`${sportHost}/Anchor`, {
        path: '/socket.io',
        transports: ['websocket']
      })
    } else {
      this.io = io.connect(`${window.location.protocol}//${window.location.host}/Anchor`, {
        path: sportHost + '/socket.io',
        transports: ['websocket']
      })
    }
    this.mount()
  }

  connect() {
    if (this.io.disconnected) this.io.connect()
  }

  disconnect() {
    if (this.io.connected) {
      //this.io.removeAllListeners()
      this.io.disconnect()
    }
  }

  mount() {
    this.unMount()
    this.io.on('connect', () => {
      this.io.emit('chat/login', { token: this.user.accessToken, modelId: this.user.id, merchant: this.user.merchant })
    })
    // this.io.on('disconnect', () => {
    //   const msg = {
    //     messageType: 9,
    //     msg: i18n[this.app.state.language]['lasSocket.disconnect'],
    //   }
    //   this.addMessage(msg)
    // })
    this.io.on('reconnecting', () => {
      if (this.isConnected) this.connectRetryCount += 1
      if (this.connectRetryCount > 3) {
        this.app.setState({ isReconnectVisible: true })
      }
    })

    this.io.on('chat/logout', data => {
      this.app.setState({ lasIsLogin: false })
      this.io.disconnect()
    })
    this.io.on('chat/login/success', () => {
      if (!this.isConnected) {
        const msg = {
          messageType: 8,
          msg: i18n[this.app.state.language]['lasSocket.connect'],
        }
        this.addMessage(msg)
        this.app.setState({ lasIsLogin: true })
      }

      this.isConnected = true
      this.connectRetryCount = 0
      this.app.setState({ isReconnectVisible: false })
    })
    this.io.on('chat/login/failed', data => {
      const msg = {
        messageType: 9,
        msg: i18n[this.app.state.language]['lasSocket.loginFailed'],
      }
      this.addMessage(msg)
    })
    this.io.on('chat/message/new', data => {
      this.addMessage(data)
    })
    this.io.on('chat/message/monitor', data => {
      const msg = {
        messageType: 3,
        msg: data.msg
      }
      this.addMessage(msg)
    })

    this.io.on('chat/message/gift', data => {
      const msg = {
        messageType: 1,
        name: data.nickname,
        gift: data.gift,
        qty: data.qty,
        points: data.points,
      }
      this.addMessage(msg)
    })

    this.io.on('chat/message/heart', (data) => {
      if (!data.heartSent) {
        const msg = {
          messageType: 2,
          name: data.nickname
        }
        this.addMessage(msg, true)
      }

      this.app.setState({ lasHeart: this.app.state.lasHeart + 1 })
    })

    this.io.on('chat/room/enter', (data) => {
      const msg = {
        messageType: 4,
        name: data.nickname
      }
      this.addMessage(msg)
    })

    this.io.on('live/stop', () => {
      this.app.stopLive(false)
    })

    // this.io.on('customer/focus', (data) => {
    //   data['messageType'] = 'focus'
    //   this.addMessage(data)
    // })

    this.io.on('public/user/betting', (data) => {
      const msg = {
        messageType: 6,
        name: data.nickname,
        gameCode: data.betting.gameCode,
        totalBettingAmount: data.betting.totalBettingAmount
      }
      this.addMessage(msg)
    })

    this.io.on('public/user/winner', (data) => {
      const msg = {
        messageType: 7,
        name: data.nickname,
        gameCode: data.gameCode,
        winAmount: data.winAmount,
        numero: data.numero
      }
      this.addMessage(msg)
    })
  }

  unMount() {
    this.io.removeAllListeners()
  }

  sendMessage(msg) {
    this.io.emit('chat/message/new', { message: msg })
  }

  sendGameRecommend(object) {
    this.io.emit('model/game/recommend', object)
  }

  addMessage(msg) {
    const lasMsg = this.app.state.lasMsg.slice()
    lasMsg.push(msg)
    if (lasMsg.length > MAX_MSG_LENGTH) {
      lasMsg.splice(0, lasMsg.length - MAX_MSG_LENGTH)
    }

    this.app.setState({ lasMsg })
  }
}

export default Socket