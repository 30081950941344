import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import Heart from '../chat/Heart'
import { lasHost } from '../../config/env'

const scrollToBottom = () => {
  const ele = document.querySelector('.message-block')
  ele.scrollTop = ele.scrollHeight
}

class LasChat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      msg: [],
      heart: props.heart,
      show: false,
    }
  }

  componentDidMount() {
    scrollToBottom()
  }

  shouldComponentUpdate(nextProps) {
    if (this.state.msg[this.state.msg.length - 1] !== nextProps.msg[nextProps.msg.length - 1]) {
      this.setState({ msg: nextProps.msg.slice() })
      return true
    }

    if (this.state.heart !== nextProps.heart) {
      this.setState({ heart: this.state.heart + 1, show: true })
      return true
    }

    return false
  }

  componentDidUpdate() {
    scrollToBottom()
  }

  onAnimationEnd = () => {
    this.setState({ show: false })
  }

  gmaeName = (games, gamecode) => {
    const game = games.filter((g) => g.gameCode === gamecode)
    return game ? game[0].name : gamecode
  }

  render() {
    const { intl, games, onOpenMenu } = this.props

    return (
      <div className="message-block">
        <Heart show={this.state.show} onAnimationEnd={this.onAnimationEnd} />
        {
          this.state.msg.map((data, index) => {
            switch (data.type) {
              case 0:
                return (
                  <div className="row" key={index}>
                    <div className={"content color-" + data.color}>
                      <span className="name" onClick={
                        () => onOpenMenu(data.name, data.customerId)
                      } style={{ cursor: 'pointer' }}>{data.name}</span>
                      <span className="msg">{data.msg}</span>
                    </div>
                  </div>
                )

              case 1:
                return (
                  <div className="row" key={index}>
                    <div className={"content color-" + data.color}>
                      <span className="name" onClick={
                        () => onOpenMenu(data.name, data.customerId)
                      } style={{ cursor: 'pointer' }}>{data.name}</span>
                      <span className="msg">
                        {intl.formatMessage({ id: 'chat.gift' }, { series: this.state.series })} {data.gift.name} <img src={lasHost + data.gift.icon} alt="gift" /> X {data.qty} ({data.points})
                      </span>
                    </div>
                  </div>
                )

              case 2:
                return (
                  <div className="row" key={index}>
                    <div className={"content color-" + data.color}>
                      <span className="name" onClick={
                        () => onOpenMenu(data.name, data.customerId)
                      } style={{ cursor: 'pointer' }}>{data.name}</span>
                      <span className="msg">{intl.formatMessage({ id: 'chat.heart' })}</span>
                    </div>
                  </div>
                )

              case 3:
                return (
                  <div className="row center" key={index}>
                    <div className="content monitor">
                      <span className="name">{intl.formatMessage({ id: 'chat.monitor' })}</span>
                      <span className="msg">{data.msg}</span>
                    </div>
                  </div>
                )

              case 4:
                return (
                  <div className="row center" key={index}>
                    <div className="content">
                      <span className="name" onClick={
                        () => onOpenMenu(data.name, data.customerId)
                      } style={{ cursor: 'pointer' }}>{data.name}</span>
                      <span className="msg">{intl.formatMessage({ id: 'chat.enter' })}</span>
                    </div>
                  </div>
                )

              case 5:
                return (
                  <div className="row" key={index}>
                    <div className="content model">
                      <span className="name">{intl.formatMessage({ id: 'chat.model' })}</span>
                      <span className="msg">{data.msg}</span>
                    </div>
                  </div>
                )
              case 6:
                return (
                  <div className="row" key={index}>
                    <div className="content model">
                      <span className="name" onClick={
                        () => onOpenMenu(data.name, data.customerId)
                      } style={{ cursor: 'pointer' }}>{data.name}</span>
                      <span className="msg">{this.gmaeName(games, data.gameCode)} {intl.formatMessage({ id: 'chat.betting' })} {data.totalBettingAmount}</span>
                    </div>
                  </div>
                )
              case 7:
                return (
                  <div className="row" key={index}>
                    <div className="content model">
                      <span className="name" onClick={
                        () => onOpenMenu(data.name, data.customerId)
                      } style={{ cursor: 'pointer' }}>{data.name}</span>
                      <span className="msg">[{data.numero}] {this.gmaeName(games, data.gameCode)} {intl.formatMessage({ id: 'chat.win' })} {data.winAmount}</span>
                    </div>
                  </div>
                )
              case 8:

              case 9:
                return (
                  <div className="row center" key={index}>
                    <div className={`content ${data.type === 8 ? 'success' : 'error'}`}>
                      <span className="msg">{data.msg}</span>
                    </div>
                  </div>
                )
            }
          })
        }
      </div>
    )
  }
}

export default injectIntl(LasChat)
