import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Layout, } from 'antd'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  AndroidFilled,
  AppleFilled
} from '@ant-design/icons'
import { injectIntl } from 'react-intl'
import QRCode from './modals/Qrcode'
import qrcode from '../api/qrcode'
import LoginAPI from '../api/user'


class AppHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      QRCode: {
        url: '',
        type: ''
      },
      modalShow: false
    }

  }

  android = () => {
    this.featchAppUrl('android')
    this.setState({ modalShow: true })
  }

  iOS = () => {
    this.featchAppUrl('ios')
    this.setState({ modalShow: true })
  }

  featchAppUrl = async (type) => {
    const result = await qrcode.applist()
    if (result.status === 200) {

      if (!result.data.ios) {
        return
      }

      if (type === 'ios') {
        this.setState({
          QRCode: {
            url: result.data.ios.url,
            type: 'iOS'
          }
        })
      } else {
        this.setState({
          QRCode: {
            url: result.data.android.url,
            type: 'Android'
          }
        })
      }
    }
  }

  openModal = async () => {
    const result = await LoginAPI.generateQRCode()

    if (result.status === 200) {
      this.setState({ modalShow: true, QRCode: result.data.qrcode })
    } else {
      console.error(result)
    }
  }

  closeModal = () => {
    this.setState({ modalShow: false })
  }

  render() {
    const { collapsed, isLogin, onLogout } = this.props
    const { Header } = Layout
    const ToggleIcon = () => collapsed
      ? <MenuUnfoldOutlined style={{ color: 'white' }} className="menu-toggle" onClick={this.props.onMenuToggle} />
      : <MenuFoldOutlined style={{ color: 'white' }} className="menu-toggle" onClick={this.props.onMenuToggle} />

    return isLogin ? (
      <Header style={{
        position: 'relative',
        padding: "0 15px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
      }}>
        <ToggleIcon />
        {
          process.env.REACT_APP_BUILD_TYPE !== 'SPORT'
          &&
          <AndroidFilled style={{ fontSize: '20px', color: 'white', marginRight: '20px' }} onClick={this.android} />
        }
        {
          process.env.REACT_APP_BUILD_TYPE !== 'SPORT'
          &&
          <AppleFilled style={{ fontSize: '20px', color: 'white', marginRight: '20px' }} onClick={this.iOS} />
        }
        <LogoutOutlined style={{ fontSize: '20px', color: 'white' }} onClick={onLogout} />
        <QRCode value={this.state.QRCode} visible={this.state.modalShow} onCancel={this.closeModal} />
      </Header>
    ) : null
  }
}

const mapStateToProps = store => (
  { collapsed: store.collapsed, isLogin: store.isLogin }
)

export default connect(mapStateToProps)(injectIntl(AppHeader))
