import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Heart from './Heart';
import { icsHost } from '../../config/env';
import { Row, Avatar, Space, Col } from 'antd';
import ReconnectAlert from './ReconnectAlert'

const scrollToBottom = () => {
  const ele = document.querySelector('.message-block');
  ele.scrollTop = ele.scrollHeight;
};
class LasChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: [],
      heart: props.heart,
      show: false,
      isVisible: props.isReconnectVisible,
    };
  }

  componentDidMount() {
    scrollToBottom();
  }

  shouldComponentUpdate(nextProps) {
    if (this.state.msg[this.state.msg.length - 1] !== nextProps.msg[nextProps.msg.length - 1]) {
      this.setState({ msg: nextProps.msg.slice() });
      return true;
    }

    if (this.state.heart !== nextProps.heart) {
      this.setState({ heart: this.state.heart + 1, show: true });
      return true;
    }

    if (this.state.isVisible !== nextProps.isReconnectVisible) {
      this.setState({ isVisible: nextProps.isReconnectVisible });
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    scrollToBottom();
  }

  onAnimationEnd = () => {
    this.setState({ show: false });
  };

  gmaeName = (games, gamecode) => {
    const game = games.filter((g) => g.gameCode === gamecode);
    return game ? game[0].name : gamecode;
  };

  tranColor = (name = '') => {
    var str = '';
    for (var i = 0; i < name.length; i++) {
      str += parseInt(name[i].charCodeAt(0), 10).toString(16);
    }
    return '#' + str.slice(1, 4);
  };

  messageType = (data) => {
    const { intl, onClickItem } = this.props;

    switch (data.messageType) {
      case 'message':
        switch (data.role) {
          case 'monitor':
            return (
              <div className={"content color-" + data.color}>
                <span className="content monitor name">{this.props.intl.formatMessage({ id: 'chat.monitor' })}</span>
                <span className="msg">{data.text}</span>
              </div>
            );
          case 'customer':
            return (
              <div className={"content color-" + data.color}>
                <span className="name" onClick={() => onClickItem(data)} style={{ cursor: 'pointer' }}>{data.nickname}</span>
                <span className="msg">{data.text}</span>
              </div>
            );
        }
      case 'gift':
        return (
          <div className={"content color-" + data.color}>
            <span className="name" onClick={() => onClickItem(data)} style={{ cursor: 'pointer' }}>{data.nickname}</span>
            <Space className="msg">
              {intl.formatMessage({ id: 'chat.gift' }, { series: this.state.series })}
              {data.giftName}
              <img src={icsHost + data.gif} alt="gift" />
                  X 1
              </Space>
          </div>
        );
      case 'focus':
        return (
          <div className={"content color-" + data.color}>
            <span className="name" onClick={() => onClickItem(data)} style={{ cursor: 'pointer' }}>{data.nickname}</span>
            <span className="msg">{this.props.intl.formatMessage({ id: 'chat.focus' })}</span>
          </div>
        );
      case 'join':
        if (data.name === 'Monitor') {
          // return (
          //   <div className={"content color-" + data.color}>
          //     <span className="content monitor name">{this.props.intl.formatMessage({ id: 'chat.monitor' })}</span>
          //     <span className="msg">{intl.formatMessage({ id: 'chat.enter' })}</span>
          //   </div>
          // );
        } else {
          return (
            <div className={"content color-" + data.color}>
              <span className="name" onClick={() => onClickItem(data)} style={{ cursor: 'pointer' }}>{data.nickname}</span>
              <span className="msg">{intl.formatMessage({ id: 'chat.enter' })}</span>
            </div>
          );
        }
    }
  };

  render() {
    const { manager } = this.props;
    return (
      <div className="message-block">
        <Heart show={this.state.show} onAnimationEnd={this.onAnimationEnd} />
        <ReconnectAlert isVisible={this.state.isVisible} />
        {this.state.msg.map((data, index) => {
          {
            switch (data.messageType) {
              case 8: //連線成功or失敗

              case 9: //連線成功or失敗
                return (
                  <div className="row center" key={index}>
                    <div className={`content ${data.messageType === 8 ? 'success' : 'error'}`}>
                      <span className="msg">{data.msg}</span>
                    </div>
                  </div>
                );
              default: //訊息都帶頭像
                return (
                  <Row wrap={false} align='top'>
                    <Space>
                      {
                        data.name === 'Monitor' || data.messageType === 'focus' ?
                          null
                          :
                          <>
                            <Avatar size='small' style={{ backgroundColor: this.tranColor(data.nickname) }}>{data.nickname.slice(0, 2)}</Avatar>
                            {manager.filter((c) => c.customerId === data.customerId).length > 0 ?
                              <div className="manager">{this.props.intl.formatMessage({ id: 'chat.manager' })}</div>
                              :
                              null}
                            <div className="rank">Lv.{data.rank}</div>
                          </>
                      }
                    </Space>
                    <Col span={20}>
                      {this.messageType(data)}
                    </Col>
                  </Row>
                );
            }
          }
        })}
      </div>
    );
  }
}
export default injectIntl(LasChat);
