import Chart from 'chart.js'
import React, { Component } from 'react'

class ChartComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    const { intl } = this.props
    this.chart = null
    this.config = {
      type: 'line',
      data: {
        datasets: [{
          label: props.label,
          backgroundColor: 'rgba(255, 99, 132, .5)', 
          borderColor: 'rgba(255, 99, 132, 1)',
          data: props.data,
          fill: false
        }]
      },
      options: {
        responsive: true,
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MM/DD'
              }
            },
            scaleLabel: {
              display: true,
              labelString: intl.formatMessage({ id: 'LineChart.date' }),
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            scaleLabel: {
              display: true,
              labelString: intl.formatMessage({ id: 'LineChart.points' })
            }
          }]
        }
      }
    }
  }

  setCanvasRef = element => {
    this.canvasRef = element
  }

  componentDidUpdate() {
    this.chart.update()
  }
 
  componentDidMount() {
    const ctx = this.canvasRef.getContext('2d')
    Chart.defaults.global.defaultFontSize = 14
    this.chart = new Chart(ctx, this.config)
  }

  componentWillUnmount() {
    if (this.chart) this.chart.destroy()
  }

  render() {
    this.config.data.datasets[0].data = this.props.data

    return (
      <div>
        <canvas ref={this.setCanvasRef} />
      </div>
    )
  }
}

export default ChartComponent
