import axios from 'axios';
import { lasHost, sportHost } from '../config/env'
import { getUserInfo } from '../helper/helper'

const API = axios.create({
  baseURL: process.env.REACT_APP_BUILD_TYPE !== 'SPORT' ? lasHost : sportHost
})

export const setRequestHeader = () => {
  API.defaults.headers.common['x-access-token'] = getUserInfo().accessToken
  const lang = window.sessionStorage.getItem('language')
  if(lang) API.defaults.headers.common['language'] = getLanguage(lang)

}

const getLanguage = (str) => {
  switch(str) {
    case 'zh-CN':
    case 'zh-TW':
      return "CN"
    default:
      return str.toUpperCase()
  }
} 

export default API