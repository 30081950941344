import API from './index'
import { getUserInfo } from '../helper/helper'

const path = 'giftOrder'

export default {
  async giftList (q) {
    let query = ''
    if (q && q.from) {
      query += `&from=${q.from}`
    }
    if (q && q.to) {
      query += `&to=${q.to}`
    }

    try {
      const response = await API.get(`/${path}/model?limit=10000${query}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async giftSum (q) {
    let query = ''
    if (q && q.from) {
      query += `&from=${q.from}`
    }
    if (q && q.to) {
      query += `&to=${q.to}`
    }

    try {
      const response = await API.get(`/${path}/model/sum?${query}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}
