import React, { Component } from 'react'

const style = {
  position: 'absolute',
  left: '5%',
  bottom: 10,
  color: 'red',
  background: '#333',
  width: '90%',
  padding: '4px',
  borderRadius: '25px',
  textAlign: 'center',
}

class ReconnectAlert extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { isVisible } = this.props

    if (!isVisible) return null

    return (
      <div style={style}>
        网络不给力，正在努力重接中.....
      </div>
    )
  }
}

export default ReconnectAlert
