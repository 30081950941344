export function i18nObject(lang) {
  const result = {}
  Object.keys(lang).forEach((v1) => {
    if (typeof lang[v1] === 'object') {
      Object.keys(lang[v1]).forEach((v2) => {
        result[`${v1}.${v2}`] = lang[v1][v2]
      })
    } else {
      result[`${v1}`] = lang[v1]
    }
  })

  return result
}

export function getLanguage() {
  const lang = window.sessionStorage.getItem('language') || navigator.language || navigator.userLanguage

  if ((/^zh-tw/i).test(lang) || (/^zh-hant-tw/i).test(lang)) {
    return 'zh-TW'
  } else if ((/^zh-cn/i).test(lang)) {
    return 'zh-CN'
  } else if ((/^en/i).test(lang)) {
    return 'en'
  } else if ((/^th/i).test(lang)) {
    return 'th'
  } else if ((/^vi/i).test(lang)) {
    return 'vi'
  }
}

export function storeUserInfo(user) {
  if (user && Object.keys(user).length) {
    window.sessionStorage.setItem('user', JSON.stringify(user))
    window.sessionStorage.setItem('modelToken', user.accessToken)
  }
}

export function getUserInfo() {
  return {
    user: JSON.parse(window.sessionStorage.getItem('user')),
    accessToken: window.sessionStorage.getItem('modelToken')
  }
}

export function killAllStorage() {
  window.sessionStorage.removeItem('user')
  window.sessionStorage.removeItem('modelToken')
}

export function waitForGlobal(name, timeout = 300) {
  return new Promise((resolve, reject) => {
    let waited = 0

    function wait(interval) {
      setTimeout(() => {
        waited += interval
        if (window[name] !== undefined) {
          return resolve()
        }
        if (waited >= timeout * 1000) {
          return reject()
        }
        wait(interval)
      }, interval)
    }

    wait(timeout)
  })
}
