import React, { useRef, useEffect } from 'react'
import flvJs from "flv.js"
import FlvPlayerContent from "./FlvPlayer"

const FlvPlayer = props => {
  const { streamUrl, roomType, changeChannelTypeToHls } = props

  return (
    <FlvPlayerContent
      streamUrl={streamUrl}
      changeChannelTypeToHls={changeChannelTypeToHls}
      flvJs={roomType !== "normal" ? window.flvjs : flvJs}
    />
  )
}

export default FlvPlayer
