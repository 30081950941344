import React from 'react';
import './index.scss';

const Ribbon= (props) => {
  const { children, text, color, style = {} } = props
  if (!text) return <React.Fragment>{children}</React.Fragment>

  return (
    <div className="ant-ribbon-wrapper">
      {children}
      <div
        style={style}
        className={`ant-ribbon ant-ribbon-placement-start ${color ? `ant-ribbon-color-${color}` : "ant-ribbon-color-green"}`}>
        <span className="ant-ribbon-text">{text}</span>
        <div className="ant-ribbon-corner"/>
      </div>
    </div>
  )
}

export default Ribbon
