import React, { Component } from 'react'
import RangePicker from '../../forms/DateTimeRangePicker'
import PrefixLabel from '../../forms/PrefixLabel'
import { Row, Col, Button, Table, Typography } from 'antd'
import { SyncOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl'
import moment from 'moment'

import FamilyProfitAPI from '../../../api/familyProfit'

class UserIncome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            pagination: { current: 1 },
            from: moment().set({ hour: 0, minute: 0, second: 0 }),
            to: moment().set({ hour: 23, minute: 59, second: 59 }),
            results: [],
            resultSum: {},
        }

        this.columns = [
            {
                title: props.intl.formatMessage({ id: 'UserIncome.Date' }),
                dataIndex: 'date'
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.onairDuration' }),
                dataIndex: 'intervalSum',
                render: time => this.dateToHMS(time)
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.Wage' }),
                dataIndex: 'hourlyRate',
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.giftSplite' }),
                dataIndex: 'giftAmountRate',
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.roomFeeSplite' }),
                dataIndex: 'privateRoomFeeRate',
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.giftIncome' }),
                dataIndex: 'giftSum',
                render: number => number.toLocaleString(),
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.roomFeeIncome' }),
                dataIndex: 'privateFeeSum',
                render: number => number.toLocaleString(),
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.onairIncome' }),
                dataIndex: 'intervalProfit',
                render: number => number.toLocaleString(),
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.totalGiftIncome' }),
                dataIndex: 'giftProfit',
                render: number => number.toLocaleString(),
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.totalRoomFeeIncome' }),
                dataIndex: 'privateFeeProfit',
                render: number => number.toLocaleString(),
            },
            {
                title: props.intl.formatMessage({ id: 'UserIncome.totalIncome' }),
                dataIndex: 'profitSum',
                render: number => number.toLocaleString(),
            },
        ]
    }

    dateToHMS(time) {
        let H = Math.floor(time / 3600), M = Math.floor((time % 3600) / 60), S = Math.floor(time % 60)

        return `${H > 0 ? H + 'h' : ''} ${M > 0 ? ("0" + M).slice(-2) + 'm' : ''} ${S > 0 ? ("0" + S).slice(-2) + 's' : '0s'}`
    }

    onRangePickerInit = (moment) => {
        this.setState({ from: moment.startDate, to: moment.endDate })
    }

    onRangePickerChanged = (moment) => {
        const { pagination } = this.state
        this.setState({ from: moment.startDate, to: moment.endDate })
        this.query(moment.startDate.toISOString(), moment.endDate.toISOString(), pagination.current)
    }

    onSearchClick = () => {
        const { from, to, pagination } = this.state
        this.query(from.toISOString(), to.toISOString(), pagination.current)
    }

    handleTableChange = (pagination, filters, sorter) => {
        const { from, to } = this.state
        this.setState({ pagination, })
        this.query(from.toISOString(), to.toISOString(), pagination.current)
    }

    query = async (from, to, page) => {
        this.setState({ loading: true })
        const ret = await FamilyProfitAPI.list({ from, to, page })
        if (ret.status === 200) {
            const pagination = { ...this.state.pagination }
            pagination.total = ret.data.total
            const resultSum = {...ret.data}
            delete resultSum.value
            this.setState({
                results: ret.data.value,
                resultSum,
                pagination,
                loading: false,
            })
        }
    }

    summary = (pageData) => {
        if (pageData.length === 0) return

        const {
            giftSum, privateFeeSum, intervalProfit,
            giftProfit, privateFeeProfit, profitSum,
        } = this.state.resultSum
        return (
            <Table.Summary.Row>
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell />
                <Table.Summary.Cell>
                    <div style={{ textAlign: 'right' }}>{this.props.intl.formatMessage({ id: 'FamilyIncome.sum' })}:</div> 
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Typography>{giftSum.toLocaleString()}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Typography>{privateFeeSum.toLocaleString()}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Typography>{intervalProfit.toLocaleString()}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Typography>{giftProfit.toLocaleString()}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Typography>{privateFeeProfit.toLocaleString()}</Typography>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                    <Typography>{profitSum.toLocaleString()}</Typography>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        )
    }

    componentWillMount() {
        this.onSearchClick()
    }

    render() {
        const { intl } = this.props

        return (
            <div>
                <Row style={{ marginBottom: '20px' }}>
                    <Col span={4}>
                        <PrefixLabel
                            text={intl.formatMessage({ id: 'global.dateRange' })}
                        >
                            <RangePicker
                                style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onInit={this.onRangePickerInit}
                                onChange={this.onRangePickerChanged}
                            />
                        </PrefixLabel>
                    </Col>
                    <Col span={4} offset={16}>
                        <Button type="primary" icon={<SyncOutlined />} loading={this.state.loading}
                            onClick={this.onSearchClick}
                            style={{ float: 'right' }}
                        >
                            {intl.formatMessage({ id: 'global.update' })}
                        </Button>
                    </Col>
                </Row>
                <Table
                    rowKey={record => record.id}
                    columns={this.columns}
                    dataSource={this.state.results}
                    pagination={this.state.pagination}
                    onChange={this.handleTableChange}
                    scroll={{ x: 1500 }}
                    bordered
                    summary={this.summary}
                />
            </div>
        )
    }
}

export default injectIntl(UserIncome)
