import React, { Component } from 'react'
import { connect } from 'react-redux'
import RangePicker from '../forms/DateTimeRangePicker'
import PrefixLabel from '../forms/PrefixLabel'
import { Row, Col, Select, Input, Button, Table } from 'antd'
import { injectIntl } from 'react-intl'

import LiveAPI from '../../api/live'
import FamilyLiveAPI from '../../api/Family/live'
import moment from 'moment'
import { SyncOutlined } from '@ant-design/icons';

class LiveHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            from: null,
            to: null,
            name: null,
            results: [],
            pagination: { current: 1 },
            switchTable: false,
            scrollTable: { x: 'calc(700px + 50%)' },
            columns: [],
            totalGiftSum: 0,
            totalPrivateFees: 0,
        }

        const { userInfo } = props

        if (userInfo.isAdmin) {
            this.state.columns = [
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.model' }),
                    dataIndex: 'modelName'
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.liveCount' }),
                    align: 'center',
                    dataIndex: 'liveCounts',
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.totalInterval' }),
                    align: 'center',
                    dataIndex: 'totalInterval',
                    sorter: (a, b) => a.totalInterval - b.totalInterval,
                    render: time => this.dateToHMS(time),
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.watchedCounts' }),
                    align: 'center',
                    dataIndex: 'watchedCounts',
                    sorter: (a, b) => a.watchedCounts - b.watchedCounts,
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.clientCounts' }),
                    align: 'center',
                    dataIndex: 'clientCounts',
                    sorter: (a, b) => a.clientCounts - b.clientCounts,
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.giftSum' }),
                    align: 'center',
                    dataIndex: 'giftSum',
                    sorter: (a, b) => a.giftSum - b.giftSum,
                    render: v => v.toFixed(1)
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.privateFees' }),
                    align: 'center',
                    dataIndex: 'totalPrivateFees',
                    sorter: (a, b) => a.totalPrivateFees - b.totalPrivateFees,
                },
            ]
        } else {
            this.state.columns = [
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.date' }),
                    align: 'center',
                    dataIndex: 'liveDate',
                    render: date => moment(date).local().format('YYYY/MM/DD'),
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.liveCount' }),
                    align: 'center',
                    dataIndex: 'liveCounts',
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.totalInterval' }),
                    align: 'center',
                    dataIndex: 'totalInterval',
                    sorter: (a, b) => a.totalInterval - b.totalInterval,
                    render: time => this.dateToHMS(time),
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.watchedCounts' }),
                    align: 'center',
                    dataIndex: 'watchedCounts',
                    sorter: (a, b) => a.watchedCounts - b.watchedCounts,
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.clientCounts' }),
                    align: 'center',
                    dataIndex: 'clientCounts',
                    sorter: (a, b) => a.clientCounts - b.clientCounts,
                },
                {
                    title: props.intl.formatMessage({ id: 'liveAnalysis.giftSum' }),
                    align: 'center',
                    dataIndex: 'giftSum',
                    sorter: (a, b) => a.giftSum - b.giftSum,
                    render: v => v.toFixed(1)
                },
                // {
                //     title: props.intl.formatMessage({ id: 'liveAnalysis.betCount' }),
                //     align: 'center',
                //     dataIndex: 'betMemberCount',
                //     sorter: (a, b) => a - b,
                // },
                // {
                //     title: props.intl.formatMessage({ id: 'liveAnalysis.lotteryBet' }),
                //     align: 'center',
                //     dataIndex: 'elottBet',
                //     sorter: (a, b) => a - b,
                // },
                // {
                //     title: () => {
                //         return (<div>
                //             {props.intl.formatMessage({ id: 'liveAnalysis.winOrLose' })}&nbsp;&nbsp;
                //             <Button type="primary" size="small" icon={this.state.switchTable ? "left-circle" : "right-circle"}
                //                 onClick={() => this.openTable()}
                //                 style={{ float: 'center' }}
                //             >
                //             </Button>
                //         </div>)
                //     },
                //     align: 'center',
                //     dataIndex: '',
                // }
            ]
        }
    }

    openTable = () => {

        if (this.state.switchTable) {
            let table = this.state.columns
            table.splice(8, 17)
            this.setState({ switchTable: false, columns: table, scrollTable: { x: 'calc(700px + 50%)' } })
            return
        }
        const Columns = [
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.eBet' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.eBetWin' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.liveBet' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.liveValidBet' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.liveWin' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.lotteryWin' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.battleLostSum' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.battleValidBet' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.battleWinSum' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.fishBet' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.fishWin' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.sportBet' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.sportValidBet' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.sportWin' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.handicapBet' }),
                align: 'center',
                dataIndex: 'total',

            },
            {
                title: this.props.intl.formatMessage({ id: 'liveAnalysis.handicapWin' }),
                align: 'center',
                dataIndex: 'total',

            }
        ]

        this.setState({ switchTable: true, columns: this.state.columns.concat(Columns), scrollTable: { x: 'calc(2500px + 50%)' } })
    }

    dateToHMS(time) {
        let H = Math.floor(time / 3600), M = Math.floor((time % 3600) / 60), S = Math.floor(time % 60)

        return `${H > 0 ? H + 'h' : ''} ${M > 0 ? ("0" + M).slice(-2) + 'm' : ''} ${S > 0 ? ("0" + S).slice(-2) + 's' : '0s'}`
    }

    onRangePickerInit = (moment) => {
        this.setState({ from: moment.startDate, to: moment.endDate })
    }

    onRangePickerChanged = (moments) => {
        const from = moment(moments.startDate).set({ hour: 0, minute: 0, second: 0 })
        const to = moment(moments.endDate).set({ hour: 23, minute: 59, second: 59 })
        this.setState({ from, to })

        this.query(from.toISOString(), to.toISOString(), this.state.type)
    }

    onSearchClick = () => {
        this.query(this.state.from.toISOString(), this.state.to.toISOString())
    }

    onNameChange = (event) => {
        this.setState({ name: event.target.value })
    }

    query = async (from, to, page) => {
        this.setState({ loading: true })
        const { userInfo } = this.props
        let ret = {}
        if (userInfo.isAdmin) {
            const { name } = this.state
            ret = await FamilyLiveAPI.liveSum({ from, to, name, page })

            if (ret.status === 200) {
                const pagination = { ...this.state.pagination }
                pagination.total = ret.data.total
                this.setState({
                    results: ret.data.value,
                    totalGiftSum: ret.data.totalGiftSum,
                    totalPrivateFees: ret.data.totalPrivateFees,
                    pagination,
                    loading: false,
                })
            }
        } else {
            ret = await LiveAPI.analysisList({ from, to })

            if (ret.status === 200) {

                const Data = ret.data.sort(function (a, b) {
                    return new Date(b.liveDate) - new Date(a.liveDate)
                })

                this.setState({ results: Data, loading: false })
            }
        }
    }

    render() {
        const { intl, userInfo } = this.props
        const { totalGiftSum, totalPrivateFees } = this.state

        const TableFooter = () => {
            return (
                <div
                    style={{ textAlign: "right" }}
                >
                    {intl.formatMessage({ id: 'liveAnalysis.totalGiftSum' })} : {totalGiftSum} / {intl.formatMessage({ id: 'liveAnalysis.totalPrivateFees' })} : {totalPrivateFees}
                </div>
            )
        }

        return (
            <div>
                <Row gutter={4} style={{ marginBottom: '20px' }}>
                    {
                        userInfo.isAdmin
                        &&
                        <Col span={4}>
                            <Input
                                addonBefore={intl.formatMessage({ id: 'liveAnalysis.model' })}
                                value={this.state.name}
                                onChange={this.onNameChange}
                            />
                        </Col>
                    }
                    <Col span={4}>
                        <PrefixLabel
                            text={intl.formatMessage({ id: 'liveAnalysis.statisticTime' })}
                        >
                            <RangePicker
                                style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                onInit={this.onRangePickerInit}
                                onChange={this.onRangePickerChanged}
                            />
                        </PrefixLabel>
                    </Col>
                    <Col span={4} offset={userInfo.isAdmin ? 12 : 16}>
                        <Button type="primary" icon={<SyncOutlined />} loading={this.state.loading}
                            onClick={this.onSearchClick}
                            style={{ float: 'right' }}
                        >
                            {intl.formatMessage({ id: 'global.update' })}
                        </Button>
                    </Col>
                </Row>
                <Table
                    scroll={this.state.scrollTable}
                    columns={this.state.columns}
                    dataSource={this.state.results}
                    bordered
                    footer={() => userInfo.isAdmin ? <TableFooter /> : null}
                />
            </div>
        )
    }
}

const mapStateToProps = store => (
    { userInfo: store.user }
)

export default connect(mapStateToProps)(injectIntl(LiveHistory))
