import React, { Component } from 'react'
import { Row, Col, Button, Table, Descriptions, Badge, Checkbox} from 'antd'
import { injectIntl } from 'react-intl'
import { SyncOutlined } from '@ant-design/icons'
import FamilyInfoAPI from '../../../api/Family/info'
import moment from 'moment'

class FamilyInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            name: '',
            email: '',
            memberCount: '',
            giftAmountRate: '',
            createdAt: '',
            members: []
        }
        const { intl } = this.props
        this.columns = [
            {
                title: intl.formatMessage({ id: 'FamilyInfo.modelName' }),
                dataIndex: 'modelName',
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.modelEmail' }),
                dataIndex: 'modelEmail',
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.hourlyRate' }),
                dataIndex: 'hourlyRate'
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.giftAmountRate' }),
                dataIndex: 'giftAmountRate'
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.privateRoomFeeRate' }),
                dataIndex: 'privateRoomFeeRate'
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.popular' }),
                dataIndex: 'popular'
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.onair' }),
                dataIndex: 'onair',
                align: 'center',
                //sortOrder: 'ascend',
                //sorter: (a, b) => b.onair - a.onair,
                render: (status) =>
                    (<Badge status={status? "processing" : "default"}/>)
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.isValid' }),
                dataIndex: 'isValid',
                align: 'center',
                //sortOrder: 'ascend',
                //sorter: (a, b) => b.isValid - a.isValid,
                render: (status) =>
                    (<Checkbox defaultChecked={status} disabled />)
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.createdAt' }),
                dataIndex: 'createdAt',
                //sortOrder: 'descend',
                //sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                render: date => date ? new Date(date).toLocaleString([], { hour12: false }) : ''
            },
            {
                title: intl.formatMessage({ id: 'FamilyInfo.option' }),
                dataIndex: 'j',
            },
        ];
    }

    async componentDidMount() {
        const ret = await FamilyInfoAPI.myInfo()
        if (ret.status === 200) {
            this.setState(ret.data[0])
        }

        const members = await FamilyInfoAPI.myMembers()
        if (members.status === 200) {
            this.setState({members: members.data})
        }
        
    }

    onUploadClick = async () => {
        //api
        this.setState({loading: true})

        const members = await FamilyInfoAPI.myMembers()
        if (members.status === 200) {
            this.setState({members: members.data})
            this.setState({loading: false})
        }
    }

    render() {
        const { intl } = this.props

        return (
            <>
                <Descriptions title={intl.formatMessage({ id: 'FamilyInfo.familyInfo' })} bordered>
                    <Descriptions.Item label={intl.formatMessage({ id: 'FamilyInfo.familyName' })}>
                        {this.state.name}
                    </Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ id: 'FamilyInfo.bindEmail' })}>
                        {this.state.email}
                    </Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ id: 'FamilyInfo.memberCount' })}>
                        {this.state.memberCount}
                    </Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ id: 'FamilyInfo.giftAmountRate' })}>
                        {this.state.giftAmountRate}
                    </Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ id: 'FamilyInfo.createdAt' })} span={2}>
                        {new Date(this.state.createdAt).toLocaleString([], { hour12: false })}
                    </Descriptions.Item>
                </Descriptions>

                <Row style={{ marginTop: 20 }}>
                    <Col span={12}>
                      <Descriptions title={intl.formatMessage({ id: 'FamilyInfo.memberList' })} bordered></Descriptions>
                    </Col>
                    <Col span={12}>
                        <Button 
                        style={{float: 'right'}}
                        type="primary"
                        loading={this.state.loading}
                        onClick={this.onUploadClick}
                        icon={<SyncOutlined/>}>{intl.formatMessage({ id: 'global.update' })}</Button>
                    </Col>
                </Row>
                <Table
                    style={{ marginBottom: 20 }}
                    dataSource={this.state.members}
                    columns={this.columns}
                    pagination={false}
                />
            </>
        )
    }

}

export default injectIntl(FamilyInfo)