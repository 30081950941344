import React, { Component } from 'react'
import 'antd/dist/antd.css'
import ImgCrop from 'antd-img-crop'
import {
  Form,
  Input,
  Button,
  Upload,
  message,
  DatePicker,
  Modal,
  Typography,
  Descriptions,
  Layout
} from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl'
import UserInfoAPI from '../../../api/user'
import imagePath from '../../../config/env'
import moment from 'moment'


function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


class UserInfo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      userIconLoading: false,
      backgroundImageLoading: false,
      userIconUrl: '',
      backgroundImageUrl: '',
      popPasswordModal: false,
      password: '',
      chackPassword: '',
      userData: {
        name: '',
        englishName: '',
        email: '',
        birthday: '',
        height: '',
        measurements: '',
        weight: '',
        bloodType: '',
        zodiac: '',
        language: '',
        education: '',
        workExperience: '',
        interest: '',
        movie: '',
        food: '',
        artist: '',
        game: '',
        luckyNumber: '',
        introduction: ''
      },
    }

    this.userForm = [
      {
        label: props.intl.formatMessage({ id: 'userInfo.icon' }),
        type: 'image',
        index: 'userIcon',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.backgroundImage' }),
        type: 'image',
        index: 'backgroundImage',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.name' }),
        index: 'name',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.name_en' }),
        index: 'englishName',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.email' }),
        index: 'email',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.password' }),
        type: 'password',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.birthday' }),
        type: 'DatePicker',
        index: 'birthday',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.height' }),
        index: 'height',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.weight' }),
        index: 'weight',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.width' }),
        index: 'measurements',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.blood' }),
        index: 'bloodType',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.constellation' }),
        index: 'zodiac',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.language' }),
        index: 'language',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.education' }),
        index: 'education',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.job' }),
        index: 'workExperience',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.interest' }),
        index: 'interest',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.movie' }),
        index: 'movie',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.food' }),
        index: 'food',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.actor' }),
        index: 'artist',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.game' }),
        index: 'game',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.number' }),
        index: 'luckyNumber',
      },
      {
        label: props.intl.formatMessage({ id: 'userInfo.introduction' }),
        type: 'TextArea',
        index: 'introduction',
      }
    ]
  }

  componentWillMount() {
    this.query(true)
  }

  query = async (reloadData) => {
    //api
    const ret = await UserInfoAPI.userInfo()
    if (ret.status === 200) {
      ret.data.photo.forEach((photo) => {
        if (photo.type == 1) {
          this.setState({ userIconUrl: imagePath.lasHost + photo.url })
        } else {
          this.setState({ backgroundImageUrl: imagePath.lasHost + photo.url })
        }
      })
      if (reloadData) {
        this.setState({ userData: ret.data })
      }
    }
  }

  handleChange = async (info, type) => {
    if (info.file.status === 'uploading') {
      if (type == 'userIcon') {
        this.setState({ userIconLoading: true })
      } else {
        this.setState({ backgroundImageLoading: true })
      }
      return;
    }
  };

  modifyPassword = () => {
    this.setState({ popPasswordModal: true })
  }

  modifyData = async () => {
    //api
    const user = this.state.userData
    user['english_name'] = user.englishName
    user['blood_type'] = user.bloodType
    user['work_experience'] = user.workExperience
    user['lucky_number'] = user.luckyNumber
    const ret = await UserInfoAPI.setUserInfo(user)
    if (ret.status === 200) {
      message.success(this.props.intl.formatMessage({ id: 'PopModifyPassword.success' }))
    } else {
      message.error(ret)
    }
  }

  uploadCustomRequest = async (file, type) => {
    const uploadImage = await UserInfoAPI.upload(
      file.file, //file
      type == 'userIcon' ? '1' : '2' //type
    )

    if (uploadImage.status === 200) {
      this.query(false)
      message.success('Upload Success!')
    } else {
      message.error(uploadImage)
    }
  }

  fromLayout = (data) => {
    const uploadButton = (
      <div>
        {data.index == 'userIcon' ?
          this.state.userIconLoading ? <LoadingOutlined /> : <PlusOutlined />
          :
          this.state.backgroundImageLoading ? <LoadingOutlined /> : <PlusOutlined />
        }
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    switch (data.type) {
      case 'image':
        return (
          <Descriptions.Item label={data.label} span={4}>
            <ImgCrop aspect={data.index == 'userIcon' ? 1 / 1 : 9 / 18}>
              <Upload
                name={data.index}
                accept=".jpg,.jpeg"
                listType="picture-card"
                customRequest={(info) => this.uploadCustomRequest(info, data.index)}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => this.handleChange(info, data.index)}
              >
                {data.index == 'userIcon' ?
                  this.state.userIconUrl ? <img src={this.state.userIconUrl} alt="example" style={{ maxWidth: '100%', maxHeight: '100%' }} /> : uploadButton
                  :
                  this.state.backgroundImageUrl ? <img src={this.state.backgroundImageUrl} alt="example" style={{ maxWidth: '100%', maxHeight: '100%' }} /> : uploadButton
                }
              </Upload></ImgCrop>
          </Descriptions.Item>)
      case 'password':
        return (
          <Descriptions.Item label={data.label} span={3}>
            <Button type="primary" onClick={this.modifyPassword}>
              {this.props.intl.formatMessage({ id: 'PopModifyPassword.modifyPassword' })}
            </Button>
          </Descriptions.Item>
        )
      case 'DatePicker':
        return (
          <Descriptions.Item label={data.label}>
            <DatePicker value={moment(this.state.userData[data.index], 'YYYY-MM-DD')} onChange={(date, string) => this.onDateChange(date, string, data.index)} />
          </Descriptions.Item>
        )
      case 'TextArea':
        return (
          <Descriptions.Item label={data.label}>
            <Input.TextArea rows={5}
              value={this.state.userData[data.index]}
              onChange={(text) => this.inputOnChange(text, data.index)} />
          </Descriptions.Item>
        )
      default:
        return (
          <Descriptions.Item label={data.label}>
            <Input value={this.state.userData[data.index]} onChange={(text) => this.inputOnChange(text, data.index)} />
          </Descriptions.Item>
        )
    }
  }

  inputOnChange = (text, index) => {
    const user = this.state.userData
    user[index] = text.target.value
    this.setState({ userData: user })
  }

  onDateChange = (date, string, index) => {
    if (date == null) return
    const user = this.state.userData
    user[index] = string
    this.setState({ userData: user })
  }

  handleOk = async () => {
    if (this.state.password === '') return
    if (this.state.password === this.state.chackPassword) {
      const ret = await UserInfoAPI.setUserInfo({ password: this.state.password })
      if (ret.status === 200) {
        message.success(this.props.intl.formatMessage({ id: 'PopModifyPassword.success' }))
        this.setState({ popPasswordModal: false })
      } else {
        message.error('6~10码且至少包含一个英文字符')
      }
    } else {
      message.error(this.props.intl.formatMessage({ id: 'PopModifyPassword.chackFailure' }));
    }
  };

  handleCancel = () => {
    this.setState({ popPasswordModal: false })
  };

  onChangePassword = (text, type) => {
    if (type == 'password') {
      this.setState({ password: text.target.value })
    } else {
      this.setState({ chackPassword: text.target.value })
    }
  }

  render() {
    const { intl } = this.props

    return (
      <>
        <Layout.Content style={{ background: 'white' }}>
          <Descriptions bordered>
            {this.userForm.map((data) => (
              this.fromLayout(data)
            ))}
          </Descriptions>
        </Layout.Content>

        <Button style={{ marginTop: '20px', marginBottom: '20px', float: 'right', height: '40px' }}
          type="primary"
          onClick={this.modifyData}
          block
        >
          {intl.formatMessage({ id: 'userInfo.modify' })}
        </Button>


        <Modal
          title={this.props.intl.formatMessage({ id: 'PopModifyPassword.modifyPassword' })}
          visible={this.state.popPasswordModal}
          closable={false}
          okText={this.props.intl.formatMessage({ id: 'userInfo.modify' })}
          cancelText={this.props.intl.formatMessage({ id: 'PopModifyPassword.cancel' })}
          onOk={this.handleOk}
          onCancel={this.handleCancel}>
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            name="basic"
            initialValues={{ remember: true }}
          >
            <Form.Item label={this.props.intl.formatMessage({ id: 'PopModifyPassword.newPassword' })}>
              <Input.Password onChange={(text) => this.onChangePassword(text, 'password')} />
              <Typography.Text type="secondary">{this.props.intl.formatMessage({ id: 'PopModifyPassword.inputPassword' })}</Typography.Text>
            </Form.Item>
            <Form.Item label={this.props.intl.formatMessage({ id: 'PopModifyPassword.chackPassword' })}>
              <Input.Password onChange={(text) => this.onChangePassword(text, 'chack')} />
              <Typography.Text type="secondary">{this.props.intl.formatMessage({ id: 'PopModifyPassword.inputPassword' })}</Typography.Text>
            </Form.Item>
          </Form>
        </Modal>
      </>
    )
  }
}

export default injectIntl(UserInfo)