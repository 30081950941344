import React, { useState, useEffect } from 'react'
import { DatePicker, Space, Dropdown, Button, Menu } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import moment from 'moment'
import { injectIntl } from 'react-intl'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'

const disabledDate = value => {
  return value > moment(new Date().addDays(7), dateFormat) ||
    value < moment(new Date().toLocaleDateString(), dateFormat)
}

const ActionBar = (props) => {
  const { intl, sportId, league, region, onChange } = props
  const [from, setFrom] = useState(moment(new Date(), dateFormat))
  const [to, setTo] = useState(moment(new Date().addDays(7), dateFormat).set({ hour: 23, minute: 59, second: 59 }))
  const [leagueId, setLeagueId] = useState(null)
  const [regionType, setRegionType] = useState(null)
  const [leagueName, setLeagueName] = useState(intl.formatMessage({ id: 'Sports.allLeague' }))
  const [regionName, setRegionName] = useState(intl.formatMessage({ id: 'Sports.allRegion' }))


  const regionMenu = (
    <Menu onClick={(e) => handleRegionMenu(e)} style={{ maxHeight: '400px', overflow: 'auto' }}>
      <Menu.Item key={['all']}>
        {intl.formatMessage({ id: 'Sports.allRegion' })}</Menu.Item>
      {
        region.map(r => {
          return (
            <Menu.Item key={[r]}>
              {r}
            </Menu.Item>
          )
        })
      }
    </Menu>
  )
  const leagueMenu = (
    <Menu onClick={(e) => handleLeagueMenu(e)} style={{ maxHeight: '400px', overflow: 'auto' }}>
      <Menu.Item key={[null, intl.formatMessage({ id: 'Sports.allLeague' })]}>
        {intl.formatMessage({ id: 'Sports.allLeague' })}</Menu.Item>
      {
        league.map(l => {
          return (
            <Menu.Item key={[l.leagueId, l.leagueName]}>
              {l.leagueName}
            </Menu.Item>
          )
        })
      }
    </Menu>
  )

  const onChangeRangePicker = (dates) => {
    setFrom(moment(dates[0], dateFormat))
    setTo(moment(dates[1], dateFormat))
    onChange(
      sportId,
      regionType,
      leagueId,
      moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss"),
      moment(dates[1]).set({ hour: 23, minute: 59, second: 59 }).toISOString(),
      'Date'
    )
  }

  const handleLeagueMenu = (e) => {
    const league = e.key.split(',')
    setLeagueId(league[0])
    setLeagueName(league[1])
    onChange(
      sportId,
      regionType,
      league[0],
      from.toISOString(),
      to.toISOString(),
      'League'
    )
  }
  const handleRegionMenu = (e) => {
    setRegionName(e.key == 'all' ? intl.formatMessage({ id: 'Sports.allRegion' }) : e.key)
    setRegionType(e.key == 'all' ? null : e.key)
    setLeagueId(null)
    setLeagueName(intl.formatMessage({ id: 'Sports.allLeague' }))
    onChange(
      sportId,
      e.key == 'all' ? null : e.key,
      null,
      from.toISOString(),
      to.toISOString(),
      'Region'
    )
  }

  useEffect(() => {
    setRegionName(intl.formatMessage({ id: 'Sports.allRegion' }))
    setLeagueName(intl.formatMessage({ id: 'Sports.allLeague' }))
  }, [sportId])

  return (
    <Space>
      <RangePicker
        value={[from, to]}
        disabled={[false, false]}
        disabledDate={disabledDate}
        onChange={onChangeRangePicker}
        allowClear={false}
      />
      <Dropdown overlay={regionMenu}>
        <Button style={{ marginLeft: '20px' }}>
          {regionName} <DownOutlined />
        </Button>
      </Dropdown>
      <Dropdown overlay={leagueMenu}>
        <Button style={{ marginLeft: '20px' }}>
          {leagueName} <DownOutlined />
        </Button>
      </Dropdown>
    </Space>
  )
}
export default injectIntl(ActionBar)