import React from 'react'
import { Modal, Typography } from 'antd'
import RQRcode from 'qrcode-react'
import {
  AndroidFilled,
  AppleFilled
} from '@ant-design/icons'


export default function QRcode({ visible, value, onCancel }) {
  const { Title } = Typography

  return (
    <Modal
      visible={visible}
      bodyStyle={{ textAlign: 'center' }}
      footer={null}
      onCancel={onCancel}
    >
      <RQRcode
        value={value.url}
        size={256}
      />
      <Title level={3}>
        {value.type === 'iOS' ? <AppleFilled /> : <AndroidFilled />} LiveTool
      </Title>
    </Modal>
  )
}


// class QRCode extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {}
//   }

//   render() {
//     const { intl } = this.props

//     return (
//       <Modal
//         visible={this.props.visible}
//         bodyStyle={{ textAlign: 'center' }}
//         footer={null}
//         onCancel={this.props.onCancel}
//       >
//         <RQRcode
//           value={this.props.value}
//           size={256}
//         />

//         {/* <h2>{intl.formatMessage({ id: 'app.qrcodeDesc' })}</h2> */}
//       </Modal>
//     )
//   }
// }

//export default QRCode
