import React, { Component } from 'react'
import { connect } from 'react-redux'
import RangePicker from '../forms/DateTimeRangePicker'
import PrefixLabel from '../forms/PrefixLabel'
import { Row, Col, Input, Button, Table } from 'antd'
import { injectIntl } from 'react-intl'
import { SyncOutlined } from '@ant-design/icons';

import OrderAPI from '../../api/order'
import FamilyOrderAPI from '../../api/Family/giftOrder'

class GiftHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      from: null,
      to: null,
      name: null,
      pagination: { current: 1 },
      results: [],
    }

    const { userInfo } = props

    this.columns = [
      {
        title: props.intl.formatMessage({ id: 'giftHistory.fromName' }),
        dataIndex: ['customer', 'nickname'],
      },
      {
        title: props.intl.formatMessage({ id: 'giftHistory.giftInfo' }),
        dataIndex: 'gift',
        render: gift => (`${gift.name} (${gift.point}) * ${gift.count} `)
      },
      {
        title: props.intl.formatMessage({ id: 'giftHistory.amount' }),
        dataIndex: 'amount'
      },
      {
        title: props.intl.formatMessage({ id: 'giftHistory.createdAt' }),
        dataIndex: 'createdAt',
        render: datetime => (new Date(datetime)).toLocaleString([], { hour12: false })
      }
    ]

    if (userInfo.isAdmin) {
      this.columns.unshift({
        title: props.intl.formatMessage({ id: 'giftHistory.model' }),
        dataIndex: ['model', 'name'],
      })
    }
  }

  onRangePickerInit = (moment) => {
    this.setState({ from: moment.startDate, to: moment.endDate })
  }

  onRangePickerChanged = (moment) => {
    this.setState({ from: moment.startDate, to: moment.endDate })
    this.query(moment.startDate.toISOString(), moment.endDate.toISOString())
  }

  onSearchClick = () => {
    this.query(this.state.from.toISOString(), this.state.to.toISOString())
  }

  onNameChange = (event) => {
    this.setState({ name: event.target.value })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { from, to } = this.state
    this.setState({ pagination, })
    this.query(from.toISOString(), to.toISOString(), pagination.current)
  }

  query = async (from, to, page) => {
    this.setState({ loading: true })
    const { userInfo } = this.props
    let ret = {}
    if (userInfo.isAdmin) {
      const { name } = this.state
      ret = await FamilyOrderAPI.list({ from, to, name, page })
    } else {
      ret = await OrderAPI.giftList({ from, to })
    }

    if (ret.status === 200) {
      const pagination = { ...this.state.pagination }
      pagination.total = ret.data.total
      this.setState({ results: userInfo.isAdmin ? ret.data.value : ret.data, pagination, loading: false })
    }
  }

  render() {
    const { intl, userInfo } = this.props

    return (
      <div>
        <Row gutter={4} style={{ marginBottom: '20px' }}>
          {
            userInfo.isAdmin
            &&
            <Col span={4}>
              <Input
                addonBefore={intl.formatMessage({ id: 'giftHistory.model' })}
                value={this.state.name}
                onChange={this.onNameChange}
              />
            </Col>
          }
          <Col span={4}>
            <PrefixLabel
              text={intl.formatMessage({ id: 'global.dateRange' })}
            >
              <RangePicker
                style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onInit={this.onRangePickerInit}
                onChange={this.onRangePickerChanged}
              />
            </PrefixLabel>
          </Col>
          <Col span={4} offset={userInfo.isAdmin ? 12 : 16}>
            <Button type="primary" icon={<SyncOutlined />} loading={this.state.loading}
              onClick={this.onSearchClick}
              style={{ float: 'right' }}
            >
              {intl.formatMessage({ id: 'global.update' })}
            </Button>
          </Col>
        </Row>
        <Table
          rowKey={record => record.id}
          columns={this.columns}
          dataSource={this.state.results}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          bordered
        />
      </div>
    )
  }
}

const mapStateToProps = store => (
  { userInfo: store.user }
)

export default connect(mapStateToProps)(injectIntl(GiftHistory))
