import io from 'socket.io-client'
import { lasHost } from '../config/env'
import i18n from '../locales'
const MAX_MSG_LENGTH = 200

class Socket {
  constructor(app, user, imUrl) {
    this.user = user
    this.app = app
    this.init(user.merchant, imUrl)
  }

  init(merchant, imUrl) {
    if (imUrl) {
      this.io = io.connect(`${imUrl}/model`, {
        path: '/ics/socket.io',
        transports: ['websocket']
      })
    } else if (lasHost.indexOf('http') !== -1) {
      this.io = io.connect(`${lasHost}/model`, {
        path: '/socket.io',
        transports: ['websocket']
      })
    } else {
      this.io = io.connect(`${window.location.protocol}//${window.location.host}/model`, {
        path: lasHost + '/socket.io',
        transports: ['websocket']
      })
    }

    this.mount()
  }

  connect() {
    if (this.io.disconnected) this.io.connect()
  }

  disconnect() {
    if (this.io.connected) {
      this.io.removeAllListeners()
      this.io.disconnect()
    }
  }

  mount() {
    this.unMount()
    this.io.on('connect', () => {
      this.io.emit('chat/login', { token: 'model', modelId: this.user.id, merchant: this.user.merchant })
    })
    this.io.on('disconnect', () => {
      const msg = {
        type: 9,
        msg: i18n[this.app.state.language]['lasSocket.disconnect'],
      }
      this.addMessage(msg)
    })

    this.io.on('chat/logout', data => {
      this.app.setState({ lasIsLogin: false })
      this.io.disconnect()
    })
    this.io.on('chat/login/success', () => {
      const msg = {
        type: 8,
        msg: i18n[this.app.state.language]['lasSocket.connect'],
      }
      this.addMessage(msg)
      this.app.setState({ lasIsLogin: true })
    })
    this.io.on('chat/login/failed', data => {
      const msg = {
        type: 9,
        msg: i18n[this.app.state.language]['lasSocket.loginFailed'],
      }
      this.addMessage(msg)
    })
    this.io.on('chat/message/new', data => {
      const msg = {
        type: data.role !== 'model' ? 0 : 5,
        name: data.nickname,
        msg: data.msg,
        customerId: data.customerId
      }
      this.addMessage(msg)
    })
    this.io.on('chat/message/monitor', data => {
      const msg = {
        type: 3,
        msg: data.msg
      }
      this.addMessage(msg)
    })

    this.io.on('chat/message/gift', data => {
      const msg = {
        type: 1,
        name: data.nickname,
        gift: data.gift,
        qty: data.qty,
        points: data.points,
        customerId: data.customerId
      }
      this.addMessage(msg)
    })

    this.io.on('chat/message/heart', (data) => {
      if (!data.heartSent) {
        const msg = {
          type: 2,
          name: data.nickname,
          customerId: data.customerId
        }
        this.addMessage(msg, true)
      }

      this.app.setState({ lasHeart: this.app.state.lasHeart + 1 })
    })

    this.io.on('chat/room/enter', (data) => {
      const msg = {
        type: 4,
        name: data.nickname,
        customerId: data.customerId
      }
      this.addMessage(msg)
    })

    this.io.on('public/user/betting', (data) => {
      const msg = {
        type: 6,
        name: data.nickname,
        gameCode: data.betting.gameCode,
        totalBettingAmount: data.betting.totalBettingAmount,
        customerId: data.customerId
      }
      this.addMessage(msg)
    })

    this.io.on('public/user/winner', (data) => {
      const msg = {
        type: 7,
        name: data.nickname,
        gameCode: data.gameCode,
        winAmount: data.winAmount,
        numero: data.numero,
        customerId: data.customerId
      }
      this.addMessage(msg)
    })
  }

  unMount() {
    this.io.removeAllListeners()
  }

  sendMessage(msg) {
    this.io.emit('chat/message/model', { msg })
  }

  sendGameRecommend(object) {
    this.io.emit('model/game/recommend', object)
  }

  addMessage(msg) {
    const lasMsg = this.app.state.lasMsg.slice()
    lasMsg.push(msg)
    if (lasMsg.length > MAX_MSG_LENGTH) {
      lasMsg.splice(0, lasMsg.length - MAX_MSG_LENGTH)
    }

    this.app.setState({ lasMsg })
  }
}

export default Socket