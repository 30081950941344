import React from 'react'
import { Form, Input, Button, Avatar, Row, Col, Upload, message, Space } from 'antd'
import ImgCrop from 'antd-img-crop'
import { injectIntl } from 'react-intl'
import { UserOutlined, UploadOutlined } from '@ant-design/icons'
import UserInfoAPI from '../../../../api/user'
import imagePath from '../../../../config/env'

const { TextArea } = Input

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class UserSetting extends React.Component {

    state = {
        anchorId: 0,
        imageSrc: '',
        userData: {
            name: '',
            email: '',
            introduction: '',
            announcement: ''
        }
    }

    uploadCustomRequest = async (file) => {
        const uploadImage = await UserInfoAPI.upload(
            file.file, //file
            '1' //type
        )
        if (uploadImage.status === 200) {
            this.setState({
                imageSrc: imagePath.sportHost + `/public/uploads/users/${this.state.anchorId}/` + uploadImage.data
            })
        } else {
            message.error(uploadImage)
        }
    }

    componentWillMount() {
        this.getUserData()
    }

    getUserData = async () => {
        const result = await UserInfoAPI.userInfo()
        if (result.status === 200) {
            result.data.photo.forEach((photo) => {
                if (photo.type == 1) {
                    this.setState({ imageSrc: imagePath.sportHost + photo.url })
                }
            })
            this.setState({ userData: result.data ,anchorId: result.data.id})
        }
    }

    inputOnChange = (text) => {
        const user = this.state.userData
        user[text.target.id] = text.target.value
        this.setState({ userData: user })
    }

    uploadUserData = async () => {
        const result = await UserInfoAPI.setUserInfo(this.state.userData)
        if (result.status === 200) {
            message.success(this.props.intl.formatMessage({ id: 'PopModifyPassword.success' }))
        } else {
            message.error(result)
        }
    }

    render() {
        return (
            <Row>
                <Col span={12}>
                    <Form layout="vertical">

                        <Form.Item label={this.props.intl.formatMessage({ id: 'SportUserSetting.name' })} >
                            <Input id='name' value={this.state.userData.name} onChange={this.inputOnChange} />
                        </Form.Item>

                        <Form.Item label={this.props.intl.formatMessage({ id: 'SportUserSetting.email' })}>
                            <Input id='email' value={this.state.userData.email} onChange={this.inputOnChange} />
                        </Form.Item>

                        <Form.Item label={this.props.intl.formatMessage({ id: 'SportUserSetting.introduction' })}>
                            <TextArea id='introduction' value={this.state.userData.introduction} rows={5} onChange={this.inputOnChange} />
                        </Form.Item>

                        <Form.Item label={this.props.intl.formatMessage({ id: 'SportUserSetting.announcement' })}>
                            <TextArea id='announcement' value={this.state.userData.announcement} rows={5} onChange={this.inputOnChange} />
                        </Form.Item>

                        <Button type="primary" onClick={this.uploadUserData}>
                            {this.props.intl.formatMessage({ id: 'SportUserSetting.uploadData' })}
                        </Button>
                    </Form>
                </Col>
                <Col span={12} align="middle">
                    <Space align='center' direction='vertical'>
                        <Avatar src={this.state.imageSrc} shape="circle" size={150} icon={<UserOutlined />} />
                        <ImgCrop aspect={1 / 1} shape="round">
                            <Upload
                                showUploadList={false}
                                customRequest={this.uploadCustomRequest}
                                beforeUpload={beforeUpload}
                            >
                                <Button icon={<UploadOutlined />}>
                                    {this.props.intl.formatMessage({ id: 'SportUserSetting.uploadAvatar' })}
                                </Button>
                            </Upload>
                        </ImgCrop>
                    </Space>
                </Col>
            </Row>

        )
    }
}

export default injectIntl(UserSetting)