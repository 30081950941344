import API from '../index'

const path = 'statistics'

export default {
  async anchorEventList(from, to, mode, id) {
    const params = { limit: 1000 }
    if (mode) params.mode = mode
    if (from) params.from = from
    if (to) params.to = to
    try {
      const response = await API.get(`/${path}/anchor/${id}`, { params })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
}