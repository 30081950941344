import { i18nObject } from '../helper/helper'

const language = {
  app: {
    mobileLogin: 'เข้าสู่ระบบมือถือ',
    logout: 'ออกจากระบบ',
    qrcodeDesc: 'กรุณาเปิดแอพมือถือเพื่อสแกนเข้าสู่ระบบ'
  },
  familyMonitor: {
    anchor_count: 'Anchor Count',
    anchor_live_count: 'Live Count',
    anchor_porn_live_count: 'Porn Live Count',
    clientCount: 'Client Count',
    no_anchor: 'No Anchor',
    sureRemove: 'Are you sure offline?',
    cancel: 'Cancel',
    confirm: 'Confirm',
    remove: 'Offline',
    monitor_live: 'Monitor Live',
    sendGift: 'Send Anchor',
    monitor: 'Monitor',
    enterRoom: 'Enter Room',
    anchor_live: 'Live',
    anchor_porn_live: 'Pron Live',
    inputPlaceholder: 'Private messages from monitors to streamers...',
    send: 'Send',
    kick_success: 'The operation is successful, update the host list',
  },
  userInfo: {
    icon: 'รูปถ่ายโปรไฟล์',
    backgroundImage: 'ภาพพื้นหลัง',
    name: 'ชื่อผู้สตรีม',
    name_en: 'ชื่ออังกฤษผู้สตรีม',
    email: 'อีเมลผู้สตรีม',
    password: 'รหัสผ่าน',
    birthday: 'วันเกิด',
    height: 'ส่วนสูง',
    weight: 'น้ำหนัก',
    width: 'สัดส่วน',
    blood: 'กรุ๊ปเลือด',
    constellation: 'จักรราศี',
    language: 'ภาษา',
    education: 'การศึกษา',
    job: 'ประสบการณ์ทำงาน',
    interest: 'ความสนใจ',
    movie: 'หนังเรื่องโปรด',
    food: 'อาหารโปรด',
    actor: 'ศิลปินคนโปรด',
    game: 'เกมโปรด',
    number: 'เลขนำโชค',
    introduction: 'แนะนำตัวเอง',
    modifyPassword: 'แก้ไขรหัสผ่าน',
    modify: 'แก้ไข'
  },
  routes: {
    info: 'ข้อมูลส่วนตัว',
    dashboard: 'แผงควบคุม',
    liveControl: 'การจัดการสตรีม',
    giftHistory: 'ประวัติการส่งของขวัญ',
    liveHistory: 'บันทึกการสตรีม',
    modelPlayer: 'ูสตรีมทั้งหมด',
    RoomOrder: 'บันทึกค่าห้อง',
    analysis: 'วิเคราะห์ข้อมูลการสตรีม',
    userIncome: 'บันทึกรายได้',
    familyManager: 'การจัดการครอบครัว',
    familyIncome: 'บันทึกรายได้ครอบครัว',
    events: 'การจัดการแข่งขัน',
    SportStatistics: 'สอบถามสถิติการสตรีมการแข่ง',
    monitor_live: 'Monitor Live'
  },
  global: {
    update: 'อัปเดต',
    query: 'สอบถาม',
    dateRange: 'ช่วงวันที่',
    all: 'ทั้งหมด',
    export: 'ส่งออก'
  },
  dateTimePicker: {
    today: 'ทุกวันนี้',
    yesterday: 'เมื่อวาน',
    l7days: '7วันล่าสุด',
    l30days: '30วันล่าสุด',
    tMonth: 'เดือนนี้',
    lMonth: 'เดือนที่แล้ว',
    custom: 'กำหนดช่วงเวลาเอง',
  },
  lasSocket: {
    disconnect: 'ตัดการเชื่อมต่อ',
    connect: 'เชื่อมต่อแล้ว',
    loginFailed: 'เข้าสู่ระบบล้มเหลว'
  },
  login: {
    title: 'เข้าสู่ระบบการสตรีม',
    account: 'หมายเลขบัญชี',
    accountPlaceholder: 'อีเมล',
    password: 'รหัสผ่าน',
    modelLogin: 'เข้าสู่ระบบการสตรีม',
    adminLogin: 'เข้าสู่ระบบผู้ดูแลระบบ',
    familyLogin: 'เข้าสู่ระบบครอบครัว',
    adminPlaceholder: 'บัญชีผู้ดูแลระบบ',
  },
  giftHistory: {
    model: 'ชื่อผู้สตรีม',
    fromName: 'เลขบัญชีที่ให้รางวัล',
    giftInfo: 'ของขวัญ',
    amount: 'ยอดเงินรวม',
    createdAt: 'วันที่',
    sum: 'ผลรวมของรางวัล'
  },
  liveHistory: {
    createdAt: 'เวลาที่เริ่ม',
    updatedAt: 'เวลาสิ้นสุด',
    total: 'ผลรวม',
    type: 'ประเภท',
    all: 'ทั้งหมด',
    type0: 'ห้องสตรีม',
    type1: 'สตรีมบนมือถือ'
  },
  liveAnalysis: {
    date: 'วันที่',
    liveCount: 'จำนวนครั้งที่สตรีม',
    totalInterval: 'ระยะเวลาสตรีม',
    watchedCounts: 'จำนวนครั้งที่ชม',
    clientCounts: 'จำนวนผู้ชม',
    giftSum: 'จำนวนของขวัญ',
    betCount: 'จำนวนเดิมพัน',
    winOrLose: 'กำไรและขาดทุนของผู้เล่น',
    eBet: 'เดิมพันE Bet',
    eBetWin: 'ชนะเกมE Bet',
    liveBet: 'เดิมพันเกมสด',
    liveValidBet: 'เดิมพันเกมสดที่มีผล',
    liveWin: 'ชนะเกมสด',
    lotteryBet: 'เดิมพันหวย',
    lotteryWin: 'ถูกรางวัลหวย',
    battleLostSum: 'แพ้การต่อสู้',
    battleValidBet: 'การเดิมพันที่มีผล',
    battleWinSum: 'ชนะการต่อสู้',
    fishBet: 'เดิมพันยิงปลา',
    fishWin: 'ชนะยิงปลา',
    sportBet: 'เดิมพันกีฬา',
    sportValidBet: 'การเดิมพันกีฬาที่มีผล',
    sportWin: 'รางวัลกีฬา',
    handicapBet: 'เดิมพันแฮนดิแคปหวย',
    handicapWin: 'ถูกรางวัลแฮนดิแคปหวย',
    statisticTime: 'เวลาสถิติ',
    model: 'ชื่อผู้สตรีม',
    privateFees: 'ค่าห้อง',
    totalGiftSum: 'จำนวนของขวัญทั้งหมด',
    totalPrivateFees: 'ค่าห้องทั้งหมด',
  },
  chat: {
    gift: 'สำหรับคุณ',
    heart: 'ให้หัวใจ',
    monitor: 'เจ้าหน้าที่ตรวจสอบ',
    model: 'ผู้สตรีม',
    betting: 'เดิมพันแล้ว',
    win: 'ถูกรางวัลแล้ว',
    placeholder: 'พูดอะไรหน่อย',
    send: 'ส่ง',
    enter: 'เข้าห้อง',
    focus: 'ติดตามผู้สตรีมแล้ว',
    manager: 'จัดการห้อง'
  },
  games: {
    series: '{series} เวลา',
    whichSeries: 'รอบที่{series}',
    lottery: 'หวย',
    esport: 'อีสปอร์ต',
    sport: 'กีฬา',
    littleGame: 'เกมเด็ก',
    comingSoon: 'โปรดติดตาม',
    gameSelected: 'เกมที่เลือกปัจจุบัน',
    gameRecommend: 'ตกลงที่จะส่งคำแนะนำ',
    gameCancel: 'ยกเลิกแนะนำ',
    menu: 'เมนูเกม',
    binding: 'ผูกพัน',
    bind: 'ผูก',
    cancelBind: 'ยกเลิกการผูก',
  },
  liveControl: {
    scheduledAt: 'เวลาเริ่มต้นโดยประมาณ',
    webStart: 'เปิดห้องสตรีม',
    webEnd: 'ปิดห้องสตรีม',
    mobile: 'แอพมือถือ',
    PC: 'ผู้ช่วย OBS',
    PCStream: 'เครื่องมือสตรีมมิ่งPC',
    PCStreaming: 'เครื่องมือสตรีมมิงPC-กำลังสตรีม',
    pushUrl: 'URL สตรีมมิ่ง',
    pushKey: 'คีย์สตรีมมิ่ง',
    OBSContent: 'แจ้งเตือน：ใช้ตัวช่วย OBSและเครื่องมืออื่นๆเพื่อกำหนดค่าที่อยู่การสตรีมจากนั้น หลังจากที่เริ่มกดผู้ช่วยแล้วคลิกปุ่ม "เริ่ม" ด้านล่าง',
    loginQRCode: 'คลิกเพื่อรับรหัส QR เข้าสู่ระบบ',
    onAir: 'เริ่มการไลฟ์สด',
    selectDevice: 'กรุณาเลือกอุปกรณ์ไลฟ์สด',
    normal: 'สตรีมสีเขียว',
    vip: 'การแสดงใหญ่',
    private: 'ค่าใช้จ่ายส่วนตัว',
    onAirType: 'รูปแบบเปิดห้อง',
    roomLevel: 'เข้าระดับห้อง',
    roomWatch: 'เวลาในการรับชม',
    roomAmount: 'ค่าเข้าห้อง',
    min: 'นาที',
    unrestricted: 'ไม่จำกัด',
    qrcodeMark: 'รหัส QR ใช้ได้ภายใน 5 นาที โปรดรับใหม่เมื่อหมดอายุ'
  },
  RoomOrder: {
    customerName: 'ชื่อผู้ใช้',
    createdAt: 'เวลาที่ทำธุรกรรม',
    fee: 'จำนวนเงิน',
    minute: 'ชั่วโมง (นาที)',
  },
  UserIncome: {
    Date: 'วันที่',
    onairDuration: 'ระยะเวลาสตรีม',
    Wage: 'รายได้ต่อชั่วโมง',
    giftSplite: 'ส่วนแบ่งของขวัญ(%)',
    roomFeeSplite: 'ส่วนแบ่งค่าห้อง(%)',
    giftIncome: 'รายได้จากของขวัญ',
    roomFeeIncome: 'รายได้ค่าห้อง',
    onairIncome: 'รายได้จากสตรีม',
    totalGiftIncome: 'รายได้จากของขวัญ',
    totalRoomFeeIncome: 'รายได้จากค่าห้อง',
    totalIncome: 'สรุปรายได้'
  },
  FamilyIncome: {
    Date: 'วันที่',
    users: 'จำนวนครั้งสตรีม',
    Wage: 'รายได้ต่อชั่วโมง',
    giftSplite: 'ส่วนแบ่งของขวัญ(%)',
    roomFeeSplite: 'ส่วนแบ่งค่าห้อง(%)',
    onairDuration: 'ระยะเวลาสตรีม',
    Income: 'รายได้ครอบครัว',
    amount: 'จำนวนเงินที่จ่าย',
    amount_na: 'จำนวนเงินที่ยังไม่จ่าย',
    endDate: 'เวลาที่จ่าย',
    option: 'ดำเนินงาน',
    memberProfit: 'การจ่ายรายได้ผู้สตรีม',
    detail: 'รายละเอียดผู้สตรีม',
    sum: 'ทั้งหมด',
  },
  UserDetails: {
    userId: 'IDผู้สตรีม',
    familyName: 'ชื่อครอบครัว',
    userName: 'ชื่อผู้สตรีม',
    Wage: 'รายได้ต่อชั่วโมง',
    giftSplite: 'ส่วนแบ่งของขวัญ(%)',
    roomFeeSplite: 'ส่วนแบ่งค่าห้อง(%)',
    onairDuration: 'ระยะเวลาสตรีม',
    giftIncome: 'รายได้จากของขวัญ',
    roomFeeIncome: 'รายได้ค่าห้อง',
    onairIncome: 'รายได้จากสตรีม',
    totalGiftIncome: 'รายได้จากของขวัญ',
    totalRoomFeeIncome: 'รายได้จากค่าห้อง',
    totalIncome: 'รายได้ผู้สตรีม',

    total: 'ทั้งหมด',
  },
  PopModifyPassword: {
    modifyPassword: 'แก้ไขรหัสผ่าน',
    cancel: 'ยกเลิก',
    newPassword: 'รหัสผ่านใหม่',
    chackPassword: 'ยืนยันรหัสผ่านใหม่อีกครั้ง',
    success: 'แก้ไขสำเร็จ',

    chackFailure: 'รหัสผ่านทั้งสองไม่ตรงกัน',
    inputPassword: 'รหัส 6-10 และอักขระภาษาอังกฤษอย่างน้อยหนึ่งตัว'
  },

  LineChart: {
    date: 'วันที่',
    points: 'คะแนน',
  },
  FamilyInfo: {
    modelName: 'ชื่อผู้สตรีม',
    modelEmail: 'อีเมลผู้สตรีม',
    hourlyRate: 'รายได้ต่อชั่วโมง',
    giftAmountRate: 'ส่วนแบ่งของขวัญ(%)',
    privateRoomFeeRate: 'ส่วนแบ่งค่าห้อง(%)',
    popular: 'ความนิยมขณะนี้ของผู้สตรีม',
    onair: 'กำลังสตรีม',
    isValid: 'สถานะ',
    createdAt: 'เวลาที่สร้าง',
    option: 'ดำเนินการ',

    familyInfo: 'ข้อมูลครอบครัว',
    familyName: 'ชื่อครอบครัว',
    bindEmail: 'ผูกอีเมล',
    memberCount: 'จำนวนครั้งสตรีม',
    memberList: 'รายการสตรีม',
  },
  Sports: {
    myEvents: 'การแข่งขันของฉัน',
    sportsEvents: 'รายการการแข่ง',
    date: 'วันที่',
    leagueName: 'พันธมิตร',
    eventName: 'ชื่อการแข่งขัน',
    liveStatus: 'เริ่มสตรีมหรือไม่',
    customerCount: 'จำนวนผู้ชมที่จองไว้',
    action: 'ดำเนินงาน',
    cancelEvent: 'ยืนยันยกเลิกกำหนดกิจกรรมหรือไม่',
    fllowEvent: 'ยืนยันจองกิจกรรมนี้หรือไม่',
    ok: 'ยืนยัน',
    cancel: 'ยกเลิก',
    cancelEventSuccess: 'ยกเลิกการนัดหมายแล้ว',
    fllowEventSuccess: 'นัดหมายสำเร็จ',
    fllowed: 'จองแล้ว',
    fllow: 'จอง',
    onair: 'กำลังสตรีม',
    over: 'กำลังถ่ายทอดสด',
    never: 'ยังไม่ออกอากาศ',
    home: '(เจ้าภาพ)',
    guest: '(แขก)',
    region: 'พื้นที่',
    allLeague: 'พันธมิตรทั้งหมด',
    allRegion: 'ทุกภูมิภาค',
    fllowedAnchor: 'กำหนดจำนวนการสตรีม',
    fllowEventError: 'เจ้าภาพกิจกรรมนี้เต็มแล้ว',
    fllowFull: 'คิวการจองเต็มแล้ว',
  },
  SportLive: {
    main: '(เจ้าภาพ)',
    guest: '(แขก)',
    selectEvent: 'เลือกรายการแข่งขันสด',
    seletcEventLimitNotice: '* แสดงเฉพาะการแข่งที่จองไว้ในอีก 12 ชั่วโมงข้างหน้า',
    liveEvent: 'ถ่ายทอดสด',
    streamUrl: 'URL สตรีมมิ่ง',
    streamKey: 'คีย์สตรีมมิ่ง',
    tooltip: 'แจ้งเตือน：ใช้ตัวช่วย OBSและเครื่องมืออื่นๆเพื่อกำหนดค่าที่อยู่การสตรีมจากนั้น หลังจากที่เริ่มกดผู้ช่วยแล้วคลิกปุ่ม "เริ่ม" ด้านล่าง',
    startLive: 'เริ่มการไลฟ์สด',
    stopLive: 'ปิดการไลฟ์',
    screenSwitch: 'แสดงวิดีโอ',
    alartMessage: "ไม่พบข้อมูลการสตรีมโดยเครื่องมือเช่น OBS Assistant โปรดลองกดอีกครั้งแล้วเปิด 'Video Display'",
    twoHours: '2 ชั่วโมง',
    oneDay: '1 วัน',
    threeDay: '3 วัน',
    aMonth: '30 วัน',
    infinite: 'ถาวร',
    becomeManager: 'เป็นผู้จัดการห้องแล้ว',
    cancelledManager: 'ยกเลิกเป็นผู้จัดการห้อง',
    has: 'ได้รับการ',
    mute: 'ปิดเสียง',
    addManager: 'ตั้งเป็นผู้จัดการห้อง',
    delManager: 'ลบผู้จัดการห้อง',
    manager: 'ผู้จัดการห้อง',
    muteList: 'รายชื่อต้องห้าม',
    other:'อื่นๆ',
    customTitle: 'โปรดป้อนชื่อสดที่กำหนดเอง',
    customMessage: 'กรุณาใส่ชื่อเรื่อง'
  },
  MuteList: {
    name: 'ชื่อเล่น',
    muteTime: 'เวลาที่ถูกปิดเสียง',
    timeLeft: 'เวลาที่เหลือ',
    end: 'จบแล้ว',
    manager: 'ผู้จัดการห้อง',
    action: 'ดำเนินงาน',
    has: 'haได้รับการ',
    disMute: 'เปิดเสียง'
  },
  SportStatistics: {
    liveStartAt: 'เวลาเริ่มการสตรีม',
    liveOverAt: 'เวลาสิ้นสุดการสตรีม',
    startAt: 'เวลาเริ่มแข่งขัน',
    eventName: 'ชื่อการแข่งขัน',
    gamePrizeSum: 'ค่าใช้จ่าย',
    gameGiftSum: 'จำนวนค่าของขวัญ',
    main: '(เจ้าภาพ)',
    guest: '(แขก)',
  },
  SportUserInfo: {
    setting: 'การตั้งค่าพื้นฐาน',
    background: 'ตั้งค่าภาพพื้นหลัง',
    private: 'ตั้งค่าความปลอดภัย'
  },
  SportPrivateSetting: {
    account: 'รหัสผ่านบัญชี'
  },
  SportUserSetting: {
    name: 'ชื่อ',
    email: 'กล่องจดหมาย',
    introduction: 'แนะนำตัวเอง',
    announcement: 'ประกาศผู้สตรีม',
    uploadData: 'อัปเดตข้อมูลเบื้องต้น',
    uploadAvatar: 'เปลี่ยนรูปประจำตัว'
  },
  SportCommonTypes: {
    Soccer: 'ฟุตบอล',
    Basketball: 'บาสเกตบอล',
    Tennis: 'เทนนิส',
    Volleyball: 'วอลเลย์บอล',
    Baseball: 'เบสบอล',
    AmericanFootball: 'รักบี้',
    IceHockey: 'ฮอคกี้น้ำแข็ง',
    ESports: 'ีสปอร์ต',
    Handball: 'แฮนด์บอล',
    Snooker: 'สนุ๊กเกอร์',
    Badminton: 'แบดมินตัน',
    TableTennis: 'ปิงปอง',
    Boxing: 'มวย',
    WaterPolo: 'โปโลน้ำ',
    Floorball: 'ฟลอร์บอล',
    Squash: 'สควอช',
    Cricket: 'คริกเก็ต',
    RugbyUnion: 'รักบี้ยูเนี่ยน',
    RugbyLeague: 'รักบี้ลีก',
    Darts: 'ลูกดอก',
    Futsal: 'ฟุตซอล',
    BeachVolleyball: 'วอลเลย์บอลชายหาด',

    Others: 'อื่น ๆ'
  }
}

export default i18nObject(language)