import API from './index'

const path = 'game'

export default {
  async getGameCategory() {
    try {
      const response = await API.get(`/${path}/category`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async getGCSVendor() {
    try {
      const response = await API.get(`/${path}/GCSport`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async getLGWGame() {
    try {
      const response = await API.get(`/${path}/LGWGame`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async broadcastGCSGame(body) {
    try {
      const response = await API.post(`/${path}/broadcastGCSGame`, body)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },

  async broadcastLGWGame(body) {
    try {
      const response = await API.post(`/${path}/broadcastLGWGame`, body)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
}
