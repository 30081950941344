import React, { useState } from "react";
import { Col, Avatar, Tooltip } from 'antd'
import LasChat from '../../../chat/LasChat'
import Input from '../../../chat/Input'
import ActionMenu from '../../Sports/Live/actionMenu';
import { injectIntl } from 'react-intl';
import '../../../../css/socket.css'

function Chat(props) {
  const { intl, msg, heart, sendMessage, games, reloadBanList, reloadManagerList, managerList } = props
  const [showMenu, setShowMenu] = useState(false)
  const [customerData, setCustomerData] = useState({})

  const openActionMenu = (name, customerId) => {
    setShowMenu(true)
    setCustomerData({
      nickname: name,
      customerId: customerId
    })
  }

  const tranColor = (name = '') => {
    var str = '';
    for (var i = 0; i < name.length; i++) {
      str += parseInt(name[i].charCodeAt(0), 10).toString(16);
    }
    return '#' + str.slice(1, 4);
  }

  return (
    <Col className="las" style={{ width: '45%', height: '100%' }}>

      <div className="manager-content">
        <div>{intl.formatMessage({ id: 'chat.manager' })}</div>
        <Avatar.Group style={{ marginLeft: '.5rem' }}>
          {
            managerList.map((user) => {
              return (
                <Tooltip title={user.nickname} placement="top">
                  <a onClick={() => openActionMenu(user.nickname, user.customerId)}>
                    <Avatar
                      style={{
                        backgroundColor: tranColor(user.nickname),
                      }}
                    >{user.nickname.slice(0, 2)}</Avatar>
                  </a>
                </Tooltip>
              )
            })
          }
        </Avatar.Group>
      </div>

      <LasChat
        msg={msg}
        heart={heart}
        games={games}
        onOpenMenu={(name, customerId) => openActionMenu(name, customerId)}
      />
      <Input onClick={sendMessage} />

      <ActionMenu
        isShow={showMenu}
        dataSource={customerData}
        manager={managerList}
        onReloadBanList={() => reloadBanList()}
        onReloadManagerList={() => reloadManagerList()}
        onCancel={() => setShowMenu(false)}
      />
    </Col>
  )
}


export default injectIntl(Chat)