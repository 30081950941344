export const userTypes = {
  set: `Set user`,
}

const UserInit = {}

export default (state = UserInit, action) => {
  switch (action.type) {
    case userTypes.set:
      return action.value

    default:
      return state
  }
}
