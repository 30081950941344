import { useEffect, useRef, useState } from "react"
import io from 'socket.io-client'
import { getUserInfo } from '../../../../helper/helper'
import config from '../../../../config/env'


const uri = config.lasHost + '/familyMonitor'
const path = '/api/socket.io'

const useSocket = () => {
  const socket = useRef(null)

  useEffect(() => {
    socket.current = io.connect(uri, {
      path,
      query: {
        token: getUserInfo().accessToken
      },
      transports: ['websocket'],
      forceNew: true
    })

    return () => {
      if (socket.current) {
        socket.current.removeAllListeners()
        socket.current.disconnect()
      }
    }
  }, [])

  return socket

}

export default useSocket
