import React, { Component } from 'react'
import { Table, Typography } from 'antd'
import { injectIntl } from 'react-intl'

class UserDetailsView extends Component {

  constructor(props) {
    super(props)

    this.columns = [
      {
        title: props.intl.formatMessage({ id: 'UserDetails.userId' }),
        dataIndex: 'modelId'
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.userName' }),
        dataIndex: 'modelName',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.Wage' }),
        dataIndex: 'hourlyRate',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.giftSplite' }),
        dataIndex: 'giftAmountRate',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.roomFeeSplite' }),
        dataIndex: 'privateRoomFeeRate',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.onairDuration' }),
        dataIndex: 'intervalSum',
        render: time => time ? this.dateToHM(time) : ""
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.giftIncome' }),
        dataIndex: 'giftSum',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.roomFeeIncome' }),
        dataIndex: 'privateFeeSum',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.onairIncome' }),
        dataIndex: 'intervalProfit',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.totalGiftIncome' }),
        dataIndex: 'giftProfit',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.totalRoomFeeIncome' }),
        dataIndex: 'privateFeeProfit',
      },
      {
        title: props.intl.formatMessage({ id: 'UserDetails.totalIncome' }),
        dataIndex: 'profitSum',
      },
    ]
  }

  dateToHM(time) {
    let H = Math.floor(time / 3600), M = Math.floor((time % 3600) / 60)

    return `${H > 0 ? H + 'h' : ''}${M > 0 ? ("0" + M).slice(-2) + 'm' : ''}`
  }

  dateToHMS(time) {
    let H = Math.floor(time / 3600), M = Math.floor((time % 3600) / 60), S = Math.floor(time % 60)

    return `${H > 0 ? H + 'h' : ''}${M > 0 ? ("0" + M).slice(-2) + 'm' : ''}${S > 0 ? ("0" + S).slice(-2) + 's' : '0s'}`
  }

  summary = (pageData) => {
    let totalIntervalSum = 0,
      totalGiftSum = 0,
      totalPrivateFeeSum = 0,
      totalIntervalProfit = 0,
      totalGiftProfit = 0,
      totalPrivateFeeProfit = 0,
      totalProfitSum = 0

    pageData.forEach(({ intervalSum, giftSum, privateFeeSum, intervalProfit, giftProfit, privateFeeProfit, profitSum }) => {
      totalIntervalSum += intervalSum
      totalGiftSum += giftSum
      totalPrivateFeeSum += privateFeeSum
      totalIntervalProfit += intervalProfit
      totalGiftProfit += giftProfit
      totalPrivateFeeProfit += privateFeeProfit
      totalProfitSum += profitSum
    })

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell>
          {this.props.intl.formatMessage({ id: 'UserDetails.total' })}
        </Table.Summary.Cell>
        <Table.Summary.Cell/>
        <Table.Summary.Cell/>
        <Table.Summary.Cell/>
        <Table.Summary.Cell/>
        <Table.Summary.Cell>
          <Typography>{this.dateToHM(totalIntervalSum)}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{totalGiftSum.toLocaleString()}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{totalPrivateFeeSum.toLocaleString()}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{totalIntervalProfit.toLocaleString()}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{totalGiftProfit.toLocaleString()}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{totalPrivateFeeProfit.toLocaleString()}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{totalProfitSum.toLocaleString()}</Typography>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    )
  }

  render() {
    const { userDetailsResults } = this.props

    return (
      <div>
        <Table
          rowKey={record => record.id}
          columns={this.columns}
          dataSource={userDetailsResults}
          scroll={{ x: 1000 }}
          bordered
          summary={this.summary}
        />
      </div>
    )
  }
}

export default injectIntl(UserDetailsView)
