import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Row, Col } from 'antd'
import RangePicker from '../forms/DateTimeRangePicker'
import PrefixLabel from '../forms/PrefixLabel'
import LineChart from '../charts/LineChart'

import moment from 'moment'
import OrderAPI from '../../api/order'

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      from: moment().add(-7, 'd'),
      to: moment(),
      giftSum: []
    }
  }

  processData = (data) => {
    const result = []
    for (let i = 0; i < data.length; i++) {
      result.push({
        x: moment(data[i].date),
        y: data[i].amount
      })
    }
    return result
  }

  onRangePickerInit = (moment) => {
    this.setState({ from: moment.startDate, to: moment.endDate })
    this.query(moment.startDate.toISOString(), moment.endDate.toISOString())
  }

  onRangePickerChanged = (moment) => {
    this.setState({ from: moment.startDate, to: moment.endDate })
    this.query(moment.startDate.toISOString(), moment.endDate.toISOString())
  }

  query = async (from, to) => {
    this.setState({ loading: true })
    const ret = await OrderAPI.giftSum({ from, to })
    if (ret.status === 200) {
      this.setState({ giftSum: this.processData(ret.data), loading: false })
    }
  }

  render() {
    const { intl } = this.props

    return (
      <div>
        <Row>
          <Col span={4}>
            <PrefixLabel
              text={intl.formatMessage({ id: 'global.dateRange' })}
            >
              <RangePicker
                startDate={this.state.from}
                endDate={this.state.to}
                style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onInit={this.onRangePickerInit}
                onChange={this.onRangePickerChanged} 
              />
            </PrefixLabel>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <LineChart intl={intl} data={this.state.giftSum} label={intl.formatMessage({ id: 'giftHistory.sum' })} />
          </Col>
        </Row>
      </div>
    )
  }
}

export default injectIntl(Index)
