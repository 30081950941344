import React, { Component } from 'react'
import RangePicker from '../../forms/DateTimeRangePicker'
import PrefixLabel from '../../forms/PrefixLabel'
import { Row, Col, Button, Table, Typography, Modal } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { injectIntl } from 'react-intl'
import UserDetailsView from './UserDetailsView'
import moment from 'moment'

import FamilyProfitAPI from '../../../api/familyProfit'

class UserIncome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showUserDetails: false,
      loading: false,
      pagination: { current: 1 },
      from: null,
      to: null,
      results: [],
      resultSum: {},
      userDetailsResults: [],
      memberProfitSum: null
    }

    this.columns = [
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.Date' }),
        dataIndex: 'date'
      },
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.users' }),
        dataIndex: 'memberCount',
      },
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.giftSplite' }),
        dataIndex: 'giftAmountRate',
      },
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.roomFeeSplite' }),
        dataIndex: 'privateRoomFeeRate',
      },
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.onairDuration' }),
        dataIndex: 'intervalSum',
        render: time => time ? this.dateToHM(time) : ""
      },     
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.Income' }),
        dataIndex: 'profitSum',
      },
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.amount_na' }),
        dataIndex: 'noProfitSum',
      },
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.amount' }),
        dataIndex: 'profitConfirm',
      },   
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.memberProfit' }),
        dataIndex: 'memberProfit',
      },
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.endDate' }),
        dataIndex: 'profitConfirmDate',
        render: date => date ? new Date(date).toLocaleString([], { hour12: false }) : ''
      },
      {
        title: props.intl.formatMessage({ id: 'FamilyIncome.option' }),
        dataIndex: '',
        render: (text) => (
          <Button
            type="primary"
            onClick={() => this.userDetails(text)}>
            {props.intl.formatMessage({ id: 'FamilyIncome.detail' })}
          </Button>
        )
      },
    ]
  }

  userDetails = async (text) => {
    //api
    const ret = await FamilyProfitAPI.adminMember({
      date: Number(moment(text.date).format('YYYYMMDD')),
    })

    if (ret.status === 200) {
      this.setState({
        userDetailsResults: ret.data.value,
        showUserDetails: true,
      })
    }

  }

  dateToHM(time) {
    let H = Math.floor(time / 3600), M = Math.floor((time % 3600) / 60)

    return `${H > 0 ? H + 'h' : ''}${M > 0 ? ("0" + M).slice(-2) + 'm' : ''}`
  }

  dateToHMS(time) {
    let H = Math.floor(time / 3600), M = Math.floor((time % 3600) / 60), S = Math.floor(time % 60)

    return `${H > 0 ? H + 'h' : ''}${M > 0 ? ("0" + M).slice(-2) + 'm' : ''}${S > 0 ? ("0" + S).slice(-2) + 's' : '0s'}`
  }

  onRangePickerInit = (moment) => {
    this.setState({ from: moment.startDate, to: moment.endDate })
  }

  onRangePickerChanged = (moment) => {
    const { pagination } = this.state
    this.setState({ from: moment.startDate, to: moment.endDate })
    this.query(moment.startDate.toISOString(), moment.endDate.toISOString(), pagination.current)
  }

  onSearchClick = () => {
    const { from, to, pagination } = this.state
    this.query(from.toISOString(), to.toISOString(), pagination.current)
  }

  handleTableChange = (pagination, filters, sorter) => {
    const { from, to } = this.state
    this.setState({ pagination, })
    this.query(from.toISOString(), to.toISOString(), pagination.current)
  }

  query = async (from, to, page) => {
    this.setState({ loading: true })
    const ret = await FamilyProfitAPI.admin({ from, to, page })
    if (ret.status === 200) {
      const pagination = { ...this.state.pagination }
      pagination.total = ret.data.total
      const resultSum = { ...ret.data }
      delete resultSum.value

      const results = ret.data.value
      results.forEach((res) => {
        res['noProfitSum'] = res['profitConfirmDate'] ? 0.0 : res['profitSum']
      })

      const memberProfitSum = results.map(e => e.memberProfit ? e.memberProfit : 0).reduce((prev, element) => prev + element, 0)
      this.setState({
        results: results,
        resultSum,
        pagination,
        memberProfitSum,
        loading: false,
      })
    }
  }

  summary = (pageData) => {
    if (pageData.length === 0) return

    const { profitSum, profitConfirmSum, noProfitSum } = this.state.resultSum
    let totalIntervalSum = 0

    pageData.forEach(({ intervalSum }) => {
      totalIntervalSum += intervalSum
    })

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell>
          {this.props.intl.formatMessage({ id: 'FamilyIncome.sum' })}
        </Table.Summary.Cell>
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell>
          <Typography>{this.dateToHM(totalIntervalSum)}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{this.state.memberProfitSum}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{profitSum.toLocaleString()}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{profitConfirmSum.toLocaleString()}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell>
          <Typography>{noProfitSum.toLocaleString()}</Typography>
        </Table.Summary.Cell>
        <Table.Summary.Cell />
      </Table.Summary.Row>
    )
  }


  render() {
    const { intl } = this.props

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
          <Col span={4}>
            <PrefixLabel
              text={intl.formatMessage({ id: 'global.dateRange' })}
            >
              <RangePicker
                style={{ width: '100%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                onInit={this.onRangePickerInit}
                onChange={this.onRangePickerChanged}
              />
            </PrefixLabel>
          </Col>
          <Col span={4} offset={16}>
            <Button type="primary" icon={<SyncOutlined />} loading={this.state.loading}
              onClick={this.onSearchClick}
              style={{ float: 'right' }}
            >
              {intl.formatMessage({ id: 'global.update' })}
            </Button>
          </Col>
        </Row>
        <Table
          rowKey={record => record.id}
          columns={this.columns}
          dataSource={this.state.results}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          scroll={{ x: 1000 }}
          bordered
          summary={this.summary}
        />
        <Modal
          title={intl.formatMessage({ id: 'FamilyIncome.detail' })}
          centered
          footer={null}
          visible={this.state.showUserDetails}
          onCancel={() => this.setState({ showUserDetails: false })}
          width={1300}
        >
          <UserDetailsView
            userDetailsResults={this.state.userDetailsResults}
          />
        </Modal>
      </div>
    )
  }
}

export default injectIntl(UserIncome)
