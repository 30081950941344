import API from './index'

const path = 'privateFeeOrder'

export default {
  async list(q) {
    try {
      let query = ''
      if (q && q.from) {
        query += `&from=${q.from}`
      }
      if (q && q.to) {
        query += `&to=${q.to}`
      }

      const response = await API.get(`/${path}/model?limit=10&page=${q.page}${query}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
}
