import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"
import { Layout, Menu, Icon, Avatar, Dropdown } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import routes from '../routes'
import { UserOutlined } from '@ant-design/icons'

class SideMenu extends Component {
  constructor(props) {
    super(props)
  }

  selectedMenu() {
    let url = window.location.hash.split('#')[1]
    let currentIndex = 1

    const Routes = routes.user
    for (const route of Routes) {
      if (url.match(route.path)) {
        currentIndex = route.key
      }
    }
    return [String(currentIndex)]
  }

  render() {
    const { isLogin, collapsed, userInfo, isMobile } = this.props
    const { Sider } = Layout

    const Routes = userInfo.isAdmin ? routes.family : routes.user


    return (
      <div>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{ overflow: 'auto', height: '100vh', display: isLogin ? 'block' : 'none' }}
          collapsedWidth="0"
        >

          <Menu
            mode="vertical"
            theme="dark"
            defaultSelectedKeys={this.selectedMenu()}
            style={{ lineHeight: '64px' }}
          >

            <div style={{
              borderRadius: "5px",
              background: "#1890ff",
              margin: "15px",
              color: "white",
              padding: "15px 10px",
              lineHeight: "initial",
              display: "flex",
              alignItems: "center"
            }}>
              <div style={{ width: 30 }}>
                <Avatar size="normal" icon={<UserOutlined />} />
              </div>
              <div
                style={{
                  color: 'white',
                  marginLeft: 10
                }}>{!collapsed && this.props.userInfo ? this.props.userInfo.name : ''}</div>
            </div>
            <Menu.Divider />

            {Routes.filter(route => ((isMobile && route.enableForMobile !== false) || !isMobile)).map((route) => (
              <Menu.Item key={route.key} icon={<route.icon />} onClick={() => {
                isMobile && this.props.onMenuClose()
              }}>
                <Link to={route.path}>
                  <FormattedMessage id={route.label} />
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      </div>
    )
  }
}

const mapStateToProps = store => (
  { collapsed: store.collapsed, isLogin: store.isLogin, userInfo: store.user }
)

export default connect(mapStateToProps)(injectIntl(SideMenu))

