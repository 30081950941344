import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import Animate from './Animate'
import Login from './pages/Login'
import { injectIntl } from 'react-intl'

import routes from '../routes'


class AppRouters extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentWillMount() {
    const { location } = this.props.history
    if (process.env.REACT_APP_BUILD_TYPE === 'SPORT') {
      if (!this.props.userInfo.isAdmin && location.pathname === "/") {
        this.props.history.push("/userInfo")
      }
    }else{
      if (!this.props.userInfo.isAdmin && this.props.isMobile && location.pathname === "/") {
        this.props.history.push("/giftHistory")
      }
    }
  }

  render() {
    const {
      intl, isLogin, userInfo,
      socketIO, language, messages, hearts,
      onLiveChange, onLoginSuccess, onLanguageChange,
      sendMessage, sendGameRecommend, isMobile
    } = this.props

    const Routes = userInfo.isAdmin ? routes.family : routes.user

    return isLogin ?
      (<div>
        {
          Routes.filter(route => ((isMobile && route.enableForMobile !== false) || !isMobile)).map((route) => (
            <Route
              key={route.key}
              path={route.path}
              exact={route.exact}
              render={() => route.path === '/liveControl' ? (
                <route.component
                  socket={socketIO}
                  lang={language}
                  msg={messages}
                  heart={hearts}

                  onLiveChange={onLiveChange}
                  sendMessage={sendMessage}
                  sendGameRecommend={sendGameRecommend}
                />
              ) : (
                  <route.component />
                )}
            />
          ))
        }
      </div>)
      : (
        <Animate
          animateIn="fadeInRightBig"
          animateOut="fadeOutRightBig"
          toggle={!isLogin}
        >
          <Login
            language={language}
            onLoginSuccess={onLoginSuccess}
            onLanguageChange={onLanguageChange}
          />
        </Animate>
      )
  }
}

const mapStateToProps = store => (
  { isLogin: store.isLogin, userInfo: store.user }
)

export default connect(mapStateToProps)(injectIntl(withRouter(AppRouters)))
