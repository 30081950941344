import React, { Component } from 'react'
import { Row, Col } from 'antd'
import { injectIntl } from 'react-intl'

class Sport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.selected && nextProps.selected) {
      this.setState({ selected: nextProps.selected })
    } else if (this.state.selected && !nextProps.selected) {
      this.setState({ selected: null })
    }
  }

  onClick = (name, id, nodeId, gameId, gameIcon) => {
    this.setState({ selected: id })
    this.props.onGameSelected({
      type: 'sport',
      name, id, nodeId, gameId, gameIcon
    })
  }

  render() {
    const { games } = this.props

    return (
      <Row type="flex" gutter={12}>
        {
          games.map(g => (
            <Col key={g.id} span={4}>
              <div
                className="icon-style"
                style={this.state.selected === g.id ? { border: 'solid 2px red' } : null}
                onClick={() => this.onClick(g.name, g.id, g.nodeId, g.gameId, g.image)}
              >
                <img src={g.image} alt="game icon" />
                <p>{g.name}</p>
              </div>
            </Col>
          ))
        }
      </Row>
    )
  }
}

export default injectIntl(Sport)