import API from '../index'
import { getUserInfo } from '../../helper/helper'

const path = 'family'

export default {
  async getAnchorList () {
    try {
      const headers = { 'x-access-token': getUserInfo().accessToken }
      const response = await API.get(`${path}/monitor`, { headers })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}