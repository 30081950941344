import React, { Component } from 'react'
import { Row, Col, Button, InputNumber, Typography, Radio } from 'antd'
import { injectIntl } from 'react-intl'

class OnAirPanel extends Component {
	constructor(props) {
		super(props)
		this.state = {
			type: 'normal',
			level: 0,
			watch: 3,
			fee: 1
		}
	}

	changeType = (type) => {
		switch (type) {
			case 'normal':
				return (
					<Row>
						<Col>
							<Typography style={{ color: '#F49F34' }}>{this.props.intl.formatMessage({ id: 'liveControl.roomLevel' })}</Typography>
						</Col>
						<Col style={{ marginLeft: 6 }}>
							<Typography>{this.props.intl.formatMessage({ id: 'liveControl.unrestricted' })}</Typography>
						</Col>
					</Row>
				)
			case 'vip':
				return (
					<Row align="middle">
						<Col>
							<Typography style={{ color: '#F49F34' }}>{this.props.intl.formatMessage({ id: 'liveControl.roomLevel' })}</Typography>
						</Col>
						<Col style={{ marginLeft: 6 }}>
							{this.levelBar()}
						</Col>
					</Row>
				)
			case 'private':
				return (
					<div>
						<Row align="middle">
							<Typography style={{ color: '#F49F34' }}>{this.props.intl.formatMessage({ id: 'liveControl.roomWatch' })}</Typography>
							<InputNumber style={{ marginLeft: 6 }} value={this.state.watch} min={3} max={9999} onChange={this.watchTimeOnChange} />
							<Typography style={{ marginLeft: 6 }}>{this.props.intl.formatMessage({ id: 'liveControl.min' })}</Typography>
						</Row>
						<Row align="middle" style={{ marginTop: 6 }}>
							<Typography style={{ color: '#F49F34' }}>{this.props.intl.formatMessage({ id: 'liveControl.roomAmount' })}</Typography>
							<InputNumber style={{ marginLeft: 6 }} value={this.state.fee} min={1} max={9999} onChange={this.roomFeeOnChange} />
						</Row>
					</div>

				)
		}
	}

	watchTimeOnChange = (e) => {
		this.setState({ watch: e })
		this.props.watchTime(e)
	}

	roomFeeOnChange = (e) => {
		this.setState({ fee: e })
		this.props.roomFee(e)
	}

	levelBar = () => {
		return (
			<Row align="middle">
				<Col>
					<Button type="circle" onClick={this.downOnChange}>-</Button>
				</Col>
				<Col>
					<Typography style={{ marginLeft: 8, marginRight: 8 }}>{this.state.level}</Typography>
				</Col>
				<Col>
					<Button type="circle" onClick={this.upOnChange}>+</Button>
				</Col>
			</Row>
		)
	}

	downOnChange = () => {
		if (this.state.level == 0) return
		this.props.roomLevel(this.state.level - 1)
		this.setState({ level: this.state.level - 1 })
	}

	upOnChange = () => {
		if (this.state.level <= 4) {
			this.props.roomLevel(this.state.level + 1)
			this.setState({ level: this.state.level + 1 })
		}
	}

	typeOnChange = (e) => {
		this.props.openOBS(e.target.value)
		this.setState({ type: e.target.value })
		this.props.roomType(e.target.value)
	}

	render() {
		const { intl } = this.props

		return (
			<div>
				<Row align="middle">
					<Typography strong style={{ color: '#F49F34' }}>{intl.formatMessage({ id: 'liveControl.onAirType' })}</Typography>
					<Radio.Group value={this.state.type} onChange={this.typeOnChange} style={{ marginBottom: 8, marginLeft: 6 }}>
						<Radio.Button value="normal">{intl.formatMessage({ id: 'liveControl.normal' })}</Radio.Button>
						<Radio.Button value="vip">{intl.formatMessage({ id: 'liveControl.vip' })}</Radio.Button>
						<Radio.Button value="private">{intl.formatMessage({ id: 'liveControl.private' })}</Radio.Button>
					</Radio.Group>
				</Row>
				{this.changeType(this.state.type)}
			</div>

		)
	}
}

export default injectIntl(OnAirPanel)