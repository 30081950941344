import React, { useRef, useEffect } from 'react'

const FlvPlayer = props => {
  const { flvJs, videoUrl } = props
  const videoRef = useRef()
  const player = useRef()


  const destroyPlayer = () => {
    videoRef.current.style.opacity = 0
    if (player.current) {
      player.current.destroy()
      player.current = null
    }
  }

  const launchVideo = () => {
    if (flvJs && flvJs.isSupported()) {
      player.current = flvJs.createPlayer({
        type: 'flv',
        isLive: true,
        url: videoUrl,
      })
      player.current.on('metadata_arrived', () => {
        videoRef.current.style.opacity = 1
      })
      player.current.attachMediaElement(videoRef.current)
      player.current.load()
      player.current.play()
    }
  }

  useEffect(() => {
    if (videoUrl) {
      launchVideo()
      return () => {
        destroyPlayer()
      }
    }
  }, [videoUrl])


  return (
    <video
      ref={videoRef}
      style={{ width: '100%', height: '100%', background: '#001529' }}
      controls={true}
      muted={false}
      autoPlay={true}
      playsInline={true}
      webkit-playsinline="true"
      x5-playsinline="true"
    />
  )
}

export default FlvPlayer
