import React, { Component } from 'react'

import heart1 from '../../assets/images/heart_blue.svg'
import heart2 from '../../assets/images/heart_bluepurple.svg'
import heart3 from '../../assets/images/heart_green.svg'
import heart4 from '../../assets/images/heart_pink.svg'
import heart5 from '../../assets/images/heart_purple.svg'
import heart6 from '../../assets/images/heart_red.svg'
import heart7 from '../../assets/images/heart_yellow.svg'

class HeartItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      elem: []
    }

    this.complete = 0
    this.hearts = [heart1, heart2, heart3, heart4, heart5, heart6, heart7]
  }

  componentWillMount() {
    const count = Math.round(Math.random() * 10 + 10)
    const styleSheet = document.styleSheets[0]
    const elems = []

    for (let i = 0; i < count; i++) {
      const animationName = `animation${Math.round(Math.random() * 100)}`

      const ranTop = Math.round(Math.random() * 50 + 20)
      const ranLeft = Math.round(Math.random() * 20)
      const keyframes =
      `@keyframes ${animationName} {
          0% { opacity: 1; }
          50% { top: ${ranTop}%; left: ${i % 2 === 0 ? 50 + ranLeft : 50 - ranLeft}%; }
          100% { top: ${ranTop + 20}%; left: ${i % 2 === 0 ? 55 + ranLeft : 45 - ranLeft}%; }
      }`

      styleSheet.insertRule(keyframes, styleSheet.cssRules.length)

      const style = {
        position: 'absolute',
        top: '90%',
        left: '50%',
        width: 50,
        opacity: 0,
        animationName: animationName,
        animationTimingFunction: 'ease-in-out',
        animationDuration: '2s',
        animationDelay: `${Math.random() * 3}s`,
        animationIterationCount: '1',
        animationDirection: 'normal',
        animationFillMode: 'forwards'
      }
      elems.push(style)
    }

    this.setState({ elems })
  }

  onAnimationEnd = () => {
    this.complete++
    if (this.complete === this.state.elems.length) {
      this.props.onAnimationEnd()
    }
  }

  render() {
    const elems = this.state.elems.map((value, key) => {
      return (
        <img key={key} src={this.hearts[key % 7]} style={value} onAnimationEnd={this.onAnimationEnd} alt="heart" />
      )
    })

    return elems
  }
}

export default HeartItem
