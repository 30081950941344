import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import Player from "../../FlvPlayer"
import Chat from "./Chat"
import Detail from "./Detail"


const MonitorPanel = (props) => {
  const { socket, anchorInfo } = props
  const [videoUrl, setVideoUrl] = useState()

  useEffect(() => {
    //避免剛開播, url 404
    setTimeout(() => {
      setVideoUrl(anchorInfo.streamInfo.channel.flv)
    }, 100)
  }, [])

  return (
    <Modal
      className="monitor-beauty-panel"
      wrapClassName="ics-app"
      title={`${anchorInfo.id} - ${anchorInfo.name}`}
      style={{ top: 20 }}
      width={800}
      footer={null}
      visible={true}
      maskClosable={false}
      onCancel={() => anchorInfo.closeMonitorModal()}
    >
      <div className="monitor-panel-warp">
        <div className="monitor-player-warp">
          <Player
            videoUrl={videoUrl}
            isNormal={anchorInfo.roomInfo.type === "normal"}
            isNeedEncrypt={anchorInfo.streamInfo.channel.isNeedEncrypt}
          />
        </div>
        <div className="monitor-detail-warp">
          <Detail anchorInfo={anchorInfo}/>
          <Chat socket={socket} anchorInfo={anchorInfo}/>
        </div>
      </div>
    </Modal>
  )

}

export default MonitorPanel
