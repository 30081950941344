import React from 'react'
import { Button, message, Upload, Image, Space } from 'antd'
import imagePath from '../../../../config/env'
import UserInfoAPI from '../../../../api/user'
import ImgCrop from 'antd-img-crop'

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

class BackGroundSetting extends React.Component {

    state = {
        anchorId: 0,
        imageSrc: '',
    }

    uploadCustomRequest = async (file) => {
        const uploadImage = await UserInfoAPI.upload(
            file.file, //file
            '2' //type
        )
        if (uploadImage.status === 200) {
            this.setState({
                imageSrc: imagePath.sportHost + `/public/uploads/users/${this.state.anchorId}/` + uploadImage.data
            })
        } else {
            message.error(uploadImage)
        }
    }

    componentWillMount() {
        this.getUserData()
    }

    getUserData = async () => {
        const result = await UserInfoAPI.userInfo()
        if (result.status === 200) {
            result.data.photo.forEach((photo) => {
                if (photo.type == 2) {
                    this.setState({ imageSrc: imagePath.sportHost + photo.url })
                }
            })
            this.setState({ anchorId: result.data.id })
        }
    }

    render() {
        return (
            <Space>
                <Image
                    width={400}
                    src={this.state.imageSrc}
                />
                <ImgCrop aspect={1 / 0.6} rotate>
                    <Upload
                        showUploadList={false}
                        customRequest={this.uploadCustomRequest}
                        beforeUpload={beforeUpload}
                    >
                        <Button>更換背景圖</Button>
                    </Upload>
                </ImgCrop>
            </Space>
        )
    }
}

export default BackGroundSetting