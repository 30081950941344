import React from "react";
import { Button } from 'antd';
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { injectIntl } from 'react-intl';

function _(props) {
  const { intl, online, channel, closeLive } = props

  if (channel == null) return (null)

  return (
    <div className="state-panel">
      <Button
        onClick={() => closeLive()}
        style={{ marginBottom: '20px' }}
        icon={<PoweroffOutlined />}
        danger
        type="primary">{intl.formatMessage({ id: 'liveControl.webEnd' })}</Button>
      <div className="online-count">
        <UserOutlined />{online}</div>
    </ div>
  )
}

export default injectIntl(_)