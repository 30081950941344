import API from '../index'

const path = 'manager'

export default {
  async managerList(anchorId) {
    try {
      const response = await API.get(`/${path}/${anchorId}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async addManager(customerId) {
    try {
      const response = await API.post(`/${path}/${customerId}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async delManager(customerId) {
    try {
      const response = await API.delete(`/${path}/${customerId}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}