import API from '../index'

const path = 'game/anchorReserve'

export default {
  async reserve(id) {
    try {
      const response = await API.post(`/${path}/${id}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async delReserve(id) {
    try {
      const response = await API.delete(`/${path}/${id}`)
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  },
  async reserveEvent(merchant, from, to) {
    const headers = {}
    if (merchant) headers.Merchant = merchant
    const params = { reserve: 1 }
    if (from) params.from = from
    if (to) params.to = to

    try {
      const response = await API.get(`/${path}`, { headers, params })
      return response
    } catch (e) {
      if (e.response) return e.response.data.message
      if (e.message) return e.message
    }
  }
}