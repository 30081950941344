import React, { useState } from 'react'
import { Avatar, Card, Popconfirm } from 'antd'
import Config from "../../../../../config/env"
import { ApiOutlined, HomeOutlined, LoginOutlined, TeamOutlined } from "@ant-design/icons"

import { injectIntl } from 'react-intl'

const Detail = (props) => {
  const { anchorInfo, intl } = props

  const [disabled, setDisabled] = useState(false)
  return (
    <div className="monitor-detail-content-warp">
      <Card
        actions={[
          <Popconfirm
            title={intl.formatMessage({ id: 'familyMonitor.sureRemove' })}
            disabled={disabled}
            cancelText={intl.formatMessage({ id: 'familyMonitor.cancel' })}
            okText={intl.formatMessage({ id: 'familyMonitor.confirm' })}
            onConfirm={() => {
              setDisabled(true)
              anchorInfo.closeRoom()
            }}
          >
            <div>
              <LoginOutlined/>
              <div>{intl.formatMessage({ id: 'familyMonitor.remove' })}</div>
            </div>
          </Popconfirm>,
        ]}
      >
        <Card.Meta
          avatar={
            <Avatar
              shape="circle"
              draggable={false}
              size={80}
              src={anchorInfo.cover ? Config.lasHost + anchorInfo.cover : ""}/>
          }
          title={<div className={"title"}><small>{anchorInfo.id} - </small>{anchorInfo.name}</div>}
          description={
            <div className={"description"}>
              <div>
                <ApiOutlined style={{ marginRight: 10, fontSize: 20 }}/>
                {anchorInfo.streamInfo ?
                  `${anchorInfo.streamInfo.streamName} - ${anchorInfo.streamInfo.streamId}` : ""}
              </div>
              <div>
                <HomeOutlined style={{ marginRight: 10, fontSize: 20 }}/>
                {anchorInfo.roomInfo.type === "normal" ? intl.formatMessage({ id: 'familyMonitor.anchor_live' }) : intl.formatMessage({ id: 'familyMonitor.anchor_porn_live' })}
              </div>
              <div>
                <TeamOutlined style={{ marginRight: 10, fontSize: 20 }}/>
                {anchorInfo.onlineCount !== undefined ? new Intl.NumberFormat().format(anchorInfo.onlineCount) : "..."}
              </div>

            </div>
          }
        />
      </Card>
    </div>
  )

}

export default injectIntl(Detail)
