import zhTW from './zh-TW'
import zhCN from './zh-CN'
import en from './en'
import th from './th'
import vi from './vi'

const i18nConfig = {
  'zh-TW': zhTW,
  'zh-CN': zhCN,
  'en': en,
  'th': th,
  'vi': vi
}

export default i18nConfig
