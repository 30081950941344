import React from 'react';
import { Modal, Menu, Typography, message } from 'antd'
import ManagerAPI from '../../../../api/Sport/manager'
import MuteAPI from '../../../../api/Sport/mute'
import { injectIntl } from 'react-intl';

const { SubMenu } = Menu
const { Title } = Typography

class ActionMenu extends React.Component {

	onSelectMenu = (cell) => {
		switch (cell.key) {
			case 'TWO-HOURS':
				this.mute(this.props.intl.formatMessage({ id: 'SportLive.twoHours' }), 60 * 2)
				break
			case 'ONE-DAY':
				this.mute(this.props.intl.formatMessage({ id: 'SportLive.oneDay' }), 60 * 24)
				break
			case 'THREE-DAY':
				this.mute(this.props.intl.formatMessage({ id: 'SportLive.threeDay' }), 60 * 72)
				break
			case 'A-MONTH':
				this.mute(this.props.intl.formatMessage({ id: 'SportLive.aMonth' }), 60 * 720)
				break
			case 'INFINITE':
				this.mute(this.props.intl.formatMessage({ id: 'SportLive.infinite' }), 60 * 99999)
				break
			case 'ADD-MANAGER':
				this.addManager()
				break
			case 'DEL-MANAGER':
				this.delManager()
				break
		}
	}

	addManager = async () => {
		const cid = this.props.dataSource.customerId
		const result = await ManagerAPI.addManager(cid)
		if (result.status === 200) {
			message.success(`${this.props.dataSource.nickname} ${this.props.intl.formatMessage({ id: 'SportLive.becomeManager' })}`)
			this.props.onReloadManagerList()
			this.props.onCancel()
		}
	}

	delManager = async () => {
		const cid = this.props.dataSource.customerId
		const result = await ManagerAPI.delManager(cid)
		if (result.status === 200) {
			message.success(`${this.props.dataSource.nickname} ${this.props.intl.formatMessage({ id: 'SportLive.cancelledManager' })}`)
			this.props.onReloadManagerList()
			this.props.onCancel()
		}
	}

	mute = async (msg, min) => {
		const cid = this.props.dataSource.customerId
		const result = await MuteAPI.mute(cid, min)
		if (result.status === 200) {
			message.success(`${this.props.intl.formatMessage({ id: 'SportLive.has' })} ${this.props.dataSource.nickname} ${this.props.intl.formatMessage({ id: 'SportLive.mute' })}${msg}`)
			this.props.onReloadBanList()
			this.props.onCancel()
		}
	}

	render() {
		const { isShow, dataSource, manager, onCancel } = this.props

		return (
			<Modal
				width={300}
				footer={null}
				visible={isShow}
				closable={false}
				centered
				onCancel={() => onCancel()}
			>
				<Title level={4} style={{ textAlign: 'center' }}>{dataSource.nickname}</Title>
				<Menu style={{ textAlign: 'center' }} onClick={this.onSelectMenu}>
					<SubMenu style={{ color: 'red' }} title="禁言">
						<Menu.Item key="TWO-HOURS">{this.props.intl.formatMessage({ id: 'SportLive.twoHours' })}</Menu.Item>
						<Menu.Item key="ONE-DAY">{this.props.intl.formatMessage({ id: 'SportLive.oneDay' })}</Menu.Item>
						<Menu.Item key="THREE-DAY">{this.props.intl.formatMessage({ id: 'SportLive.threeDay' })}</Menu.Item>
						<Menu.Item key="A-MONTH">{this.props.intl.formatMessage({ id: 'SportLive.aMonth' })}</Menu.Item>
						<Menu.Item key="INFINITE">{this.props.intl.formatMessage({ id: 'SportLive.infinite' })}</Menu.Item>
					</SubMenu>
					{
						manager.filter(c => c.customerId === dataSource.customerId).length > 0 ?
							<Menu.Item key="DEL-MANAGER">
								{this.props.intl.formatMessage({ id: 'SportLive.delManager' })}
							</Menu.Item>
							:
							<Menu.Item key="ADD-MANAGER">
								{this.props.intl.formatMessage({ id: 'SportLive.addManager' })}
							</Menu.Item>
					}
				</Menu>
			</Modal>
		);
	}
}
export default injectIntl(ActionMenu);
