import React from 'react'
import { Table, Select, Tag } from 'antd'
import moment from 'moment'
import StatisticsAPI from '../../../../api/Sport/statistics'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import DateTimeRangePicker from '../../../forms/DateTimeRangePicker'

class Statistics extends React.Component {

  state = {
    dataSource: [],
    tableLoading: false,
    statisicDateRange: {},
    mode: '0'
  }

  columns = [
    {
      title: this.props.intl.formatMessage({ id: 'SportStatistics.liveStartAt' }),
      dataIndex: 'liveStartAt',
      align: 'center',
      render: date => {
        const at = moment(new Date(date)).format('YYYY-MM-DD /-/-/ HH:mm').split('/-/-/')
        if (this.state.mode == 1) {
          return (
            <Tag color="volcano">
              {at[0]}
              <br></br>
              {at[1]}
            </Tag>
          )
        } else {
          return (
            <Tag color="default">
              {at[0]}
              <br></br>
              {at[1]}
            </Tag>
          )
        }
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'SportStatistics.liveOverAt' }),
      dataIndex: 'liveOverAt',
      align: 'center',
      render: date => {
        const at = moment(new Date(date)).format('YYYY-MM-DD /-/-/ HH:mm').split('/-/-/')
        if (this.state.mode == 0) {
          return (
            <Tag color="volcano">
              {at[0]}
              <br></br>
              {at[1]}
            </Tag>
          )
        } else {
          return (
            <Tag color="default">
              {at[0]}
              <br></br>
              {at[1]}
            </Tag>
          )
        }
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'SportStatistics.startAt' }),
      dataIndex: 'startAt',
      align: 'center',
      sorter: (a, b) => moment(a.startAt).unix() - moment(b.startAt).unix(),
      render: date => {
        const at = moment(new Date(date)).format('YYYY-MM-DD /-/-/ HH:mm').split('/-/-/')
        return (
          <div>
            {at[0]}
            <br></br>
            {at[1]}
          </div>
        )
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'SportStatistics.eventName' }),
      dataIndex: 'eventName',
      align: 'center',
      render: text => {
        const homeGuest = text.split('/-/-/')
        return (
          <div>
            {homeGuest[0]}
            <br></br>
            {homeGuest[1]}
          </div>
        )
      }
    },
    {
      title: this.props.intl.formatMessage({ id: 'SportStatistics.gamePrizeSum' }),
      dataIndex: 'gamePrizeSum',
      align: 'center',
      render: (sum) => sum.toFixed(2)
    },
    {
      title: this.props.intl.formatMessage({ id: 'SportStatistics.gameGiftSum' }),
      dataIndex: 'gameGiftSum',
      align: 'center',
      render: (sum) => sum.toFixed(2)
    },
  ]

  query = async (from, to, mode) => {
    this.setState({ tableLoading: true })
    const result = await StatisticsAPI.anchorEventList(from, to, mode, this.props.user.id)
    if (result.status === 200) {
      const vaule = [...result.data]
      vaule.forEach((even) => {
        even["eventName"] =
          `${even.homeTeamName} ${this.props.intl.formatMessage({ id: 'SportStatistics.main' })}
         /-/-/
         ${even.guestTeamName} ${this.props.intl.formatMessage({ id: 'SportStatistics.guest' })}`
      })
      this.setState({ dataSource: vaule })
      this.setState({ tableLoading: false })
    }
  }

  componentDidMount() {
    this.setState({
      statisicDateRange:
      {
        startDate: moment(new Date(), 'YYYY-MM-DD').set({ hour: 0, minute: 0, second: 0 }),
        endDate: moment(new Date(), 'YYYY-MM-DD').set({ hour: 23, minute: 59, second: 59 })
      }
    })

    this.query(
      moment(new Date(), 'YYYY-MM-DD').set({ hour: 0, minute: 0, second: 0 }).toISOString(),
      moment(new Date(), 'YYYY-MM-DD').set({ hour: 23, minute: 59, second: 59 }).toISOString(),
      this.state.mode
    )
  }

  summaryTable = (pageData) => {
    let totalGamePrizeSum = 0;
    let totalGameGiftSum = 0;

    pageData.forEach(({ gamePrizeSum, gameGiftSum }) => {
      totalGamePrizeSum += gamePrizeSum;
      totalGameGiftSum += gameGiftSum;
    })

    return (
      <Table.Summary.Row>
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell align='center'>
          {totalGamePrizeSum.toFixed(2)}
        </Table.Summary.Cell>
        <Table.Summary.Cell align='center'>
          {totalGameGiftSum.toFixed(2)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    );
  }

  TypeOptions = [
    { value: "updated", title: this.props.intl.formatMessage({ id: 'SportStatistics.liveOverAt' }) },
    { value: "created", title: this.props.intl.formatMessage({ id: 'SportStatistics.liveStartAt' }) }
  ]

  selectList = (defaultValue) => {
    const Option = Select.Option
    return (
      <Select defaultValue={defaultValue} onChange={(t, k) => {
        this.setState({ mode: `${k.key}` })
        this.query(
          this.state.statisicDateRange.startDate.toISOString(),
          this.state.statisicDateRange.endDate.toISOString(),
          `${k.key}`
        )
      }}>
        {
          this.TypeOptions.map((v, key) =>
            <Option key={key} value={v.value}>{v.title}</Option>
          )
        }
      </Select>
    )
  }

  onChangeDateTimeRangePicker = (d) => {
    this.setState({
      statisicDateRange: {
        startDate: new Date(d.startDate),
        endDate: new Date(d.endDate)
      }
    })
    this.query(
      new Date(d.startDate).toISOString(),
      new Date(d.endDate).toISOString(),
      this.state.mode
    )
  }

  render() {
    return (
      <>
        <DateTimeRangePicker
          label={this.selectList(['updated'])}
          onChange={this.onChangeDateTimeRangePicker}
        />
        <Table
          style={{ marginTop: '20px' }}
          columns={this.columns}
          dataSource={this.state.dataSource}
          bordered={true}
          pagination={{ pageSize: 15, showSizeChanger: false }}
          loading={this.state.tableLoading}
          summary={(pageData) => this.summaryTable(pageData)}
        />
      </>
    )
  }
}


const mapStateToProps = store => (
  { user: store.user }
)

export default connect(mapStateToProps)(injectIntl(Statistics))