export const isLoginTypes = {
  set: `Set isLogin`,
}

const IsLoginInit = false

export default (state = IsLoginInit, action) => {
  switch (action.type) {
    case isLoginTypes.set:
      return action.value

    default:
      return state
  }
}
