// User
import Dashboard from './components/pages/Dashboard'
import LiveControl from './components/pages/LiveControl'
import GiftHistory from './components/pages/GiftHistory'
import RoomOrder from './components/pages/RoomOrder'
import LiveAnalysis from './components/pages/LiveAnalysis'
import UserIncome from './components/pages/UserIncome'
import UserInfo from './components/pages/UserInfo'
import FamilyIncome from './components/pages/FamilyIncome'
import LiveMonitor from './components/pages/Monitor'
import FamilyInfo from './components/pages/FamilyInfo'
import Sports from './components/pages/Sports'
import SportsLive from './components/pages/Sports/Live'
import Statistics from './components/pages/Sports/Statistics'
import SportUserInfo from './components/pages/Sports/UserInfo'

import {
  DashboardOutlined,
  RocketOutlined,
  GiftOutlined,
  SolutionOutlined,
  AreaChartOutlined,
  PieChartOutlined,
  WalletOutlined,
  UserOutlined,
  TeamOutlined,
  TableOutlined,
  VideoCameraOutlined,
  VideoCameraAddOutlined
} from '@ant-design/icons'

const user = [
  {
    key: 1,
    icon: UserOutlined,
    label: 'routes.info',
    path: '/userInfo',
    exact: true,
    component: UserInfo
  },
  {
    key: 2,
    icon: DashboardOutlined,
    label: 'routes.dashboard',
    path: '/',
    exact: true,
    component: Dashboard,
    enableForMobile: false
  },
  {
    key: 3,
    label: 'routes.liveControl',
    icon: RocketOutlined,
    path: '/liveControl',
    exact: true,
    component: LiveControl,
    enableForMobile: false
  },
  {
    key: 4,
    label: 'routes.giftHistory',
    icon: GiftOutlined,
    path: '/giftHistory',
    exact: true,
    component: GiftHistory
  },
  {
    key: 5,
    label: 'routes.RoomOrder',
    icon: WalletOutlined,
    path: '/roomOrder',
    exact: true,
    component: RoomOrder
  },
  {
    key: 6,
    label: 'routes.analysis',
    icon: AreaChartOutlined,
    path: '/liveAnalysis',
    exact: true,
    component: LiveAnalysis
  },
  {
    key: 7,
    label: 'routes.userIncome',
    icon: SolutionOutlined,
    path: '/userIncome',
    exact: true,
    component: UserIncome
  },
]

const family = [
  {
    key: 1,
    label: 'routes.familyManager',
    icon: TeamOutlined,
    path: '/familyInfo',
    exact: true,
    component: FamilyInfo
  },
  {
    key: 2,
    label: 'routes.giftHistory',
    icon: GiftOutlined,
    path: '/giftHistory',
    exact: true,
    component: GiftHistory
  },
  {
    key: 3,
    label: 'routes.analysis',
    icon: AreaChartOutlined,
    path: '/liveAnalysis',
    exact: true,
    component: LiveAnalysis
  },
  {
    key: 4,
    label: 'routes.familyIncome',
    icon: PieChartOutlined,
    path: '/familyIncome',
    exact: true,
    component: FamilyIncome
  },
  {
    key: 5,
    label: 'routes.monitor_live',
    icon: VideoCameraOutlined,
    path: '/liveMonitor',
    exact: true,
    component: LiveMonitor
  }
]

const sport = [
  {
    key: 1,
    label: 'routes.info',
    icon: UserOutlined,
    path: '/userInfo',
    exact: false,
    component: SportUserInfo
  },
  {
    key: 2,
    label: 'routes.events',
    icon: TableOutlined,
    path: '/sports',
    exact: false,
    component: Sports
  },
  {
    key: 3,
    label: 'routes.liveControl',
    icon: VideoCameraAddOutlined,
    path: '/live',
    exact: false,
    component: SportsLive
  },
  {
    key: 4,
    label: 'routes.SportStatistics',
    icon: PieChartOutlined,
    path: '/statistics',
    exact: false,
    component: Statistics
  },
]

const routes = process.env.REACT_APP_BUILD_TYPE !== 'SPORT'
  ? { user, family } : { user: sport }

export default routes
