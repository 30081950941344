import React, { Component } from 'react'
import { Input, Button } from 'antd'
import { injectIntl } from 'react-intl'

class ICSInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: ''
    }
  }

  onChange = event => {
    this.setState({ input: event.target.value })
  }

  onSummit = () => {
    if (this.state.input) {
      this.props.onClick(this.state.input)
      this.setState({ input: '' })
    }
  }

  render() {
    const { intl } = this.props
    const SummitButton = (
      <Button
        onClick={this.onSummit}
      >
        {intl.formatMessage({ id: 'chat.send' })}
      </Button>
    )

    return (
      <div className="message-input">
        <Input
          className="input"
          placeholder={intl.formatMessage({ id: 'chat.placeholder' })}
          value={this.state.input}
          suffix={SummitButton}
          onChange={this.onChange}
          onPressEnter={this.onSummit}
        />
      </div>
    )
  }
}

export default injectIntl(ICSInput)
