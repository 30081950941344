import React, { useEffect } from 'react'

import AnchorList from "./AnchorList/index"
import useSocket from "./useSocket"
import useData from "./useData"
import Header from "./Header"
import MonitorModal from "./MonitorModal"

import { injectIntl } from 'react-intl'

const BeautyPanel = (props) => {
  const { intl } = props
  const socket = useSocket()
  const {anchorListInfo, monitorInfo} = useData({socket, intl: intl})

  return (
    <div className="monitor-beauty-panel">
      <Header anchorListInfo={anchorListInfo}/>
      <AnchorList anchorListInfo={anchorListInfo}/>
      { monitorInfo && <MonitorModal key={monitorInfo.id} anchorInfo={monitorInfo} socket={socket}/>}
    </div>
  )

}

export default injectIntl(BeautyPanel)
