import React from "react";
import { Divider, Table, Statistic, Tag, Button, message } from 'antd'
import moment from 'moment'
import MuteAPI from '../../../../api/Sport/mute'
import { injectIntl } from 'react-intl';

const { Countdown } = Statistic

function MuteList(props) {
  const { intl, banCustomer } = props

  // const onMute = async (item) => {
  //   const result = await MuteAPI.dismissMute(item.customerId)
  //   if (result.status === 200) {
  //     reloadBanList()
  //     message.success(`${intl.formatMessage({ id: 'MuteList.has' })} ${item.customer.nickname} ${this.props.intl.formatMessage({ id: 'MuteList.disMute' })}`)
  //   }
  // }

  const columns = [
    {
      title: intl.formatMessage({ id: 'MuteList.name' }),
      dataIndex: 'nickname',
      align: 'center',
      width: '100px'
    },
    {
      title: intl.formatMessage({ id: 'MuteList.muteTime' }),
      dataIndex: 'banTime',
      align: 'center',
      render: (date) => moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: intl.formatMessage({ id: 'MuteList.timeLeft' }),
      dataIndex: 'freeTime',
      align: 'center',
      render: (date) => {
        if (new Date(date) <= new Date()) {
          return (
            <Tag color="red">{intl.formatMessage({ id: 'MuteList.end' })}</Tag>
          )
        } else {
          return (
            <Countdown valueStyle={{ fontSize: 18 }} value={new Date(date)} onFinish={() => this.props.reloadData()} />
          )
        }
      }
    },
    // {
    //   title: intl.formatMessage({ id: 'MuteList.manager' }),
    //   dataIndex: 'byManager',
    //   align: 'center',
    //   render: (byManager) => byManager ? byManager.nickname : ''
    // },
    // {
    //   title: intl.formatMessage({ id: 'MuteList.action' }),
    //   dataIndex: 'action',
    //   //fixed: 'right',
    //   align: 'center',
    //   render: (_, item) => {
    //     if (new Date(item.freeTime) <= new Date()) {
    //       return (
    //         <Button type='primary' disabled>{intl.formatMessage({ id: 'MuteList.disMute' })}</Button>
    //       )
    //     } else {
    //       return (
    //         <Button type='primary' onClick={() => onMute(item)}>{intl.formatMessage({ id: 'MuteList.disMute' })}</Button>
    //       )
    //     }
    //   }
    // }
  ]

  return (
    <>
      <Divider />
      <Table
        scroll={{ y: 300 }}
        size={'small'}
        pagination={false}
        columns={columns}
        dataSource={banCustomer.mute ?? []}
      />
    </>
  )
}

export default injectIntl(MuteList)