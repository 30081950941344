import { useState, useEffect } from "react";
import LoginAPI from '../../../api/user'
import LiveAPI from '../../../api/live'
import ManagerAPI from '../../../api/Sport/manager'
import BenAPI from '../../../api/Sport/mute'

const useModel = (props) => {
  const { socket } = props
  const [model, setModel] = useState(
    {
      liveInfo: {
        game: {},
        channel: null,
        roomType: 'normal',
        name: null,
        deviceType: 'app',
        qrCode: '',
        push: {},
        isOBSStreaming: false,
        connectLoading: false
      },
      managerList: [],
      banCustomerList: [],
      online: 0
    }
  )

  useEffect(() => {
    actions.getStreamState()
    actions.getBenCustomerList()

    socket.io.on('model/stream/start', async () => {
      setTimeout(() => {
        actions.getStreamState()
      }, 5000)
    })
    socket.io.on('model/stream/stop', async () => {
      setModel((model) => {
        model.liveInfo.channel = null
        model.liveInfo.name = ''
        model.liveInfo.connectLoading = false
        return { ...model }
      })
    })
    socket.io.on('model/live/stop', async () => {
      setModel((model) => {
        model.liveInfo.channel = null
        model.liveInfo.name = ''
        model.liveInfo.isOBSStreaming = false
        model.liveInfo.connectLoading = false
        return { ...model }
      })
      if (model.liveInfo.deviceType === 'obs') {
        setModel((model) => {
          model.liveInfo.deviceType = ''
          return { ...model }
        })
      }
    })
    socket.io.on('chat/room/onLineCount', async (online) => {
      setModel(model => {
        model.online = online.count
        return { ...model }
      })
    })

    return () => {
      socket.io.off('model/stream/start')
      socket.io.off('model/stream/stop')
      socket.io.off('model/live/stop')
      socket.io.off('chat/room/onLineCount')
    }
  }, [])

  const actions = {
    setRoomType: (type) => {
      setModel(model => {
        model.liveInfo.roomType = type
        return { ...model }
      })
    },
    setRoomLevel: (level) => {
      setModel(model => {
        model.liveInfo.roomLevel = level
        return { ...model }
      })
    },
    setWatchTime: (time) => {
      setModel(model => {
        model.liveInfo.watchTime = time
        return { ...model }
      })
    },
    setRoomFee: (fee) => {
      setModel(model => {
        model.liveInfo.roomFee = fee
        return { ...model }
      })
    },
    fetchGames: (data) => {
      setModel(model => ({
        ...model,
        gameList: data
      }))
    },
    getStreamState: async () => {
      const result = await LoginAPI.findOneModel()
      if (result.status !== 200) return

      const state = {
        name: result.data.name,
        anchorId: result.data.id,
        game: JSON.parse(result.data.chatSetting.game) ?? {},
        onAirLoading: false,
        roomType: result.data.roomType
      }

      if (result.data.streaming) state.channel = result.data.channel

      setModel(model => {
        const info = { ...model.liveInfo, ...state }
        model.liveInfo = info
        return { ...model }
      })

      actions.getManagerList()
    },
    streamDevice: (type) => {
      if (type === 'obs') actions.getStreamData()
      setModel(model => {
        model.liveInfo.deviceType = type
        return { ...model }
      })
    },
    getQrcode: async () => {
      const result = await LoginAPI.generateQRCode()
      if (result.status === 200) {
        setModel(model => {
          model.liveInfo.qrCode = result.data.qrcode
          return { ...model }
        })
      }
    },
    getStreamData: async (type = 'normal') => {
      if (!model.liveInfo.isOBSStreaming) {
        const result = await LoginAPI.pushUrl(type)

        if (result.status === 200) {
          setModel(model => {
            model.liveInfo.push = result.data
            model.liveInfo.roomType = type
            model.liveInfo.roomLevel = 0
            model.liveInfo.watchTime = 3
            model.liveInfo.roomFee = 1
            return { ...model }
          })
        }
      }
    },
    startPullStream: async () => {
      if (!model.liveInfo.isOBSStreaming) {
        setModel((model) => {
          model.liveInfo.connectLoading = true
          return { ...model }
        })

        const body = {
          roomType: model.liveInfo.roomType,
          roomLevel: model.liveInfo.roomLevel,
          privateFee: {
            fee: model.liveInfo.roomFee,
            minute: model.liveInfo.watchTime
          }
        }

        const result = await LiveAPI.webStart(body)

        if (result.status === 200) {
          socket.io.emit('model/stream/start', { isPC: true })
          setModel((model) => {
            model.liveInfo.isOBSStreaming = true
            return { ...model }
          })
        }
      }
    },
    closeLive: async () => {
      await LiveAPI.webEnd()
      socket.io.emit('model/stream/stop', { active: false, streaming: false })
    },
    bindGame: (game) => {
      setModel(model => {
        model.liveInfo.game = game
        return { ...model }
      })
    },
    getManagerList: async () => {
      const result = await ManagerAPI.managerList(model.liveInfo.anchorId)

      if (result.status === 200) {
        setModel((model) => {
          model.managerList = result.data
          return { ...model }
        })
      }
    },
    getBenCustomerList: async () => {
      const result = await BenAPI.muteList()

      if (result.status === 200) {
        setModel((model) => {
          model.banCustomerList = result.data
          return { ...model }
        })
      }
    },

  }

  return { model, actions }
}

export default useModel