import React, { Component } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  setPlayerRef = element => {
    this.playerRef = element
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.streamUrl !== nextProps.streamUrl) {
      this.player.src({
        src: nextProps.streamUrl,
        type: 'application/x-mpegURL'
      })
    }
  }

  componentDidMount() {
    const options = {
      controlBar: {
        fullscreenToggle: false
      },
      plugins: {
        reloadSourceOnError: { errorInterval: 5 }
      }
    }

    var Button = videojs.getComponent('Button')
    var RefreshButton = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments)
        this.addClass('vjs-icon-refresh')
      },
      handleClick: function () {
        this.player().src({
          src: this.player().src(),
          type: 'application/x-mpegURL'
        })
        this.player().load()
      }
    })
    videojs.registerComponent('RefreshButton', RefreshButton)

    this.player = videojs(this.playerRef, options)
    this.player.src({
      src: this.props.streamUrl,
      type: 'application/x-mpegURL'
    })

    this.player.getChild('controlBar').addChild('RefreshButton', {})

  }

  componentWillUnmount() {
    if (this.player) this.player.dispose()
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%', background: '#001529', position: "relative" }}>
        <div
          onClick={this.props.changeChannelTypeToFlv}
          style={{
            position: "absolute",
            color: "white",
            top: 0,
            right: 0,
            width: 15,
            height: 15,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            fontSize: 12,
            zIndex: 1,
            borderRadius: 15,
            border: "1px solid"
          }}>H
        </div>
        <video
          ref={this.setPlayerRef}
          className="video-js vjs-default-skin"
          style={{ width: '100%', height: '100%', background: '#001529' }}
          controls={true}
          muted
          autoPlay={true}
          playsInline={true}
          webkit-playsinline="true"
          x5-playsinline="true"
        />
      </div>
    )
  }
}

export default Video
