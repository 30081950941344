import React, { Component } from 'react'
import './css/app.css'
import { HashRouter, Switch } from "react-router-dom"
import { Provider } from 'react-redux'
import store from './store'
import Header from './components/Header'
import SiderMenu from './components/SiderMenu'
import Routers from './components/Routers'

import { Layout } from 'antd'
import i18n from './locales'
import { IntlProvider } from 'react-intl'
import { storeUserInfo, getUserInfo, getLanguage, killAllStorage } from './helper/helper'

import LasSocket from './helper/lasSocket'
import ChatAPI from './api/chat'
import { setRequestHeader } from './api'
import { setIsLogin } from './store/isLogin/action'
import { setUser } from './store/user/action'
import mobile from 'is-mobile'
import LiveAPI from "./api/live"
import { closeCollapsed, toggleCollapsed } from "./store/collapsed/action"


class App extends Component {
  constructor() {
    super()
    this.state = {
      menuIsClose: false,
      language: getLanguage(),
      // las socket
      lasIsLogin: false,
      lasMsg: [],
      lasHeart: 0,
      lasSocket: null,
    }

  }

  onLoginSuccess = async (user) => {
    storeUserInfo(user)
    await this.dealUserData()
  }

  onLanguageChange = (language) => {
    window.sessionStorage.setItem('language', language)
    this.setState({ language, lasMsg: [] })
  }

  onLogout = async () => {
    const user = store.getState().user
    if (user.liveRoomIsBegin) {
      await LiveAPI.webEnd()
    }
    killAllStorage()
    if (this.state.lasSocket) this.state.lasSocket.unMount()
    setIsLogin(false)
    setUser(false)
  }

  async componentWillMount() {
    await this.dealUserData()
  }

  dealUserData = async () => {
    const sessionStorage = getUserInfo()
    if (sessionStorage.user && sessionStorage.accessToken) {
      setRequestHeader()
      setUser(sessionStorage.user)
      if (!sessionStorage.user.isAdmin && process.env.REACT_APP_BUILD_TYPE !== 'SPORT') {
        const result = await ChatAPI.imUrl(sessionStorage.user.merchant)
        this.setState({
          lasSocket: new LasSocket(this, sessionStorage.user, result.imUrl)
        }, () => {
          window.lasSocket = this.state.lasSocket
        })
      }
      setIsLogin(true)
    }
  }

  sendMessage = (msg) => {
    this.state.lasSocket.sendMessage(msg)
  }

  sendGameRecommend = (object) => {
    this.state.lasSocket.sendGameRecommend(object)
  }

  onMenuToggle = () => {
    this.setState({
      menuIsClose: !this.state.menuIsClose
    })
    toggleCollapsed()
  }

  onMenuClose = () => {
    this.setState({
      menuIsClose: true
    })
    closeCollapsed()
  }

  render() {
    const { Content } = Layout
    const isMobile = mobile()
    return (
      <IntlProvider locale="en" messages={i18n[this.state.language]}>
        <Provider store={store}>
          <HashRouter>
            <Layout>
              <SiderMenu
                onMenuClose={this.onMenuClose}
                isMobile={isMobile} />
              <Layout style={{ height: '100vh' }}>
                <Header
                  onMenuToggle={this.onMenuToggle}
                  onLogout={this.onLogout}
                />
                <Content
                  style={{
                    padding: '12px',
                    position: "relative",
                    height: this.state.isLogin ? 'calc(100vh - 64px)' : '100vh'
                  }}

                >
                  {
                    isMobile && <div
                      onClick={() => this.onMenuClose()}
                      style={{
                        display: (this.state.menuIsClose ? "none" : ""),
                        position: "absolute",
                        background: "rgba(0, 0, 0, 0.23)",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 1,
                      }} />
                  }
                  <Switch>
                    <Routers
                      isMobile={mobile()}
                      socketIO={this.state.lasSocket}
                      language={this.state.language}
                      messages={this.state.lasMsg}
                      hearts={this.state.lasHeart}
                      onLoginSuccess={this.onLoginSuccess}
                      onLanguageChange={this.onLanguageChange}
                      sendMessage={this.sendMessage}
                      sendGameRecommend={this.sendGameRecommend}
                    />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </HashRouter>
        </Provider>
      </IntlProvider>
    )
  }
}

export default App
