import API from '../index'

export default {
    async myInfo () {
      try {
        const response = await API.get(`/family/myInfo`)
        return response
      } catch (e) {
        if (e.response) return e.response.data.message
        if (e.message) return e.message
      }
    },
    async myMembers () {
        try {
          const response = await API.get(`/family/myMembers`)
          return response
        } catch (e) {
          if (e.response) return e.response.data.message
          if (e.message) return e.message
        }
      },
  }