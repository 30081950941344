import React from 'react'
import { useScript } from "use-script"
import BeautyPanel from './Beauty/index'
import './Beauty/index.scss'

// export default Monitor
export default React.memo(() => {
  const { loading: FlvScriptLoading } = useScript({
    src: './js/flv.min.js',
  })
  if (FlvScriptLoading) return null
 
  return (
    <BeautyPanel />
  )
})
