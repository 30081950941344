import React, { Component } from 'react'
import HeartItem from './HeartItem'

class Heart extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {

    return (
      <div>
        {
          this.props.show
          &&
          <HeartItem onAnimationEnd={this.props.onAnimationEnd} />
        }
      </div>
    )
  }
}

export default Heart
